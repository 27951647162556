import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { MdClose } from "react-icons/md";
import FullLoader from "../../../storyProject/components/FullLoader";
import { message } from "antd";
import { FaMagic, FaSearch } from "react-icons/fa";
import Assets from "./Tasks/Assets";
import Header from "../../../../components/Header";
import Blocks from "./Tasks/Blocks";
import Settings from "./Tasks/Settings";

import HeaderCreate from "./Tasks/HeaderCreate";
import Assets2 from "./Tasks/Assets2";
import ChatLog from "./Tasks/ChatLog";
import ListConnect from "./Tasks/ListConnect";
import ChatLogTask from "./Tasks/ChatLogTask";
import Assets3 from "./Tasks/Assets3";
import ListDataSet from "./Tasks/ListDataSet";
import MergTabs from "./Tasks/MergTabs";

export default function Start() {
  const {
    editor,
    seteditor,
    user,
    organization,
    setImages,
    images,
    tasks,
    callApi,
    setCallApi,
    setShowPrice,
  } = useContext(SearchContext);

  const [search1, setSearch1] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);

  const [loadingP, setLoadingP] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoadingP(false);
    }, 500);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return loading ? (
    <FullLoader />
  ) : (
    <div style={{ width: "100%" }} className="start-search-bx">
      <div style={{ width: "100%" }}>
        <Header
          windowWidth={windowWidth}
          showCreate={true}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      </div>

      {windowWidth && windowWidth < 850 ? (
        <div className="mobile-header-crt">
          <HeaderCreate />
        </div>
      ) : null}
      {tasks.activeIcon !== "block" &&
      tasks.activeIcon !== "database" &&
      tasks.activeIcon !== "dataset" &&
      tasks.activeIcon !== "settings" &&
      tasks.activeIcon !== "BiTask" &&
      tasks.activeIcon !== "axons" &&
      !window.location.pathname.includes("/space/") &&
      tasks.activeIcon !== "chat_log" ? (
        <div className="assets-bx-h1">
          <Assets
            loading2={loading2}
            setLoading2={setLoading2}
            setCallApi={setCallApi}
            callApi={callApi}
          />
        </div>
      ) : null}
      {tasks.activeIcon === "settings" ? <Settings /> : null}

      {tasks.activeIcon === "BiTask" ? <ChatLogTask /> : null}

      {window.location.pathname.includes("/space/") ? <Assets3 /> : null}
      {tasks.activeIcon === "database" ? (
        <Assets2
          loading2={loading2}
          setLoading2={setLoading2}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ) : null}
      {tasks.activeIcon === "axons" ||
      tasks.activeIcon === "block" ||
      tasks.activeIcon === "chat_log" ||
      tasks.activeIcon === "dataset" ? (
        <div className="assets-bx-h1">
          <MergTabs />
        </div>
      ) : null}
      {/* <CineTask /> */}
      {/* {editor.activeGoal ? <GoalsHome /> : null} */}
    </div>
  );
}
