import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import { Button, Input } from "antd";

export default function DevelopersHub() {
  const { user, setUser, organization, setShowPrice } =
    useContext(SearchContext);
  const [visible, setVisible] = useState();

  return (
    <div>
      <div className="profile-edit-page">
        {organization ? (
          <div className="form-group mb-3">
            <table>
              <tr>
                <td>
                  <b>Developer Hub</b>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Api Key:</td>
                <td colSpan={2}>
                  <Input.Password
                    placeholder="input password"
                    value={organization.api_key}
                    visibilityToggle={{
                      visible: visible,
                      onVisibleChange: setVisible,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Usage:</td>
                <td>${organization.chat_count * 0.03} </td>
              </tr>
              <tr>
                <td>Balance:</td>
                <td>${organization.reserved_compute_credits} </td>
                <td>
                  {" "}
                  <a
                    href="https://buy.stripe.com/dR68xFbqyfkH4TKdR4"
                    target="blank"
                  >
                    <Button type="primary">Add Credit</Button>{" "}
                  </a>
                </td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <a href="https://docs.olm.ai/" target="blank">
                    <Button className="custom-button">Documentation</Button>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
}
