import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import SearchContext from "../../Context/SearchContext";
import { FaArrowRight, FaUpload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Input, Modal } from "antd";

export default function Story() {
  const navigate = useNavigate();
  const { user, videosFeed, setVideosFeed } = useContext(SearchContext);
  const [name, setName] = useState("");
  const [projects, setProjects] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [dispaly, setDisplay] = useState();

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/project?page=${pageNo}&limit=10&user_id=${
        user && user.user_id
      }&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setProjects(data.projectList);
          setTotalPages(data.totalPages);
        }
      });
  };
  const saveCreate = () => {
    const obj = { project_name: name, user_id: user && user.user_id };
    setDisplay();
    setName("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/project`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isCreated) {
          setProjects([...projects, data.project_payload]);
        }
      });
  };

  return (
    <div>
      <Header />
      <div className="container ">
        <div className="tabs-main-bx ">
          <div className="story-box-main-c">
            <div className="upload-icon-story">
              <span onClick={() => setDisplay(true)} className="InputContainer">
                <b>
                  <FaUpload />
                </b>
              </span>
            </div>
            {projects && projects.length === 0 ? (
              <div className="card21">
                <div className="card21-img"></div>
                <div className="card21-info">
                  <p className="text21-body">Imagine The World Around You</p>
                  <p className="txt-card-21">
                    Olm lets you generate original videos from scratch up to 1
                    min long. That can be short scenes, educational guides and
                    much more.
                  </p>
                  
                </div>
              </div>
            ) : null}
            {projects &&
              projects.map((p, pi) => {
                return (
                  <div
                    onClick={() => navigate(`/story/${p._id}`)}
                    key={pi}
                    className="InputContainer project-input-con"
                  >
                    <p className="project-name">
                      <span>{p.project_name}</span> <FaArrowRight />
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Modal
        open={dispaly}
        onCancel={() => {
          setDisplay();
          setName("");
        }}
        width={500}
        className="no-buttons-modal black-modal"
      >
        <h4>Project Name</h4>
        <div
          className="InputContainer create-pro-for"
          // style={{ display: "flex", alignItems: "center" }}
        >
          <Input
            placeholder="Project Name"
            value={name}
            className="form-control   "
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ borderRadius: "20px" }}
          />
        </div>
        {name.length ? (
          <div className="create-btn-pro">
            <Button type="primary" onClick={saveCreate}>
              Create
            </Button>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
