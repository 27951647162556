import React from "react";
import { GoogleLogin } from "@react-oauth/google";

const GoogleButton = ({ onLoginSuccess, onLoginFailure }) => {
  const handleLogin = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    const id_token = googleUser.getAuthResponse().id_token;
    onLoginSuccess({ profile, id_token });
  };

  const handleFailure = (error) => {
    console.error(error);
    onLoginFailure();
  };

  return (
    <GoogleLogin
      clientId="1078775797086-mbqn8mbvg3gt33tuvo8k63qocc1qp34l.apps.googleusercontent.com"
      onSuccess={handleLogin}
      onFailure={handleFailure}
    />
  );
};

export default GoogleButton;
