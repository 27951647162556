import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import loadingVideo from "../../../../../images/guide-video-loader.mp4";

export default function GuideLoadingVideo({ text, id }) {
  const [videoDuration, setVideoDuration] = useState(15);
  const playerRef = useRef(null);

  useEffect(() => {
    const words = text ? text : "";
    const wordsCount = words.length;

    let totalSeconds = 26.833333 * 1000;
    let oneWordTime = totalSeconds / 500;
    let maxPlay = wordsCount * oneWordTime;
    setVideoDuration(maxPlay);
    // console.log(text.length, id , maxPlay);
  }, [text]);
  const adjustPlay = (a) => {
    let t = (videoDuration - 1000) / 1000;
    t = videoDuration - 1000 < 0 ? 0 : t;
    playerRef.current.seekTo(t, "seconds");
    // console.log((videoDuration - 1000) / 1000, a, t);
  };
  return (
    <div className="video-loading-guide video-container radius-video">
      <ReactPlayer
        key={id}
        ref={playerRef}
        url={loadingVideo}
        controls={false}
        playing
        loop
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
        onProgress={(a, b) => {
          if (playerRef.current) {
            adjustPlay(a);
          }
        }}

        // duration={videoDuration}
      />
    </div>
  );
}
