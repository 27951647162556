import { Button, Col, Collapse, Drawer, Input, Modal, Row, Tag } from "antd";
import React from "react";
import { TiTick } from "react-icons/ti";

import Dendric1 from "../../../../../images/nlogo-white.png";

import AWS from "../../../../../images/AWS.png";
import Azure from "../../../../../images/Azure.png";
import Dendric from "../../../../../images/Dendric.png";
import GCP from "../../../../../images/GCP.png";
import OctoAI from "../../../../../images/OctoAI.png";
import Replicate from "../../../../../images/Replicate.png";
import togetherai from "../../../../../images/togetherai.jpeg";
import mistral from "../../../../../images/mistral.png";
import elevenlabs from "../../../../../images/elevenlabs.png";
import GuideLoadingVideo from "./GuideLoadingVideo";
import { FaAccusoft, FaArrowRight } from "react-icons/fa";
const images = {
  aws: AWS,
  azure: Azure,
  denric: Dendric,
  gcp: GCP,
  octoai: OctoAI,
  replicate: Replicate,
  togetherai,
  mistral,
  elevenlabs,
};
export default function ConnectionModal({
  showC,
  setShowC,
  saveModel,
  updateConnection,
}) {
  return (
    <Drawer
      width={600}
      open={showC !== undefined}
      onClose={() => setShowC()}
      className="black-drawer "
    >
      <h5 className="create-btn-h">
        {showC && showC._id ? "Edit Connection" : "Connect"}
      </h5>

      <div className="bx-form-brand">
        {showC ? (
          <>
            {" "}
            <Collapse
              className="black-collapse"
              defaultActiveKey={"Signal"}
              bordered={false}
              style={{ marginTop: "20px" }}
            >
              <Collapse.Panel header="Signal*" key="Signal">
                <div
                  style={{ alignItems: "center" }}
                  className="api-key-input-c"
                >
                  <b style={{ width: "100px" }}>Signal: </b>
                  <Input.TextArea
                    value={showC._id ? showC.guide : showC.guide2}
                    onChange={(e) =>
                      setShowC({
                        ...showC,
                        guide2: e.target.value,
                        guide: showC._id ? e.target.value : showC.guide,
                      })
                    }
                    autoSize={{ minRows: 2, maxRows: 5 }}
                    style={{ width: "300px" }}
                    className="grey-input"
                    placeholder="Add Guide"
                  />
                  <GuideLoadingVideo text={showC.guide} id={showC._id} />
                </div>
              </Collapse.Panel>
            </Collapse>
            <Collapse
              className="black-collapse no-padding-content"
              defaultActiveKey={"API Key"}
              bordered={false}
              style={{ marginTop: "20px" }}
            >
              <Collapse.Panel header="Authentication*" key="API Key">
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    style={{ padding: "10px" }}
                  >
                    <div
                      style={{
                        borderColor:
                          showC.cType === "Managed Service" ||
                          showC.status === "managed_service"
                            ? "#cfef00"
                            : "#2a2a2a",
                        boxShadow:
                          showC.cType === "Managed Service" ||
                          showC.status === "managed_service"
                            ? "4px 4px 4px #cfef00"
                            : "4px 4px 4px #2a2a2a",
                      }}
                      class="card-m-c-b"
                    >
                      <img src={Dendric1} alt="" />
                      {/* <div class="img-m-c-b"></div> */}

                      <div class="card-title-m-c-b">Fulfiled by Dendric</div>
                      <div class="card-subtitle-m-c-b">
                        {showC.connect_type === "video"
                          ? "Dendric fulfills your request using our infrastructure, services and billing. Video output is billed per video second."
                          : showC.connect_type === "image"
                          ? "Dendric fulfills your request using our infrastructure, services and billing. Image output is per image."
                          : showC.connect_type === "text"
                          ? "Dendric fulfills your request using our infrastructure, services and billing. Text is billed per 1000 tokens of the query."
                          : showC.connect_type === "sound"
                          ? "Dendric fulfills your request using our infrastructure, services and billing. Sound output is billed per second of audio."
                          : showC.connect_type === "voice"
                          ? "Dendric fulfills your request using our infrastructure, services and billing. Vocal output is billed per 1000 characters."
                          : showC.connect_type === "music"
                          ? "Dendric fulfills your request using our infrastructure, services and billing. Music output is billed per second of audio."
                          : showC.connect_type === "code"
                          ? ""
                          : null}
                      </div>
                      <hr class="card-divider-m-c-b" />
                      <div class="card-footer-m-c-b">
                        <div class="card-price-m-c-b">
                          <span>$</span>
                          {showC.connect_type === "video"
                            ? "0.02"
                            : showC.connect_type === "image"
                            ? "0.015"
                            : showC.connect_type === "text"
                            ? "0.0008"
                            : showC.connect_type === "sound"
                            ? "0.005"
                            : showC.connect_type === "voice"
                            ? "0.005"
                            : showC.connect_type === "music"
                            ? "0.005"
                            : showC.connect_type === "code"
                            ? ""
                            : null}
                        </div>
                        <button
                          onClick={() =>
                            setShowC({
                              ...showC,
                              cType: "Managed Service",
                              status: "managed_service",
                            })
                          }
                          className="btn-yel-c"
                        >
                          <span>
                            {showC.cType === "Managed Service" ||
                            showC.status === "managed_service"
                              ? "Active"
                              : "Connect"}
                          </span>
                          {showC.cType === "Managed Service" ||
                          showC.status === "managed_service" ? (
                            <TiTick />
                          ) : (
                            <FaArrowRight />
                          )}
                        </button>
                      </div>
                    </div>
                  </Col>
                  {showC.compute === "dendric" ? null : (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      style={{ padding: "10px" }}
                    >
                      <div
                        style={{
                          borderColor:
                            showC.cType === "Connect Your Account" ||
                            showC.status === "api_key"
                              ? "#cfef00"
                              : "#2a2a2a",
                          boxShadow:
                            showC.cType === "Connect Your Account" ||
                            showC.status === "api_key"
                              ? "4px 4px 4px #cfef00"
                              : "4px 4px 4px #2a2a2a",
                        }}
                        class="card-m-c-b"
                      >
                        <img
                          src={images[showC.compute?.toLowerCase()]}
                          alt=""
                        />
                        <div class="card-title-m-c-b">
                          Fulfiled by{" "}
                          <span style={{ textTransform: "uppercase" }}>
                            {showC.compute}
                          </span>
                        </div>
                        <div class="card-subtitle-m-c-b">
                          {showC.connect_type === "video"
                            ? "Dendric fulfills your request using our infrastructure, services and billing. Video output is billed per video second."
                            : showC.connect_type === "image"
                            ? "Dendric fulfills your request using our infrastructure, services and billing. Image output is per image."
                            : showC.connect_type === "text"
                            ? "Dendric fulfills your request using our infrastructure, services and billing. Text is billed per 1000 tokens of the query."
                            : showC.connect_type === "sound"
                            ? "Dendric fulfills your request using our infrastructure, services and billing. Sound output is billed per second of audio."
                            : showC.connect_type === "voice"
                            ? "Dendric fulfills your request using our infrastructure, services and billing. Vocal output is billed per 1000 characters."
                            : showC.connect_type === "music"
                            ? "Dendric fulfills your request using our infrastructure, services and billing. Music output is billed per second of audio."
                            : showC.connect_type === "code"
                            ? ""
                            : null}
                        </div>
                        <hr class="card-divider-m-c-b" />
                        <div class="card-footer-m-c-b">
                          <div class="card-price-m-c-b">
                            <span>$</span> {showC.cost}
                          </div>
                          <button
                            onClick={() =>
                              setShowC({
                                ...showC,
                                cType: "Connect Your Account",
                                status: "api_key",
                              })
                            }
                            className="btn-yel-c"
                          >
                            <span>
                              {showC.cType === "Connect Your Account" ||
                              showC.status === "api_key"
                                ? "Active"
                                : "Connect"}
                            </span>
                            {showC.cType === "Connect Your Account" ||
                            showC.status === "api_key" ? (
                              <TiTick />
                            ) : (
                              <FaArrowRight />
                            )}
                          </button>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                {showC.cType === "Connect Your Account" ? (
                  <div className="api-key-input-c">
                    <b style={{ width: "140px" }}>API Key: </b>
                    <Input
                      value={showC.api_key}
                      onChange={(e) =>
                        setShowC({ ...showC, api_key: e.target.value })
                      }
                      style={{ width: "300px" }}
                      className="grey-input"
                      placeholder="**************"
                    />
                  </div>
                ) : null}

                {showC.compute === "aws" || showC.compute === "s3" ? (
                  <div>
                    <div className="api-key-input-c">
                      <b style={{ width: "140px" }}>Secret Access Key: </b>
                      <Input
                        value={
                          showC._id
                            ? showC.secretAccessKey
                            : showC.secretAccessKey2
                        }
                        onChange={(e) =>
                          setShowC({
                            ...showC,
                            secretAccessKey2: e.target.value,
                            secretAccessKey: showC._id
                              ? e.target.value
                              : showC.secretAccessKey,
                          })
                        }
                        style={{ width: "300px" }}
                        className="grey-input"
                        placeholder="**************"
                      />
                    </div>
                    <div className="api-key-input-c">
                      <b style={{ width: "140px" }}>Access Key ID: </b>
                      <Input
                        value={
                          showC._id ? showC.accessKeyId : showC.accessKeyId2
                        }
                        onChange={(e) =>
                          setShowC({
                            ...showC,
                            accessKeyId2: e.target.value,
                            accessKeyId: showC._id
                              ? e.target.value
                              : showC.accessKeyId,
                          })
                        }
                        style={{ width: "300px" }}
                        className="grey-input"
                        placeholder="**************"
                      />
                    </div>
                    {showC.compute === "s3" ? (
                      <div className="api-key-input-c">
                        <b style={{ width: "140px" }}>Bucket ID: </b>
                        <Input
                          value={showC._id ? showC.bucket_id : showC.bucket_id2}
                          onChange={(e) =>
                            setShowC({
                              ...showC,
                              bucket_id2: e.target.value,
                              bucket_id: showC._id
                                ? e.target.value
                                : showC.bucket_id,
                            })
                          }
                          style={{ width: "300px" }}
                          className="grey-input"
                          placeholder="**************"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </Collapse.Panel>
            </Collapse>
          </>
        ) : null}

        {showC && showC.cType && showC._id === undefined ? (
          <div className="delet-btns-h">
            <Button
              className={`editor-tag-tab active-tg-wdt `}
              size="sm"
              onClick={() => setShowC()}
              variant="light"
            >
              Cancel
            </Button>{" "}
            {showC.cType === "Managed Service" ||
            (showC.cType === "Connect Your Account" &&
              showC.api_key &&
              showC.api_key.length > 4) ? (
              <Button
                onClick={() => {
                  saveModel(showC);
                  setShowC();
                }}
                variant="danger"
                size="sm"
                className={`editor-tag-tab active-tg-wdt yellow-button-c`}
              >
                Build
              </Button>
            ) : null}
          </div>
        ) : null}
        {showC && showC._id ? (
          <div className="delet-btns-h">
            <Button
              className={`editor-tag-tab active-tg-wdt `}
              size="sm"
              onClick={() => setShowC()}
              variant="light"
            >
              Cancel
            </Button>{" "}
            <Button
              className={`editor-tag-tab active-tg-wdt yellow-button-c`}
              onClick={() => {
                updateConnection(showC, showC._id);
                setShowC();
              }}
              variant="danger"
              size="sm"
            >
              Update
            </Button>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
}
