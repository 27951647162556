import {
  Badge,
  Button,
  Input,
  Popover,
  Spin,
  Tag,
  Tooltip,
  message,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";

import { FaDotCircle } from "react-icons/fa";
import { MdClose, MdKeyboardVoice } from "react-icons/md";
import { TbBrain } from "react-icons/tb";

import SearchContext from "../../../../../Context/SearchContext";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
export default function HeaderCreateShare() {
  const {
    listData,
    setListData,
    user,
    setTasks,
    setImages,
    images,
    ref1,
    ref2,
    ref3,
    ref7,
    ref8,
    tasks,
    organization,
    setShowPrice,
  } = useContext(SearchContext);
  const [search, setSearch] = useState("");
  const [callApi, setCallApi] = useState(1);
  const [loadingS, setLoadingS] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,

        color: "white",
      }}
      spin
    />
  );

  //NO USER HOME PAGE
  if (!user || !user.user_id) {
    // Render Sign Up and Sign In buttons if user_id is not present
    return (
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      ></div>
    );
  }

  const createTask = () => {
    const obj = {
      query: search,
      related_data: tasks.activeGoal?._id,
      user_id: user && user.user_id,
      attachment: images.attachment,
      workspace: window.location.pathname.includes("/space/")
        ? window.location.pathname.slice(7)
        : undefined,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/fx`, requestOptions)
      .then((response) => {
        if (response.status === 429) {
          setSearch("");
          setLoadingS(false);
          setShowPrice(true);

          throw new Error("Too Many Requests (429)");
        }
        return response.json();
      })
      .then((data) => {
        setSearch("");
        setLoadingS(false);
        if (data.data) {
          // setImages({
          //   ...images,
          //   reload1: images.reload1 ? images.reload1 + 1 : 1,
          // });

          message.success("Processing");
        } else {
          console.log("API call succeeded, but data is missing");
          message.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        if (error.message !== "Too Many Requests (429)") {
          message.error("Something went wrong. Please try again later.");
        }
      });
  };
  const handleClearAttachments = () => {
    // Clear all attachments
    setImages({
      ...images,
      attachment: undefined,
      videoId: undefined,
      imageId: undefined,
      musicId: undefined,
      soundId: undefined,
      voiceId: undefined,
    });
  };
  const attachments = () => {
    let showImg = images.videoId
      ? images.videoId
      : images.imageId
      ? images.imageId
      : images.musicId
      ? images.musicId
      : images.soundId
      ? images.soundId
      : images.voiceId;

    let t = images.attachment?.length;
    // t = images.videoId ? t + 1 : t;
    // t = images.imageId ? t + 1 : t;
    // t = images.musicId ? t + 1 : t;
    // t = images.soundId ? t + 1 : t;
    // t = images.voiceId ? t + 1 : t;

    return t > 0 ? (
      <div className="search-bx-c-cine">
        <div className="img-active-brain">
          <Badge color="#BC1823" count={images.attachment?.length}>
            {showImg?.media_type === "image" ? (
              <img src={showImg?.file_url} alt="" />
            ) : (
              <img src={showImg?.root_image} alt="" />
            )}
          </Badge>
          <span onClick={handleClearAttachments} className="clear-icon">
            {/* Assuming you have an X icon component or an SVG for the X icon */}
            <MdClose />
          </span>
        </div>
      </div>
    ) : null;
  };

  const handleSearch = () => {
    if (!loadingS && search.trim().length) {
      createTask(search);
      setLoadingS(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <div className="header-create-c">
      <div className="start-icons-bx less-width-bx">
        <Tooltip title="FXS1">
          <div
            className={`icon-left-ed ${
              tasks.activeIcon === "task" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              if (tasks.stopGet) {
                setImages({
                  ...images,
                  pageNo: 1,
                  data: undefined,
                });
                setTasks({
                  ...tasks,
                  stopGet: undefined,
                  callGenerate: tasks.callGenerate ? tasks.callGenerate + 1 : 1,
                  activeIcon: "task",
                });
              } else {
                setTasks({ ...tasks, activeIcon: "task" });
              }
            }}
          >
            <FaDotCircle />
          </div>
        </Tooltip>
      </div>

      {tasks.activeIcon === "task" ? (
        <div style={{ display: "flex" }} className="create-input-fvc">
          <div
            className={`create-input-fvc2  ${
              search.length > 44 ? " popup-input" : ""
            }`}
          >
            {attachments()}

            <Input.TextArea
              autoSize={{ maxRows: 4, minRows: 1 }}
              style={{
                width:
                  search.length < 27
                    ? 300
                    : search.length > 35
                    ? 420
                    : search.length > 27
                    ? 350
                    : 300,
              }}
              type="text"
              placeholder="Generate"
              onChange={(e) => {
                if (e.target.value.length < 1000) {
                  setSearch(e.target.value);
                }
              }}
              value={search}
              required=""
              className={`form-control border-0  px-4 grey-input main-search-cine`}
              onKeyPress={handleKeyPress}
            />
            {search.trim().length ? (
              loadingS ? (
                <div className="loader-c-sd">
                  <Spin
                    style={{
                      fontSize: 20,
                      marginLeft: "10px",
                    }}
                    size="small"
                    indicator={antIcon}
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    if (!loadingS) {
                      createTask(search);
                      setLoadingS(true);
                    }
                  }}
                  style={{ padding: "0px", width: "auto" }}
                  className={` start-card-bx `}
                >
                  <div style={{ padding: "8px" }} className="icon-planing-c">
                    <TbBrain style={{ marginRight: "0px", fontSize: "24px" }} />
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      ) : null}

      <div></div>
    </div>
  );
}
