import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Typography,
  notification,
} from "antd";
import { TiTick } from "react-icons/ti";
import ReactPlayer from "react-player";
import profileImg from "../../../images/profile-icon.png";
import { FaPlay, FaShare } from "react-icons/fa";
import olmLogo from "../../../images/olm_logo.png";
import { BiTime } from "react-icons/bi";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const { Paragraph, Text } = Typography;
const { Option } = Select; // Destructure Option from Select

export default function VideoArea({ video }) {
  const { user, videosFeed, setVideosFeed, setShowPrice, organization } =
    useContext(SearchContext);
  const [play, setPlay] = useState(false);
  const [reimagine, setReimagine] = useState();
  const [textField, setTextField] = useState(""); // State to store text input
  const [duration, setDuration] = useState(4); // State to store duration input
  const [voice, setVoice] = useState(false);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification.success({
      duration: 0,
      message: "Video is being created. Check studio for updates",
    });
  };
  const updateLikes = (obj, id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/like`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          //    setDisplayVid(response.imageData)
        } else {
          // message.error("somehing went wrong");
        }
      });
  };

  const changeVideo = (row) => {
    row.likes = video.is_liked ? row.likes - 1 : row.likes + 1;
    row.is_liked = !row.is_liked;
    const all = videosFeed.map((m) => (m._id === row._id ? row : m));
    setVideosFeed(all);
  };
  const changePlay = (row) => {
    row.isPlay = true;
    const all = videosFeed.map((m) => (m._id === row._id ? row : m));
    setVideosFeed(all);
  };
  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };

  const handleSendClick = async () => {
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/remix",
        {
          query: textField,
          user_id: user && user.user_id,
          duration: duration,
          // voice,
          image_id: video._id,
        }
      );
      if (response.data?.isSuccess) {
        openNotificationWithIcon();
        setReimagine();
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
  return (
    <div className="video-feed-mbl-w">
      {/* <div className="feed-profile-area">
        <img src={video.image ? video.image : olmLogo} alt="" />
        <div>
          <b></b>
        </div>
      </div> */}
      {play ? null : (
        <div
          className={`feed-video-footer ${play ? " " : " thumbnail-footer"}`}
        >
          <p>
            <b>
              {" "}
              <Paragraph
                ellipsis={{
                  rows: 1,
                  expandable: false,
                  symbol: "",
                }}
              >
                {video.query}
              </Paragraph>
            </b>
          </p>
          <div className="likes-area">
            <span className="heart-icon">
              <div title="Like" className="heart-container">
                <input
                  id="Give-It-An-Id"
                  className="checkbox"
                  onClick={() => {
                    if (user && user.name) {
                      changeVideo(video);
                      updateLikes(
                        {
                          image_id: video._id,
                          username: user.username,
                        },
                        video._id
                      );
                    } else {
                      navigate("/login");
                    }
                  }}
                  checked={video.is_liked}
                  type="checkbox"
                  readOnly
                />
                <div className="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-outline"
                    viewBox="0 0 24 24"
                  >
                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-filled"
                    viewBox="0 0 24 24"
                  >
                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="100"
                    width="100"
                    className="svg-celebrate"
                  >
                    <polygon points="10,10 20,20"></polygon>
                    <polygon points="10,50 20,50"></polygon>
                    <polygon points="20,80 30,70"></polygon>
                    <polygon points="90,10 80,20"></polygon>
                    <polygon points="90,50 80,50"></polygon>
                    <polygon points="80,80 70,70"></polygon>
                  </svg>
                </div>
              </div>

              <b>{video.likes}</b>
            </span>
          </div>
        </div>
      )}
      <div className="feed-video-mc1">
        {play ? (
          <div className="video-container">
            <ReactPlayer
              controls
              url={video.file_url}
              playing={play}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        ) : (
          <div className="video-thumbnail-img">
            {video.frame ? (
              <img src={video.frame} alt="" />
            ) : (
              <div className="video-container">
                <ReactPlayer controls={false} url={video.file_url} />
              </div>
            )}
            <div className="icons-bx-ri-vd2">
              <span className="play-blur-glow">
                <Button
                  onClick={() => setPlay(true)}
                  size="large"
                  className="large-play-btn"
                >
                  <FaPlay className="r-atc-incs" />
                </Button>
              </span>
            </div>
          </div>
        )}
      </div>
      {play ? (
        <div className="reimagine-b-mai">
          <Button
            onClick={() => {
              if (user && user.name) {
                if (organization && organization.subscription === "Pro") {
                  setShowPrice(true);
                } else {
                  setReimagine(video);
                }
              } else {
                navigate("/login");
              }
            }}
          >
            Reimagine
          </Button>
        </div>
      ) : (
        <div className="duration-box">
          <b>
            {video.video_duration
              ? TimeCorrection(Math.floor(video.video_duration))
              : "00:00:00"}
          </b>

          <b
            className="click-profile-b"
            onClick={() => navigate(`/profile/${video.username}`)}
          >
            <span>By</span>
            <img
              className="logo-img-video-create"
              src={video.profile_picture ? video.profile_picture : olmLogo}
              alt=""
            />
            {video.username}
          </b>
        </div>
      )}
      <div />
      <Modal
        open={reimagine}
        onCancel={() => {
          setReimagine();
        }}
        width={600}
        className="no-buttons-modal "
      >
        <div className="create-tab">
          {/* Create Tab Content Goes Here */}
          <div
            className="InputContainer"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Input.TextArea
              placeholder="Type your text here"
              value={textField}
              className="form-control border-0  px-4"
              onChange={(e) => setTextField(e.target.value)}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </div>
          <div className="duration-box-m">
            <div className="InputContainer">
              <Select
                placeholder="Time"
                style={{ width: 120 }} // Set the desired width in pixels
                value={duration}
                onChange={(value) => setDuration(value)}
              >
                {/* <Option disabeled value={""}>
              Duration
            </Option> */}
            <Option value={1}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  3s
                </Option>
                <Option value={2}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  6s
                </Option>
                <Option value={4}>
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  12s
                </Option>
                <Option value={6}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  18s
                </Option>
                <Option value={8}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  24s
                </Option>
                <Option value={10}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  30s
                </Option>
                <Option value={12}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  36s
                </Option>
                <Option value={14}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  42s
                </Option>
                <Option value={16}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  48s
                </Option>
                <Option value={18}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  54s
                </Option>
                <Option value={20}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  1m
                </Option>
              </Select>
            </div>

            <div className="create-button-con">
              <button onClick={handleSendClick} className="button-21">
                Imagine
                <div className="hoverEffect">
                  <div></div>
                </div>
              </button>
            </div>
          </div>
          <div className="card21">
            <div className="card21-img"></div>
            <div className="card21-info">
              <p className="text21-body">Imagine The World Around You</p>
              <p className="txt-card-21">
                Olm lets you generate original videos from scratch up to 1 min
                long. That can be short scenes, educational guides and much
                more.
              </p>
              {/* </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
