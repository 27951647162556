import { Input, message, Modal, Button } from "antd";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { FaCloudShowersHeavy } from "react-icons/fa";
import bcrypt from "bcryptjs";
import SearchContext from "../../../Context/SearchContext";

export default function EditPassword() {
  const { user, theme } = useContext(SearchContext);
  const [editP, setEditP] = useState(false);
  const [oldP, setOldP] = useState("");
  const [newP, setNewP] = useState("");
  const [newP2, setNewP2] = useState("");
  const [matchErr, setmatchErr] = useState(false);
  const [oldErr, setoldErr] = useState(false);

  const closeModal = () => {
    setEditP(false);
    setOldP("");
    setNewP("");
    setNewP2("");
    setmatchErr(false);
    setoldErr(false);
  };

  const saveForm = () => {
    if (newP !== newP2) {
      setmatchErr(true);
    } else {
      updatePassword();
    }
  };

  const checkPassword = (p1, p2) => {
    if (p1 === p2) {
      setmatchErr(false);
    }
  };
  const updatePassword = () => {
    const obj = {
      old_password: bcrypt.hashSync(oldP, "$2a$10$CwTycUXWue0Thq9StjUM0u"),
      new_password: bcrypt.hashSync(newP, "$2a$10$CwTycUXWue0Thq9StjUM0u"),
    };
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.ACCESS_TOKEN,
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://embed.axv.ai/api/consumer/change_pass/${user.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          closeModal();
          message.success("Password Updated");
        } else if (data && data.message === "Wrong Password") {
          setoldErr(true);
        }
      });
  };

  return (
    <div>
      <Button
        className={`editor-tag-tab active-tg-wdt`}
        type="primary"
        onClick={() => setEditP(true)}
      >
        Change
      </Button>
      <Modal
        className={`no-h-bo-b-modal brand-modal ${theme}-theme`}
        open={editP}
        onCancel={() => {
          closeModal();
        }}
        width={500}
      >
        <h5 className="create-btn-h">Change Password</h5>
        <div className=" bx-form-brand form-pas-bxc">
          <table>
            <tbody>
              <tr className="input-bx-pas">
                <td>
                  <h6>Old Password:</h6>
                </td>
                <td>
                  <Input.Password
                    size="large"
                    placeholder="Old Password"
                    onChange={(e) => {
                      setOldP(e.target.value);
                      setoldErr(false);
                    }}
                    value={oldP}
                    className={`${oldErr ? "err-field" : ""}`}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {oldErr ? (
                    <p className="p-n-match-c">Password is not correct</p>
                  ) : null}
                </td>
              </tr>
              <tr className="input-bx-pas">
                <td>
                  <h6>New Password:</h6>
                </td>
                <td>
                  <Input.Password
                    size="large"
                    placeholder="New Password"
                    onChange={(e) => {
                      setNewP(e.target.value);
                      checkPassword(e.target.value, newP2);
                    }}
                    value={newP}
                    className={`${matchErr ? "err-field" : ""}`}
                  />
                </td>
              </tr>
              <tr className="input-bx-pas">
                <td>
                  <h6>Confirm Password:</h6>
                </td>
                <td>
                  <Input.Password
                    size="large"
                    placeholder="********"
                    onChange={(e) => {
                      setNewP2(e.target.value);
                      checkPassword(newP, e.target.value);
                    }}
                    value={newP2}
                    className={`${matchErr ? "err-field" : ""}`}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {matchErr ? (
                    <p className="p-n-match-c">Password does not match</p>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="up-pd-os">
                  <Button
                    disabled={
                      newP.trim().length === 0 ||
                      newP2.trim().length === 0 ||
                      oldP.trim().length === 0
                    }
                    onClick={saveForm}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
}
