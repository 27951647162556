import React from "react";
import { FaPlay } from "react-icons/fa";
import loader from "../../../images/nlogo-red.png";
export default function FullLoader() {
  return (
    <div className="loading-full-page trasparent-loader">
      <img src={loader} alt="" />
    </div>
  );
}
