import React, { useState } from "react";
import { Button, Modal } from "antd";
import axios from "axios";
import { Document, Page } from "react-pdf";
import loader from "../../../../../images/nlogo-red.png";

export default function OpenCsvBlocks({ row }) {
  const [show, setShow] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchPdfData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(row.file_url, {
        responseType: "arraybuffer",
      });
      const pdfBlob = new Blob([response.data]);
      setPdfData(pdfBlob);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching PDF data:", error);
      setLoading(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Button
        style={{ marginLeft: "10px" }}
        className={`editor-tag-tab active-tg-wdt`}
        size="small"
        onClick={() => {
          fetchPdfData();
          setShow(true);
        }}
      >
        Open
      </Button>
      <Modal
        visible={show}
        onCancel={() => setShow(false)}
        width={1000}
        className="no-buttons-modal black-modal top-20-modal"
        footer={null}
      >
        <div>
          <h2>{row.value}</h2>
          {loading ? (
            <img
              style={{ marginTop: "-150px" }}
              className="image-table-loader"
              src={loader}
              alt=""
            />
          ) : (
            <Document
              file={pdfData}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          )}
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </div>
      </Modal>
    </>
  );
}
