import React, { useContext, useEffect, useState } from "react";
import { BiEdit, BiSolidHomeSmile } from "react-icons/bi";
import SearchContext from "../../../../Context/SearchContext";
import SaveCanvas from "./SaveCanvas";
import { Button, Dropdown, Input, Modal, Select, message } from "antd";
import { useNavigate } from "react-router-dom";
import fx from "../../../../images/fx2.png";
import { FaPaintBrush, FaVideo } from "react-icons/fa";

import { CiEdit } from "react-icons/ci";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";

const { Option } = Select;

export default function TopBar({ home }) {
  const {
    editor,
    seteditor,
    user,
    stories,
    setStories,
    storyChange,
    setStoryChange,
    editorLeft,
    seteditorLeft,
  } = useContext(SearchContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [callApi, setCallApi] = useState(1);
  const [imgLoading, setImgLoading] = useState(false);
  const [edit, setEdit] = useState();
  const [taskId, setTaskID] = useState();

  useEffect(() => {
    if (taskId) {
      getTasks(taskId);
    }
  }, [callApi]);
  const items = [
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];
  const logout = () => {
    window.localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  const previewImage = (v) => {
    const obj = {
      query: v ? v : search,
      //   image_id: editor.activeContent && editor.activeContent._id,
    };
    if (editor.activeContent && editor.activeContent.file_url === undefined) {
      setImgLoading(true);
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/preview/image`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.data?.data) {
          seteditor({
            ...editor,
            activeContent: {
              ...editor.activeContent,
              imgLoading: undefined,
              file_url2: data.data?.data,
              file_url: "pending",
              img_id: data.data?._id,
            },
          });
          changeData({
            ...editor.activeContent,
            imgLoading: undefined,
            file_url2: data.data?.data,
            file_url: "pending",
            img_id: data.data?._id,
          });
          //   setTaskID(data.data._id);
          //   setCallApi(callApi + 1);
        } else {
          let c = {
            ...editor.activeContent,
            imgLoading: undefined,
            file_url: "pending",
          };
          seteditor({
            ...editor,
            activeContent: c,
          });
          changeData(c);
        }
      });
  };
  const previewVideo = (v) => {
    const obj = {
      query: v ? v : search,
      preview_id: editor.activeContent?.img_id,
      //   image_id: editor.activeContent && editor.activeContent._id,
    };
    if (editor.activeContent && editor.activeContent.file_url === undefined) {
      setImgLoading(true);
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/preview/video`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.data?.data) {
          // seteditor({
          //   ...editor,
          //   activeContent: {
          //     ...editor.activeContent,
          //     imgLoading: undefined,
          //     file_url2: data.data?.data,
          //     file_url: "pending",
          //     img_id: data.data?._id,
          //   },
          // });
          // changeData({
          //   ...editor.activeContent,
          //   imgLoading: undefined,
          //   file_url2: data.data?.data,
          //   file_url: "pending",
          //   img_id: data.data?._id,
          // });
          //   setTaskID(data.data._id);
          //   setCallApi(callApi + 1);
        } else {
        }
      });
  };
  const changeImage = (v) => {
    const obj = {
      query: search,
      image_id: editor.activeContent && editor.activeContent._id,
    };
    if (editor.activeContent && editor.activeContent.file_url === undefined) {
      setImgLoading(true);
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/fx`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          setTaskID(data.data._id);
          setCallApi(callApi + 1);
        }
      });
  };
  const getTasks = (ID) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/task/${ID}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let content = data.task.goals?.[0];
          if (
            editor.activeContent &&
            editor.activeContent.file_url === undefined &&
            content &&
            content.file_url !== "pending"
          ) {
            //for empty page loading
            seteditor({
              ...editor,
              activeContent: { ...content, imgGoals: data.task },
            });
            // changeData({ ...content, imgGoals: data.task })
          } else {
            //for already have image in center
            seteditor({
              ...editor,
              activeContent: { ...editor.activeContent, imgGoals: data.task },
            });
            changeData({ ...editor.activeContent, imgGoals: data.task });
          }
          if (
            data.task.status === "planning" ||
            data.task.status === "working"
          ) {
            setTimeout(() => {
              setCallApi(callApi + 1);
            }, 4000);
          }
          if (data.task.status === "complete") {
            setImgLoading(false);
          }
        }
      });
  };
  const changeData = (d) => {
    let all = stories.data.map((f) => (f._id === d._id ? d : f));
    setStories({
      ...stories,
      data: all,
    });
  };
  const saveProject = (obj, id, g) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/project/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          let all = editor.projects.map((p) =>
            p._id === id ? response.patchedData : p
          );
          // saveSelected(g);
          seteditor({ ...editor, projects: all });
          setStories({ ...stories, active: response.patchedData });
        } else {
          message.error("something went wrong");
        }
      });
  };
  const [showShare, setShowShare] = useState();
  const copyToClipboard = () => {
    const textToCopy = showShare;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  return (
    <div className={`top-inner-c ${home ? " top-inner-bl" : ""}`}>
      <div className="home-to-name">
        <span className="">
          <BiSolidHomeSmile
            onClick={() => {
              setStoryChange(!storyChange);
              navigate("/");
              seteditorLeft(editorLeft + 1);
              setStories({ pageNo: 1 });
              seteditor({
                cType: "cine",
                width: 80,
                vPageNo: 1,
                iPageNo: 1,
                sPageNo: 1,
                voPageNo: 1,
                bPageNo: 1,
                cnPageNo: 1,
              });
            }}
            className="home-icon0-t"
          />
        </span>
        {stories.active ? (
          <div className="story-pro-name-c">
            {stories.active?.project_name}{" "}
            <MdKeyboardArrowRight
              className="edit-icn-c"
              onClick={() => {
                setEdit(stories.active);
                setShowShare(
                  `${window.location.origin}/share/${stories.active._id}`
                );
              }}
            />
          </div>
        ) : null}
        {/* <Select
          placeholder="Time"
          style={{ width: 100 }} // Set the desired width in pixels
          value={editor.activeType}
          onChange={(value) => {
            seteditor({
              ...editor,
              activeContent: {},
              activeType: value,
            });
          }}
          className="select-left-radius"
        >
          <Option value={"image"}>Image</Option>
          <Option value={"video"}>Video</Option>
          <Option value={"audio"}>Audio</Option>
        </Select> */}
      </div>
      <div className="center-input-u-img">
        <input
          style={{
            width: 300,
          }}
          type="text"
          placeholder="Search"
          onChange={(e) => {
            setSearch(e.target.value);

            if (
              editor.imageBlank === "paint" &&
              e.target.value.slice(e.target.value.length - 1) === " "
            ) {
              previewImage(e.target.value);
              seteditor({
                ...editor,
                activeContent: {
                  ...editor.activeContent,
                  imgLoading: true,
                  file_url: undefined,
                },
              });
              changeData({
                ...editor.activeContent,
                imgLoading: true,
                file_url: undefined,
              });
            }
          }}
          value={search}
          required=""
          className="form-control border-0 shadow-sm px-4 "
        />
        {editor.imageBlank === "paint" && search.trim().length ? (
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                previewImage(search);
                seteditor({
                  ...editor,
                  activeContent: {
                    ...editor.activeContent,
                    imgLoading: true,
                    file_url: undefined,
                  },
                });
                changeData({
                  ...editor.activeContent,
                  imgLoading: true,
                  file_url: undefined,
                });
              }}
              style={{ padding: "0px", width: "auto" }}
              className={` start-card-bx `}
            >
              <div style={{ padding: "8px" }} className="icon-planing-c">
                <FaPaintBrush
                  style={{ marginRight: "0px", fontSize: "24px" }}
                />
              </div>
            </div>
            {editor.activeContent?.img_id ? (
              <div
                onClick={() => {
                  previewVideo(search);
                }}
                style={{ padding: "0px", width: "auto" }}
                className={` start-card-bx `}
              >
                <div style={{ padding: "8px" }} className="icon-planing-c">
                  <FaVideo style={{ marginRight: "0px", fontSize: "24px" }} />
                </div>
              </div>
            ) : null}
          </div>
        ) : search.trim().length ? (
          <div
            onClick={changeImage}
            style={{ padding: "0px", width: "auto" }}
            className={` start-card-bx `}
          >
            <div className="icon-planing-c">
              <img src={fx} alt="" />
            </div>
          </div>
        ) : null}
      </div>
      {user && user.name ? (
        <div className="roadm-t-div" style={{ marginLeft: "20px" }}>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
          >
            <span style={{ color: "white" }} className="btn-appr signop-btn">
              {user.name}
            </span>
          </Dropdown>
        </div>
      ) : (
        <form className="d-flex"></form>
      )}
      <Modal
        open={edit !== undefined}
        onCancel={() => {
          setEdit();
          setShowShare();
        }}
        width={450}
        className="no-buttons-modal black-modal top-20-modal save-pro-bx"
      >
        {edit ? (
          <div>
            <h4>Project</h4>

            <Input
              placeholder="Project Name"
              value={edit.project_name}
              className="form-control grey-input  "
              onChange={(e) => {
                setEdit({ ...edit, project_name: e.target.value });
              }}
              style={{ borderRadius: "20px", maxWidth: "300px" }}
            />

            <div className="create-btn-pro">
              <Button
                onClick={() => {
                  saveProject({ project_name: edit.project_name }, edit._id);
                  setEdit();
                }}
              >
                Update
              </Button>
            </div>
          </div>
        ) : null}
        <div style={{ marginTop: "40px" }}>
          <h4>Share</h4>
          <Input
            onChange={(e) => {
              setShowShare(e.target.value);
            }}
            className="form-control grey-input  "
            style={{ borderRadius: "20px", maxWidth: "300px" }}
            value={showShare}
          />
          <div style={{ marginTop: "10px" }} className="flex-end">
            <Button onClick={copyToClipboard}>copy</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
