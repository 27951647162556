import {
  Button,
  Input,
  Modal,
  Select,
  Spin,
  Tag,
  Tooltip,
  notification,
} from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { FaPlus } from "react-icons/fa";
import Dragger from "antd/es/upload/Dragger";
import { FiUpload } from "react-icons/fi";
import UploadVideo from "./UploadVideo";
import UploadImage from "./UploadImage";
import UploadAudio from "./UploadAudio";
const { Option } = Select;

export default function AddBlock({ type }) {
  const { setImages, images, user, setShowPrice, behavior } =
    useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const [ut, setUt] = useState("video");
  const [UploadedFile, setUplaoded] = useState([]);
  const [mData, setMData] = useState("");
  const [videoErr, setVideoErr] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [lengthS, setLengthS] = useState(0);
  const [dAdd, setdAdd] = useState();

  const saveFile = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    // formData.append("assistant_id", pActive ? pActive._id : "");

    formData.append("purpose", "block");
    formData.append("headclip_id", id);
    formData.append("meta_data", mData);
    formData.append("media_type", "image");
    formData.append("user_id", user ? user.user_id : "map");

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => {
        if (response.status === 400) {
          setShowPrice(true);
          return response.json(); // You may also want to parse the error response
        }
        return response.json();
      })
      .then((data) => {
        setUplaoded([]);
        setFileList([]);
        setImageUrl();
        setMData("");
        setLoading(false);

        if (data && data.isSuccess) {
          setdAdd({ ...dAdd, file_url: data.data?.file_url });
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "We couldn't accept this visual content at this moment. Please contact us if there are any questions ",
          });
        }
      });
  };

  const saveAudio = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);

    formData.append("video_duration", lengthS);
    formData.append("purpose", "block");
    formData.append("index", "pending");
    formData.append("media_type", "audio");
    // formData.append("project_id", window.location.pathname.slice(7));
    formData.append("user_id", user ? user.user_id : "map");

    const vidId = Math.random();

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/upload/audio`, requestOptions)
      .then((response) => {
        if (response.status === 400) {
          setShowPrice(true);
          return response.json(); // You may also want to parse the error response
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        setFileList([]);
        setUplaoded([]);

        if (data && data.isSuccess) {
          setdAdd({ ...dAdd, file_url: data.data?.file_url });
        }
      });
  };
  const getVideoLength = (vLength) => {
    setLengthS(vLength);
    // if (vLength / 60 > 3) {
    //   setUplaoded([]);
    //   setVideoErr(true);
    // } else {
    //   setVideoErr(false);
    // }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
      // console.log(.url);
      //   if (info.file.status !== "uploading") {
      //   }
      //   if (info.file.status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully`);
      //   } else if (info.file.status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
  };

  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };

  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          <span>{file.name}</span>
        </>
      );
    } else {
      return <span>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
    setUplaoded([]);
  };

  const SaveBlock = (row) => {
    row.user_id = user.user_id;
    row.router_id = behavior.selected ? behavior.selected._id : "auto";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`https://embed.axv.ai/api/block`, requestOptions)
      .then((response) => {
        if (response.status === 400) {
          setShowPrice(true);
          return response.json(); // You may also want to parse the error response
        }
        return response.json();
      })
      .then((data) => {
        if (data.isSuccess) {
          //   const list = [...Entity];
          //   list.unshift(data.data);
          //   setEntity(list);

          const all = images.blocks
            ? [data.data, ...images.blocks]
            : [data.data];
          setImages({ ...images, blocks: all });
        }
      });
  };
  return (
    <>
      <Tooltip title="Upload">
        <FiUpload
          onClick={() =>
            setdAdd({
              block_name: "",
              block_text: "",
              block_type: "writing_style",
            })
          }
          style={{ fontSize: "22px", marginLeft: "10px" }}
          className="icon-add-plus-c"
        />
      </Tooltip>

      <Modal
        open={dAdd !== undefined}
        onCancel={() => setdAdd()}
        className="no-buttons-modal black-modal"
      >
        {dAdd && (type === "Assets" || type === undefined) ? (
          <div style={{marginTop:"20px"}} className="bx-form-brand no-radius-input">
            <div className="block-type-c">
              <Select
                placeholder="Type"
                style={{ width: 130 }}
                value={ut}
                onChange={(value) => {
                  setUt(value);
                }}
                className="grey-input"
              >
                <Option value={"video"}>Video</Option>
                <Option value={"image"}>Image</Option>
                <Option value={"music"}>Music</Option>
                <Option value={"sound"}>Sound</Option>
                <Option value={"voice"}>Voice</Option>
              </Select>
            </div>
            {ut === "video" ? <UploadVideo setdAdd={setdAdd} /> : null}
            {ut === "image" ? <UploadImage setdAdd={setdAdd} /> : null}
            {ut === "voice" || ut === "sound" || ut === "music" ? (
              <UploadAudio setdAdd={setdAdd} ut={ut} />
            ) : null}
          </div>
        ) : null}
        {dAdd && type === "Blocks" ? (
          <div  style={{marginTop:"20px"}}  className="bx-form-brand no-radius-input">
            <div className="block-type-c">
              <Select
                placeholder="Type"
                style={{ width: 130 }}
                value={dAdd.block_type}
                onChange={(value) => {
                  setdAdd({ ...dAdd, block_type: value, file_url: undefined });
                }}
                className="grey-input"
              >
                <Option value={"character"}>Character</Option>
                <Option value={"product"}>Product</Option>
                <Option value={"writing_style"}>Writing Style</Option>
                <Option value={"logo"}>Logo</Option>
                <Option value={"voice"}>Voice</Option>
              </Select>
            </div>
            {((dAdd.block_type === "logo" ||
              dAdd.block_type === "product" ||
              dAdd.block_type === "backdrop" ||
              dAdd.block_type === "voice" ||
              dAdd.block_type === "character") &&
              dAdd.file_url) ||
            dAdd.block_type === "writing_style" ? (
              <>
                <table className="block-add-tbk">
                  <tbody>
                    {dAdd.file_url && dAdd.block_type !== "voice" ? (
                      <tr>
                        <td>
                          <b style={{ marginRight: "10px" }}>Image :</b>
                        </td>
                        <td>
                          <img
                            className="file-image-b"
                            src={dAdd.file_url}
                            alt=""
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <b style={{ marginRight: "10px" }}> Name:</b>
                      </td>
                      <td>
                        <Input
                          className="bx-fld-10t grey-input"
                          placeholder="Name"
                          value={dAdd.block_name}
                          onChange={(e) =>
                            setdAdd({
                              ...dAdd,
                              block_name: e.target.value.trim(),
                            })
                          }
                          style={{ width: 300 }}
                        />
                      </td>
                    </tr>
                    {dAdd.block_type === "writing_style" ? (
                      <tr>
                        <td>
                          <b style={{ marginRight: "10px" }}> Text:</b>
                        </td>
                        <td>
                          <Input.TextArea
                            style={{ width: 300 }}
                            className="bx-fld-10t grey-input"
                            placeholder="Block Text"
                            value={dAdd.block_text}
                            onChange={(e) =>
                              setdAdd({ ...dAdd, block_text: e.target.value })
                            }
                            autoSize={{ minRows: 2, maxRows: 3 }}
                          />
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                <div className="flex-end">
                  <Button
                    onClick={() => {
                      SaveBlock(dAdd);
                      setdAdd();
                    }}
                    className={`editor-tag-tab active-tg-wdt`}
                    style={{ borderRadius: "3px" }}
                  >
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <div
                className="vid-ad-vx tag-grou-lake-c"
                style={{
                  gridColumn: "2 / span 1",
                  marginBottom: "1px",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {user && user.id ? (
                    <Dragger
                      showUploadList={false}
                      maxCount={1}
                      beforeUpload={handleBeforeUpload}
                      {...props}
                    >
                      {renderFileName()}
                    </Dragger>
                  ) : (
                    <div
                      onClick={() => {
                        // setFormType("signup");
                      }}
                      className="no-login-up"
                    >
                      Drag or click to upload
                    </div>
                  )}
                  {/* {videoErr && type === "video" ? (
                <h6>Video is longer than 3 minutes</h6>
              ) : null} */}
                </div>
                <div className="up-bx-mnbcs">
                  {UploadedFile.length ? (
                    <Button size="small" type="default" onClick={removeFile}>
                      Change
                    </Button>
                  ) : null}
                  {UploadedFile.length ? (
                    loading ? (
                      <Spin />
                    ) : (
                      <Button
                        style={{ marginLeft: "10px" }}
                        className={`editor-tag-tab  active-tg-wdt`}
                        type="primary"
                        size="small"
                        onClick={() => {
                          for (let i = 0; i < UploadedFile.length; i++) {
                            dAdd.block_type === "voice"
                              ? saveAudio(UploadedFile[i], i)
                              : saveFile(UploadedFile[i], i);
                          }
                          setLoading(true);
                        }}
                      >
                        Attach
                      </Button>
                    )
                  ) : null}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </Modal>
    </>
  );
}
