import { Button, Input, Modal, Table } from "antd";
import React, { useState } from "react";
import axios from "axios";
import Papa from "papaparse";
import loader from "../../../../../images/nlogo-red.png";

export default function OpenCsvBlocks({ row }) {
  const [show, setShow] = useState(false);
  const [csvData, setCsvData] = useState();
  const [loading, setLoadeing] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(row.file_url);
      const parsedData = Papa.parse(response.data, { header: true }).data;
      setCsvData(parsedData);
      setLoadeing(false);
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      setLoadeing(false);
    }
  };

  const renderColumns = () => {
    if (csvData.length === 0) return [];

    const firstRowKeys = Object.keys(csvData[0]);
    return firstRowKeys.map((key) => ({
      title: key,
      dataIndex: key,
      key: key,
    }));
  };

  return (
    <>
      <Button
        style={{ marginLeft: "10px" }}
        className={`editor-tag-tab active-tg-wdt`}
        size="small"
        onClick={() => {
          fetchData();
          setShow(true);
        }}
      >
        Open
      </Button>
      <Modal
        open={show}
        onCancel={() => setShow(false)}
        width={1000}
        className="no-buttons-modal black-modal top-20-modal"
        footer={null}
      >
        <div>
          <h2>{row.value}</h2>
          {/* Render the fetched CSV data inside the modal */}
          {loading ? (
            <Table
              dataSource={[]}
              columns={[]}
              pagination={false}
              sticky
           
              className="black-table "
            />
          ) : (
            <Table
              dataSource={csvData}
              columns={renderColumns()}
              pagination={false}
              sticky
              scroll={{ y: "80vh" }}
              className="black-table csv-adjusted-table "
            />
          )}
          {loading ? (
            <img style={{marginTop:"-150px"}} className="image-table-loader" src={loader} alt="" />
          ) : null}
        </div>
      </Modal>
    </>
  );
}
