import { useInView } from "framer-motion";
import React, { useRef } from "react";

export default function TextSection({ text }) {
  return (
    <div className="grey-clr-bx-text">
      <div className="container">
        <h5>Our vision</h5>
        <div className="container">
          <p>{text}</p>
        </div>
        <div></div>
      </div>{" "}
    </div>
  );
}
