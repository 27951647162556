import { Button, Col, Input, Row } from "antd";
import React, { useState, useContext } from "react";
import SearchContext from "../../Context/SearchContext";

export default function EmailVerification({ hide }) {
  const { organization, user, setUser, setOrganization } =
    useContext(SearchContext);
    const [email, setEmail] = useState(organization.root_email || ""); // use organization.root_email as the initial value
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };
  const logout = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };

  const handleVerification = async () => {
    try {
      const requestVerification = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({ email:organization.root_email, code: verificationCode }),
      };

      const response = await fetch(
        "https://embed.axv.ai/api/verification/confirm",
        requestVerification
      );

      if (response.ok) {
        // Verification successful
        setVerificationSuccess(true);
      } else {
        // Handle verification error
        console.error("Verification failed");
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {hide ? null : <h4 style={{ fontFamily: "inter" }}>Email Verification</h4>}
      
      {!verificationSuccess ? (
        <>
        <div>
          <p style={{ fontFamily: "inter", color: "white", marginBottom: "10px" }}>
    We've sent you a verification code to help verify your email.
  </p>
  </div>

          <Input
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            style={{ width: "50%", marginBottom: "10px" }} 
          />
        <div>
        <Button onClick={handleVerification} style={{ fontFamily: "inter", marginRight: "10px" }}>
          Verify Email
        </Button>
        <Button onClick={logout} type="primary">
          Sign Out
        </Button>
      </div>
        </>
      ) : (
        <>
          <p style={{ fontFamily: "inter", color: "green" }}>
            Email verification successful! You can now continue.
          </p>
          {!hide && (
            <div style={{ marginTop: "20px" }} className="up-bx-mnbcs">

<Button
            onClick={() => {
              logout();
            }}
            type="primary"
          >
            Sign Out
          </Button>

              <Button
                onClick={() => {
                  updateOrganization("onboard", "pending");
                  setOrganization({ ...organization, onboard: "pending" });
                }}
                style={{ fontFamily: "inter" }}
              >
                Continue
              </Button>

             
            </div>

            
          )}
        </>
      )}
    </div>
  );
}
