import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo-s.png";
import CreateWc from "./create/CreateWc";
import SearchContext from "../../../Context/SearchContext";

export default function WcHeader() {
  const { wc, setWC } = useContext(SearchContext);

  return (
    <div className="header-2-wc">
      {" "}
      <Link to="/">
        <a className="navbar-brand" href="#">
          <img className="logo-img-bc" src={logo} alt="" />
        </a>
      </Link>
      <CreateWc />
    </div>
  );
}
