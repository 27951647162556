import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/SearchContext";
import { Button, Col, Modal, Row, Spin, Tag, Tooltip, message } from "antd";
import Header from "../../components/Header";
import FullLoader from "../storyProject/components/FullLoader";
import { LoadingOutlined } from "@ant-design/icons";
import { FaArrowDown } from "react-icons/fa";
import { GiExpand } from "react-icons/gi";
import { TbArrowRight } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

export default function Sounds() {
  const { setTasks, user } = useContext(SearchContext);
  const [category, setCategory] = useState();
  const [catData, setCatData] = useState();
  const [callApi, setCallApi] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [images, setImages] = useState({});
  const [show, setShow] = useState();

  const navigate = useNavigate();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  useEffect(() => {
    getImages(window.location.pathname.slice(7));
  }, [callApi]);

  const getImages = (cat) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image/library?page=${
        images.imgPNO ? images.imgPNO : 1
      }&limit=24&sort_by=${-1}&media_type=sound&count=${[
        cat,
      ]}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setLoading2(false);
        if (data && data.isSuccess) {
          let all = images.cImages
            ? images.cImages.concat(data.imageList)
            : data.imageList;
          setImages({
            ...images,
            cImages: all,
            imgPNO: images.imgPNO ? images.imgPNO : 1,
            totalPimg: data.totalPages,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  const topRight = (m) => {
    return (
      <div style={{ zIndex: 10 }} className={`top-right-popup `}>
        <b onClick={() => setShow(m)} className="show-hover">
          <GiExpand
            style={{
              position: "relative",
            }}
            className="attach-popup-c"
          />
        </b>

        <b
          onClick={() => {
            navigate(
              `/sound/${window.location.pathname.slice(
                7
              )}/${m.query?.replaceAll(" ", "-")}`
            );
          }}
          style={{ marginLeft: "5px" }}
          className="show-hover hover"
        >
          <TbArrowRight />
        </b>
      </div>
    );
  };
  return loading ? (
    <FullLoader />
  ) : (
    <div className="images-main-p ">
      <Header />
      <div className="container">
        <h6 className="category-text-d">{window.location.pathname.slice(7)}</h6>
        <Row>
          {images.cImages &&
            images.cImages.map((m, mi) => {
              return (
                <Col lg={{ span: 4 }} md={{ span: 6 }} key={mi}>
                  <div
                    className="display-goal-st-s1"
                    style={{ color: "white", width: "100%" }}
                  >
                    <div className="video-thumbnail-imgg">
                      <img src={m.root_image} alt="" />
                      {topRight(m)}
                      <div className="cat-hover-det">
                        <p>{m.query}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
        {images.totalPimg && images.totalPimg > images.imgPNO ? (
          <div className="load-more-btn">
            {loading2 ? (
              <Spin indicator={antIcon} />
            ) : (
              <Tag
                onClick={() => {
                  setImages({
                    ...images,
                    imgPNO: images.imgPNO + 1,
                  });
                  setLoading2(true);
                  setCallApi(callApi + 1);
                }}
                className={`editor-tag-tab  active-tg-wdt`}
              >
                Load More <FaArrowDown />
              </Tag>
            )}
          </div>
        ) : null}
        {catData && catData.length === 0 ? (
          <div className="no-heigh-div-c editor-container">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Assets Available</p>
                <p className="txt-card-21">
                  Leverage generative AI to reimagine your videos with Olm
                  Studio. Add objects, characters into moments that didn't exist
                  before.
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        open={show !== undefined}
        onCancel={() => setShow()}
        width={600}
        className="no-buttons-modal black-modal"
      >
        {show ? (
          <div className="download-show-img">
            <div className="card-bx-con-fl">
              <ReactAudioPlayer src={show.file_url} autoPlay controls />
            </div>

            <div className="flex-end">
              <a style={{ marginTop: "10px" }} href={show.file_url} download>
                <Button className={`editor-tag-tab  active-tg-wdt`}>
                  Download
                </Button>
              </a>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
