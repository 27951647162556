import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../Context/SearchContext";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BsPersonWorkspace } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { PiGarageBold } from "react-icons/pi";
import { MdDelete } from "react-icons/md";

export default function AddWorkspace() {
  const { user, wc, setWC } = useContext(SearchContext);
  const [show, setShow] = useState();
  const [loading, setLoading] = useState();
  const [list, setList] = useState();
  const [loadeing, setLoadeing] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [loadV, setLoadV] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const navigate = useNavigate();
  const [display, setDisplay] = useState("add");

  useEffect(() => {
    if (display === "workspaces") {
      getSpaces();
    }
    if (window.location.pathname.includes("/space/")) {
      setDisplay("workspaces")
      getSpaces()

    }
  }, [pageNo]);

  const addWorkspace = () => {
    const obj = {
      ...show,
      section: "workspace",
      user_id: user && user.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/list`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          message.success("added");
        } else {
          message.error("something went wrong");
        }
      });
  };
  const getSpaces = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list?page=${pageNo}&limit=10&user_id=${
        user.user_id
      }&sort_by=${-1}&section=workspace`,
      //   date=${date.start}-${date.end}&
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setList(data.listList);
          setTotalPages(data.totalPages);
        }
      });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "value",
      key: "value",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => moment(text).fromNow(),
    },
    {
      title: "",
      dataIndex: "del",
      key: "del",
      render: (text, row) => (
        <b>
          <Popconfirm
            title="Are You Sure to Want to Delete This"
            onConfirm={() => {
              deleteWorkspace(row);
              setList(list.filter((f) => f._id !== row._id));
            }}
          >
            <MdDelete className="delete-icon" />
          </Popconfirm>
        </b>
      ),
    },
    {
      title: "",
      dataIndex: "del",
      key: "del",
      render: (text, row) => (
        <b>
          {window.location.pathname.slice(7) === row._id.toString() ? (
            <TiTick style={{fontSize:"20px"}} color="#008300" />
          ) : (
            <FaArrowRight
              onClick={() => {
                navigate(`/space/${row._id}`);
                setWC({
                  active: row,
                  callGenerate: wc.callGenerate ? wc.callGenerate + 1 : 1,
                });
              }}
              className="hover"
            />
          )}
        </b>
      ),
    },
  ];

  const deleteWorkspace = (data) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    // Make a DELETE request to your API here
    fetch(`https://embed.axv.ai/api/list/${data._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          // getlistConnections();

          message.success("Data deleted successfully");
        } else {
          message.error("Failed to delete data");
        }
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        message.error("An error occurred while deleting data");
      });
  };
  return (
    <div>
      <Button
        style={{ marginRight: "10px" }}
        size="small"
        className={`editor-tag-tab active-tg-wdt ${window.location.pathname.includes("/space/") ? " theme-btn-c" : " theme-btn-c2 "} `}
        onClick={() => setShow({ value: "" })}
      >
        {window.location.pathname.includes("/space/") ? null : (
          <BsPersonWorkspace />
        )}
        {window.location.pathname.includes("/space/") && wc.active
          ? wc.active.value?.slice(0, 8)
          : "Workspaces"}
        {window.location.pathname.includes("/space/") ? (
          <FaArrowRight className="arrow-right-c" />
        ) : null}
      </Button>
      <Modal
        open={show !== undefined}
        onCancel={() => {
          setShow();
          
        }}
        className="no-buttons-modal black-modal"
        width={600}
      >
        {show ? (
          <div>
            <div
              style={{
                paddingBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
              className="bx-main-sel-prf"
            >
              <Tag
                onClick={() => setDisplay("add")}
                className={`editor-tag-tab ${
                  display === "add" ? "  active-tg-wdt" : ""
                }`}
              >
                <span>Add Workspace {display === "add" ? <TiTick /> : ""}</span>
              </Tag>
              <Tag
                onClick={() => {
                  setDisplay("workspaces");
                  getSpaces();
                }}
                className={`editor-tag-tab ${
                  display === "workspaces" ? "  active-tg-wdt" : ""
                }`}
              >
                <span>
                  My Workspaces {display === "workspaces" ? <TiTick /> : ""}
                </span>
              </Tag>
            </div>
            {display === "add" ? (
              <div>
                <div className="name-bx-space">
                  <b>Name: </b>{" "}
                  <Input
                    placeholder="Space Name"
                    value={show.value}
                    onChange={(e) =>
                      setShow({ ...show, value: e.target.value })
                    }
                    className="grey-input"
                    style={{width:"300px"}}
                  />
                </div>
                {show.value.length ? (
                  <div className="flex-end">
                    {loading ? (
                      <Spin />
                    ) : (
                      <Button
                        className={`editor-tag-tab active-tg-wdt`}
                        onClick={() => {
                          addWorkspace();
                          setLoading(true);
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                ) : null}{" "}
              </div>
            ) : null}
            {display === "workspaces" ? (
              <div>
                {list === undefined ? (
                  <Spin />
                ) : (
                  <Table
                    className="brand-table black-table pagination-white"
                    pagination={{
                      pageSize: 15,
                      current: pageNo,
                      total: totalPages ? totalPages * 10 : 1,
                      onChange: (v) => {
                        setPageNo(v);
                      },
                      size: "small",
                      showSizeChanger: false,
                    }}
                    columns={columns}
                    dataSource={list}
                  />
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
