import {
  Badge,
  Button,
  Input,
  Popover,
  Spin,
  Tag,
  Tooltip,
  message,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { BiInfoCircle, BiSolidImage, BiTask } from "react-icons/bi";
import {
  BsBox,
  BsDatabaseAdd,
  BsFillCameraVideoFill,
  BsInfo,
  BsQuestion,
  BsSoundwave,
} from "react-icons/bs";
import {
  FaDotCircle,
  FaMagic,
  FaMusic,
  FaQuestion,
  FaQuestionCircle,
  FaSearch,
  FaTasks,
} from "react-icons/fa";
import { MdClose, MdKeyboardVoice } from "react-icons/md";
import { TbBrain, TbDatabase } from "react-icons/tb";
import { LuHistory } from "react-icons/lu";
import { BsStack } from "react-icons/bs";

import SearchContext from "../../../../../Context/SearchContext";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import AddBlock from "../../left/AddBlock";
import { useNavigate } from "react-router-dom";
import ListConnect from "./ListConnect";
import { PiGearSixBold } from "react-icons/pi";
import { ImDroplet } from "react-icons/im";
export default function HeaderCreate() {
  const {
    listData,
    setListData,
    user,
    setTasks,
    setImages,
    images,
    ref1,
    ref2,
    ref3,
    ref7,
    behavior,
    tasks,
    organization,
    setShowPrice,
    setWC,
    wc,
  } = useContext(SearchContext);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [search4, setSearch4] = useState("");
  const [callApi, setCallApi] = useState(1);
  const [callApi2, setCallApi2] = useState(1);
  const [loading2, setLoading2] = useState(true);
  const [loadingS, setLoadingS] = useState(false);
  const [voice, setVoice] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      search1.length === 0 &&
      tasks.activeIcon !== "block" &&
      tasks.activeIcon !== "database" &&
      organization?.onboard !== "tour" &&
      callApi > 1
    ) {
      getImages();
    }
  }, [callApi]);
  useEffect(() => {
    if (tasks.activeIcon === "task") {
      setSearch1("");
    }
  }, [tasks.changeHome]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,

        color: "white",
      }}
      spin
    />
  );
  const getVideos = (v) => {
    const obj = {
      query: v,
      user_id: user && user.user_id,
      workspace: window.location.pathname.includes("/space/")
        ? window.location.pathname.slice(7)
        : undefined,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search/user`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (window.location.pathname.includes("/space/")) {
            setWC({
              ...wc,
              data: data,
              totalPages: undefined,
            });
          } else {
            setImages({
              ...images,
              data: data,
              totalPages: undefined,
            });
          }
        }
      });
  };
  const getListData = (v) => {
    const obj = {
      query: v,
      user_id: user && user.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/list/connect/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setListData({
            ...listData,
            data: data,
            totalPages: undefined,
          });
        }
      });
  };

  const adjustStates = () => {
    setImages({
      ...images,
      pageNo: 1,
      data: undefined,
    });
    setLoadingS(false);
    setSearch("");
    setSearch1("");
    setCallApi(callApi + 1);
  };

  const handleSendClick = async (srch, isThunder) => {
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/video",
        {
          query: srch ? srch.query : search,
          user_id: user && user.user_id,
          stream_id: srch && srch._id,
          stream: srch ? "init" : undefined,
          duration: 1,
          voice,
          username: user && user.username,
          profile_picture: user && user.profile_picture,
          video_id: images.videoId?._id,
          image_id: images.imageId?._id,
          music_id: images.musicId?._id,
          sound_id: images.soundId?._id,
          voice_id: images.voiceId?._id,
        }
      );

      adjustStates();
    } catch (error) {
      // Handle any errors here
      if (error?.response?.status === 400) {
        setShowPrice(true);
      }
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        // setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick3 = async (srch) => {
    let obj = {
      query: search,
      user_id: user && user.user_id,
      duration: 6,
      video_id: images.videoId?._id,
      image_id: images.imageId?._id,
      music_id: images.musicId?._id,
      sound_id: images.soundId?._id,
      voice_id: images.voiceId?._id,
    };
    setSearch("");
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/sound",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }
      adjustStates();

      // Display the alert
    } catch (error) {
      if (error?.response?.status === 400) {
        setShowPrice(true);
      }
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        // setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  const handleSendClick5 = async (srch) => {
    let obj = {
      query: search,
      user_id: user && user.user_id,
      duration: 1,
      video_id: images.videoId?._id,
      image_id: images.imageId?._id,
      music_id: images.musicId?._id,
      sound_id: images.soundId?._id,
      voice_id: images.voiceId?._id,
    };

    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/music",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }
      adjustStates();
      // Display the alert
    } catch (error) {
      if (error?.response?.status === 400) {
        setShowPrice(true);
      }
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        // setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick4 = async (srch) => {
    let obj = {
      query: search,
      user_id: user && user.user_id,
      video_id: images.videoId?._id,
      image_id: images.imageId?._id,
      music_id: images.musicId?._id,
      sound_id: images.soundId?._id,
      voice_id: images.voiceId?._id,
    };

    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/voice",
        obj
      );
      adjustStates();
    } catch (error) {
      if (error?.response?.status === 400) {
        setShowPrice(true);
      }
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        // setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick2 = async (srch) => {
    let obj = {
      query: search,
      user_id: user && user.user_id,
      source_image: images.brainId?._id,

      video_id: images.videoId?._id,
      image_id: images.imageId?._id,
      music_id: images.musicId?._id,
      sound_id: images.soundId?._id,
      voice_id: images.voiceId?._id,
    };
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/image",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }
      adjustStates();

      // Display the alert
    } catch (error) {
      if (error?.response?.status === 400) {
        setShowPrice(true);
      }
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        // setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  const getImages = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        images.pageNo
      }&limit=20&sort_by=${-1}&user_id=${user ? user.user_id : "map"}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          let all =
            images.pageNo === 1
              ? data.imageList
              : images.data.concat(data.imageList);
          setImages({
            ...images,
            // data: data.imageList,
            data: all,
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };

  const getBlocksGenerated = async (v) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/block/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&block_type=&search=${v}`
      );
      setLoading2(false);
      // Update the state with the retrieved jobs data
      setImages({
        ...images,
        // data: data.imageList,
        blocks: response.data?.blockList,
        totalPBlock: response.data?.totalPages,
      });

      // setTimeout(() => {
      //   setCallApi(callApi + 1);
      // }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  //NO USER HOME PAGE
  if (!user || !user.user_id) {
    // Render Sign Up and Sign In buttons if user_id is not present
    return (
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      ></div>
    );
  }
  const getListDataSearch = (v) => {
    const obj = {
      query: v,
      user_id: user && user.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/list/connect/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setListData({
            ...listData,
            data2: data,
            totalPages2: undefined,
          });
        }
      });
  };
  const createTask = () => {
    const obj = {
      query: search,
      user_id: user && user.user_id,
      // video_id: images.videoId?._id,
      // image_id: images.imageId?._id,
      // music_id: images.musicId?._id,
      // sound_id: images.soundId?._id,
      // voice_id: images.voiceId?._id,
      attachment: images.attachment,
      workspace: window.location.pathname.includes("/space/")
        ? window.location.pathname.slice(7)
        : undefined,
      huminoid_id: behavior.selected?._id ? behavior.selected?._id : "auto",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/image/fx`, requestOptions)
      .then((response) => {
        if (response.status === 429) {
          setSearch("");
          setLoadingS(false);
          setShowPrice(true);

          throw new Error("Too Many Requests (429)");
        }
        return response.json();
      })
      .then((data) => {
        setSearch("");
        setLoadingS(false);
        if (data.data) {
          setCallApi(callApi + 1);
          success();
        } else {
          console.log("API call succeeded, but data is missing");
          message.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        if (error.message !== "Too Many Requests (429)") {
          message.error("Something went wrong. Please try again later.");
        }
      });
  };
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Processing",

      duration: 0.8,
    });
  };
  const handleClearAttachments = () => {
    // Clear all attachments
    setImages({
      ...images,
      attachment: undefined,
      videoId: undefined,
      imageId: undefined,
      musicId: undefined,
      soundId: undefined,
      voiceId: undefined,
    });
  };
  const attachments = () => {
    let showImg = images.videoId
      ? images.videoId
      : images.imageId
      ? images.imageId
      : images.musicId
      ? images.musicId
      : images.soundId
      ? images.soundId
      : images.voiceId;

    let t = images.attachment?.length;
    // t = images.videoId ? t + 1 : t;
    // t = images.imageId ? t + 1 : t;
    // t = images.musicId ? t + 1 : t;
    // t = images.soundId ? t + 1 : t;
    // t = images.voiceId ? t + 1 : t;

    return t > 0 ? (
      <div className="search-bx-c-cine">
        <div className="img-active-brain">
          <Badge color="#BC1823" count={images.attachment?.length}>
            {showImg?.media_type === "image" ? (
              <img src={showImg?.file_url} alt="" />
            ) : (
              <img src={showImg?.root_image} alt="" />
            )}
          </Badge>
          <span onClick={handleClearAttachments} className="clear-icon">
            {/* Assuming you have an X icon component or an SVG for the X icon */}
            <MdClose />
          </span>
        </div>
      </div>
    ) : null;
  };

  const handleSearch = () => {
    if (!loadingS && search.trim().length) {
      createTask(search);
      setLoadingS(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <div className="header-create-c">
      {contextHolder}
      <div className="start-icons-bx less-width-bx">
        <Tooltip title="Imagine">
          <div
            className={`icon-left-ed ${
              tasks.activeIcon === "task" || tasks.activeIcon === "search"  ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              if (tasks.stopGet) {
                setImages({
                  ...images,
                  pageNo: 1,
                  data: undefined,
                });
                setTasks({
                  ...tasks,
                  stopGet: undefined,
                  callGenerate: tasks.callGenerate ? tasks.callGenerate + 1 : 1,
                  activeIcon: "task",
                });
              } else {
                setTasks({ ...tasks, activeIcon: "task" });
              }
              setSearch("");
              setSearch1("");
            }}
            ref={ref1}
          >
            <FaDotCircle />
          </div>
        </Tooltip>
   
        {/* <Tooltip title="Blocks">
          <div
            className={`icon-left-ed ${
              tasks.activeIcon === "block" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              setTasks({ ...tasks, activeIcon: "block" });
              navigate("/");
            }}
            ref={ref9}
          >
            <BsBox />
          </div>
        </Tooltip> */}

        {window.location.pathname.includes("/space/") ? null : (
          <Tooltip title="Elements">
            <div
              className={`icon-left-ed ${
                tasks.activeIcon === "axons" ||
                tasks.activeIcon === "block" ||
                tasks.activeIcon === "chat_log" ||
                tasks.activeIcon === "dataset"
                  ? " active-i-l-ed"
                  : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeIcon: "block" });
              }}
            >
              <BsStack />
              {/* <b>Create</b> */}
            </div>
          </Tooltip>
        )}
        {/* {window.location.pathname.includes("/space/") ? null : (
          <Tooltip title="Data Hub">
            <div
              className={`icon-left-ed ${
                tasks.activeIcon === "dataset" ? " active-i-l-ed" : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeIcon: "dataset" });
              }}
            >
              <TbDatabase />
            </div>
          </Tooltip>
        )} */}
        {/* <Tooltip title="BiTask">
          <div
            className={`icon-left-ed ${
              tasks.activeIcon === "BiTask" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              setTasks({ ...tasks, activeIcon: "BiTask" });
            }}
          >
            <BiTask />
          </div>
        </Tooltip> */}
        {/* <Tooltip title="Settings">
          <div
            className={`icon-left-ed ${
              tasks.activeIcon === "settings" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              setTasks({ ...tasks, activeIcon: "settings" });
            }}
          >
            <PiGearSixBold />
       
          </div>
        </Tooltip> */}

        {/*  <Tooltip title="Library">
          <div
            className={`icon-left-ed ${
              tasks.activeIcon === "database" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              setSearch("");
              setTasks({ ...tasks, activeIcon: "database" });
              setImages({
                ...images,
                pageNo2: 1,
                data2: undefined,
              });
            }}
          >
            <BsDatabaseAdd />
            
          </div>
        </Tooltip> */}
      </div>
      {tasks.activeIcon === "block" ? (
        <div className="block-s-c">
          <input
            style={{
              width: 300,
            }}
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setImages({
                ...images,
                pageNoB: 1,
                blocks: undefined,
              });
              setSearch2(e.target.value);
              setLoading2(true);
              getBlocksGenerated(e.target.value);
            }}
            value={search2}
            required=""
            className="form-control border-0 shadow-sm px-4 grey-input main-search-cine"
          />
        </div>
      ) : null}
      {tasks.activeIcon === "axons" ? (
        <div>
          <input
            style={{
              width: 300,
            }}
            type="text"
            placeholder="Search Models"
            onChange={(e) => {
              setSearch3(e.target.value);
              if (e.target.value.length) {
                getListData(e.target.value);
              } else {
                setListData({
                  ...listData,
                  pageNo: 1,
                  data: undefined,
                  callApi: listData.callApi ? listData.callApi + 1 : 1,
                });
              }
            }}
            value={search3}
            required=""
            className="form-control border-0 shadow-sm px-4 grey-input main-search-cine"
          />
        </div>
      ) : null}
      {tasks.activeIcon === "search" || tasks.activeIcon === "task" ? (
        <div className="h-create-s-c">
          <Tooltip title="Search">
            <div
              className={`icon-left-ed ${
                tasks.activeIcon === "search" ? " active-i-l-ed" : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeIcon: "search" });
              }}
              style={{width:"34px"}}
            >
              <FaSearch />
              {/* <b>Search</b> */}
            </div>
          </Tooltip>
          <Tooltip title="task">
            <div
              className={`icon-left-ed ${
                tasks.activeIcon === "task" ? " active-i-l-ed" : ""
              }`}
              style={{width:"34px"}}

              onClick={() => {
                if (tasks.stopGet) {
                  setImages({
                    ...images,
                    pageNo: 1,
                    data: undefined,
                  });
                  setTasks({
                    ...tasks,
                    stopGet: undefined,
                    callGenerate: tasks.callGenerate
                      ? tasks.callGenerate + 1
                      : 1,
                    activeIcon: "task",
                  });
                } else {
                  setTasks({ ...tasks, activeIcon: "task" });
                }
                setSearch("");
                setSearch1("");
              }}
            >
              <FaMagic />
              {/* <b>Search</b> */}
            </div>
          </Tooltip>
          {tasks.activeIcon === "search" ? (
            <input
              style={{
                width: 300,
              }}
              type="text"
              placeholder={
                window.location.pathname.includes("/space/")
                  ? "Search Space"
                  : "Search"
              }
              onChange={(e) => {
                setSearch1(e.target.value);
                if (e.target.value.length) {
                  getVideos(e.target.value);
                }
                if (e.target.value.length) {
                  setTasks({ ...tasks, stopGet: true });
                } else {
                  setImages({
                    ...images,
                    pageNo: 1,
                    data: undefined,
                  });
                  setTasks({
                    ...tasks,
                    stopGet: undefined,
                    callGenerate: tasks.callGenerate
                      ? tasks.callGenerate + 1
                      : 1,
                  });
                }
              }}
              value={search1}
              required=""
              className="form-control border-0 shadow-sm px-4 grey-input main-search-cine"
            />
          ) : null}
          {tasks.activeIcon === "task" ? (
            <div style={{ display: "flex" }} className="create-input-fvc">
              <div
                ref={organization?.onboardStep === 7 ? ref7 : ref2}
                className={`create-input-fvc2  ${
                  search.length > 44 ? " popup-input" : ""
                }`}
              >
                {attachments()}

                <Input.TextArea
                  autoSize={{ maxRows: 4, minRows: 1 }}
                  style={{
                    width:
                      search.length < 27
                        ? 300
                        : search.length > 35
                        ? 420
                        : search.length > 27
                        ? 350
                        : 300,
                  }}
                  type="text"
                  placeholder="Generate"
                  onChange={(e) => {
                    if (e.target.value.length < 1000) {
                      setSearch(e.target.value);
                    }
                  }}
                  value={
                    organization?.onboardStep === 2 ||
                    organization?.onboardStep === 3
                      ? "Create video"
                      : organization?.onboardStep === 7
                      ? "create sound and image "
                      : search
                  }
                  required=""
                  className={`form-control border-0  px-4 grey-input main-search-cine`}
                  onKeyPress={handleKeyPress}
                />
                {search.trim().length ||
                organization?.onboardStep === 2 ||
                organization?.onboardStep === 7 ||
                organization?.onboardStep === 3 ? (
                  loadingS ? (
                    <div className="loader-c-sd">
                      <Spin
                        style={{
                          fontSize: 20,
                          marginLeft: "10px",
                        }}
                        size="small"
                        indicator={antIcon}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (!loadingS) {
                          createTask(search);
                          setLoadingS(true);
                        }
                      }}
                      ref={ref3}
                      style={{ padding: "0px", width: "auto" }}
                      className={` start-card-bx `}
                    >
                      <div
                        style={{ padding: "8px" }}
                        className="icon-planing-c"
                      >
                        <TbBrain
                          style={{ marginRight: "0px", fontSize: "24px" }}
                        />
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {tasks.activeIcon === "dataset" ? (
        <div>
          <input
            style={{
              width: 300,
            }}
            type="text"
            placeholder={"Search"}
            onChange={(e) => {
              setSearch4(e.target.value);
              if (e.target.value.length) {
                getListDataSearch(e.target.value);
              } else {
                setListData({
                  ...listData,
                  pageNo2: 1,
                  data2: undefined,
                  callApi2: listData.callApi2 ? listData.callApi2 + 1 : 1,
                });
              }
            }}
            value={search4}
            required=""
            className="form-control border-0 shadow-sm px-4 grey-input main-search-cine"
          />
        </div>
      ) : null}
      {tasks.activeIcon === "create" ? (
        <div style={{ display: "flex" }} className="create-input-fvc">
          <div className="create-input-fvc2">
            {images.brainId ? (
              <div className="search-bx-c-cine">
                <div className="img-active-brain">
                  {images.brainId.media_type === "image" ? (
                    <img src={images.brainId.file_url} alt="" />
                  ) : (
                    <img src={images.brainId.root_image} alt="" />
                  )}
                  <div className="bpttpm-icn-wc">
                    <MdClose
                      onClick={() =>
                        setImages({
                          ...images,
                          brainId: undefined,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <input
              style={{
                width: 300,
              }}
              type="text"
              placeholder="Generate"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              required=""
              className="form-control border-0 shadow-sm px-4 grey-input main-search-cine"
            />
            {search.trim().length ? (
              loadingS ? (
                <div className="loader-c-sd">
                  <Spin
                    style={{
                      fontSize: 20,
                      marginLeft: "10px",
                    }}
                    size="small"
                    indicator={antIcon}
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    if (!loadingS) {
                      if (
                        tasks.activeT === "video" ||
                        tasks.activeT === undefined
                      ) {
                        handleSendClick();
                      } else if (tasks.activeT === "image") {
                        handleSendClick2();
                      } else if (tasks.activeT === "sound") {
                        handleSendClick3();
                      } else if (tasks.activeT === "music") {
                        handleSendClick5();
                      } else {
                        handleSendClick4();
                      }
                      setLoadingS(true);
                    }
                  }}
                  style={{ padding: "0px", width: "auto" }}
                  className={` start-card-bx `}
                >
                  <div style={{ padding: "8px" }} className="icon-planing-c">
                    <TbBrain style={{ marginRight: "0px", fontSize: "24px" }} />
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      ) : null}

      <div></div>
      {tasks.activeIcon === "video" ? (
        <div className="start-icons-bx less-width-bx bottom-icons-jh">
          <Tooltip title="Video">
            <div
              className={`icon-left-ed ${
                tasks.activeT === "video" ||
                (tasks.activeT === undefined && tasks.activeIcon !== "database")
                  ? " active-i-l-ed"
                  : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeT: "video" });
                setImages({
                  ...images,
                  pageNo2: 1,
                  data2: undefined,
                });
              }}
            >
              <BsFillCameraVideoFill />
              {/* <b>Video</b> */}
            </div>
          </Tooltip>
          <Tooltip title="Image">
            <div
              className={`icon-left-ed ${
                tasks.activeT === "image" ? " active-i-l-ed" : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeT: "image" });
                setImages({
                  ...images,
                  pageNo2: 1,
                  data2: undefined,
                });
              }}
            >
              <BiSolidImage />
              {/* <b>Image</b> */}
            </div>
          </Tooltip>
          <Tooltip title="Music">
            <div
              className={`icon-left-ed ${
                tasks.activeT === "music" ? " active-i-l-ed" : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeT: "music" });
                setImages({
                  ...images,
                  pageNo2: 1,
                  data2: undefined,
                });
              }}
            >
              <FaMusic />
              {/* <b>Music</b> */}
            </div>
          </Tooltip>
          <Tooltip title="Sound">
            <div
              ref={ref7}
              className={`icon-left-ed ${
                tasks.activeT === "sound" ? " active-i-l-ed" : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeT: "sound" });
                setImages({
                  ...images,
                  pageNo2: 1,
                  data2: undefined,
                });
              }}
            >
              <BsSoundwave />
              {/* <b>Sound</b> */}
            </div>
          </Tooltip>
          <Tooltip title="Voice">
            <div
              className={`icon-left-ed ${
                tasks.activeT === "voice" ? " active-i-l-ed" : ""
              }`}
              onClick={() => {
                setTasks({ ...tasks, activeT: "voice" });
                setImages({
                  ...images,
                  pageNo2: 1,
                  data2: undefined,
                });
              }}
            >
              <MdKeyboardVoice />
              {/* <b>Voice</b> */}
            </div>
          </Tooltip>
          {/* <AddBlock /> */}
        </div>
      ) : null}
    </div>
  );
}
