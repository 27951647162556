import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/SearchContext";
import { Col, Row, Tooltip, message } from "antd";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import Header from "../../components/Header";
import UpScale from "./components/contents/Tasks/UpScale";
import UpScaleImage from "./components/contents/Tasks/UpScaleImage";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsInfo } from "react-icons/bs";
import { TbArrowRight } from "react-icons/tb";
import DetailModal from "./components/contents/Tasks/DetailModal";
import RelatedAssets from "./components/contents/Tasks/RelatedAssets";
import HeaderCreateShare from "./components/contents/Tasks/HeaderCreateShare";
export default function SingleAsset() {
  const navigate = useNavigate();
  const { user, setImages, images, tasks, setTasks } =
    useContext(SearchContext);
  const [show, setShow] = useState();
  const [loading, setLoading] = useState();
  const [callApi, setCallApi] = useState(1);
  const [data, setData] = useState();
  const [mainD, setMainD] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (tasks.activeGoal === undefined) {
      getImage(window.location.pathname.slice(7));
    } else {
      setMainD(tasks.activeGoal)
      let m = tasks.activeGoal;
      setImages({
        ...images,
        imageId2: m.media_type === "image" ? m : undefined,
        videoId2: m.media_type === "original" ? m : undefined,
        soundId2: m.media_type === "sound" ? m : undefined,
        musicId2: m.media_type === "music" ? m : undefined,
        voiceId2: m.media_type === "voice" ? m : undefined,
      });
    }
  }, []);
  useEffect(() => {
    getImagesRelated(window.location.pathname.slice(7));
  }, [callApi]);

  const getImage = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setMainD(data.imageData)
          setTasks({
            ...tasks,
            // data: data.imageList,
            activeGoal: data.imageData,
          });
          let m = data.imageData;
          setImages({
            ...images,
            imageId2: m.media_type === "image" ? m : undefined,
            videoId2: m.media_type === "original" ? m : undefined,
            soundId2: m.media_type === "sound" ? m : undefined,
            musicId2: m.media_type === "music" ? m : undefined,
            voiceId2: m.media_type === "voice" ? m : undefined,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  const getImagesRelated = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?related_data=${id}&user_id=${user?.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          let all = [...data.imageList];

          setData(all);
          setTimeout(() => {
            setCallApi(callApi + 1);
          }, 8000);
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  const copyToClipboard = (m) => {
    const textToCopy = `${window.location.origin}/share/${m._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  const topRight = (m) => {
    return (
      <div className={`top-right-popup `}>
        {m.media_type === "original" ? <UpScale m={m} /> : null}
        {m.media_type === "image" ? <UpScaleImage m={m} /> : null}
        <Tooltip title="Info">
          <b
            style={{ margin: "0px 5px " }}
            onClick={() => copyToClipboard(m)}
            className="show-hover hover"
          >
            <AiOutlineShareAlt />
          </b>
        </Tooltip>
        <Tooltip title="Info">
          <b onClick={() => setShow(m)} className="show-hover hover">
            <BsInfo
              style={{
                position: "relative",
              }}
              className="attach-popup-c"
            />
          </b>
        </Tooltip>
      </div>
    );
  };
  return (
    <div style={{ padding: "0px" }} className="active-task-bxk">
      <Header windowWidth={windowWidth} showCreateShare={true} />
      {windowWidth && windowWidth < 850 ? (
        <div className="mobile-header-crt">
          <HeaderCreateShare />
        </div>
      ) : null}
      <DetailModal show={show} setShow={setShow} />
      <div className="content-img-bx">
        {tasks.activeGoal?.media_type === "image" ? (
          <div className="coonetnt-img-bx-m1">
            <div style={{ marginTop: "40px" }} className="blank-page-imhg-c">
              <div className="image-top-r">{topRight(tasks.activeGoal)}</div>
              <img
                className="content-img-m"
                src={tasks.activeGoal.file_url}
                alt=""
              />
            </div>
          </div>
        ) : null}
        {tasks.activeGoal?.media_type === "original" ? (
          <div style={{ marginTop: "20px" }} className="coonetnt-img-bx-m1">
            <div className="video-container video-container-round">
              <div style={{ marginTop: "24px" }} className="image-top-r">
                {" "}
                {topRight(tasks.activeGoal)}
              </div>
              <ReactPlayer playing url={tasks.activeGoal.file_url} controls />
            </div>
          </div>
        ) : null}
        {tasks.activeGoal?.media_type === "sound" ? (
          <div className="coonetnt-img-bx-m1">
            <div
              style={{
                height: "240px",
                position: "relative",
                marginTop: "30px",
              }}
              className="video-thumbnail-img img-2-sx audio-bx-blank"
            >
              <div className="voice-top-r"> {topRight(tasks.activeGoal)}</div>
              {tasks.activeGoal.root_image ? (
                <div>
                  <img src={tasks.activeGoal.root_image} alt="" />
                </div>
              ) : null}
              <div className="sound-blank-q-play">
                <h5>
                  {" "}
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      // expandable: true,
                      symbol: "see more",
                    }}
                    className="desc-p-crdjjkl"
                  >
                    {tasks.activeGoal.query}
                  </Paragraph>
                </h5>
                <ReactAudioPlayer src={tasks.activeGoal.file_url} controls />
              </div>
            </div>
          </div>
        ) : null}
        {tasks.activeGoal?.media_type === "voice" ? (
          <div className="coonetnt-img-bx-m1">
            <div
              style={{
                height: "240px",
                position: "relative",
                marginTop: "30px",
              }}
              className="video-thumbnail-img img-2-sx audio-bx-blank"
            >
              <div className="voice-top-r"> {topRight(tasks.activeGoal)}</div>
              {tasks.activeGoal.root_image ? (
                <div>
                  <img src={tasks.activeGoal.root_image} alt="" />
                </div>
              ) : null}
              <div className="sound-blank-q-play">
                <h5>
                  {" "}
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      // expandable: true,
                      symbol: "see more",
                    }}
                    className="desc-p-crdjjkl"
                  >
                    {tasks.activeGoal.query}
                  </Paragraph>
                </h5>
                <ReactAudioPlayer src={tasks.activeGoal.file_url} controls />
              </div>
            </div>
          </div>
        ) : null}
        {tasks.activeGoal?.media_type === "music" ? (
          <div className="coonetnt-img-bx-m1">
            <div
              style={{
                height: "240px",
                position: "relative",
                marginTop: "30px",
              }}
              className="video-thumbnail-img img-2-sx audio-bx-blank"
            >
              <div className="voice-top-r"> {topRight(tasks.activeGoal)}</div>
              {tasks.activeGoal.root_image ? (
                <div>
                  <img src={tasks.activeGoal.root_image} alt="" />
                </div>
              ) : null}
              <div className="sound-blank-q-play">
                <h5>
                  {" "}
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      // expandable: true,
                      symbol: "see more",
                    }}
                    className="desc-p-crdjjkl"
                  >
                    {tasks.activeGoal.query}
                  </Paragraph>
                </h5>
                <ReactAudioPlayer src={tasks.activeGoal.file_url} controls />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <RelatedAssets mainD={mainD} data={data} setData={setData} />
    </div>
  );
}
