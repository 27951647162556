import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import SearchState from "./Context/SearchState";
import Pages from "./pages/Pages";
import Onboard from "./components/onboard/Onboard";
import Pricing from "./components/Pricing";
import TourOnboard from "./components/TourOnboard";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  RedirectToSignIn,
} from "@clerk/clerk-react";
import SIgnupTest from "./pages/SIgnupTest/SIgnupTest";
import Footer from "./components/Footer";
import VideosUploading from "./components/VideosUploading";
// if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
//   throw new Error("Missing Publishable Key");
// }
// const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
function App() {
  // console.log(process.env.REACT_APP_CLERK_PUBLISHABLE_KEY)
  return (
    <ClerkProvider
      publishableKey={
        "pk_test_a2Vlbi13ZXJld29sZi04Ni5jbGVyay5hY2NvdW50cy5kZXYk"
      }
    >
      <SearchState>
        <Router>
          <TourOnboard />

          <Onboard />
          {/* <Pricing /> */}
          <VideosUploading />
          <div className="page-bx-main-app">
            <Pages />

            {/* <Footer /> */}
          </div>
        </Router>
      </SearchState>
    </ClerkProvider>
  );
}

export default App;
