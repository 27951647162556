import { Button, Tabs } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../../../../Context/SearchContext";
import Blocks from "./Blocks";
import ListConnect from "./ListConnect";
import ListDataSet from "./ListDataSet";
import ConnectBehavior from "./ConnectBehavior";
import ChatLog from "./ChatLog";

export default function MergTabs() {
  const { tasks } = useContext(SearchContext);

  return (
    <div className="">
      <ConnectBehavior />
      {tasks.activeIcon === "block" ? <Blocks /> : null}
      {tasks.activeIcon === "axons" ? <ListConnect /> : null}
      {tasks.activeIcon === "chat_log" ? <ChatLog /> : null}
    </div>
  );
}
