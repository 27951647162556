import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchContext from "../../Context/SearchContext";
import VideoArea from "./components/VideoArea";
import CreateImagine from "../../components/CreateImagine";
export default function Feed() {
  const { user, videosFeed, setVideosFeed } = useContext(SearchContext);
  const [search, setSearch] = useState("");

  // const userId = "64e920acf96bb2a83107e5f3";

  const getVideos = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/image/watch?user_id=${user && user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setVideosFeed([...videosFeed, ...data.imageList]);
        }
      });
  };

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <div>
      <div className="fixed-header">
        <Header showSearch={true} search={search} setSearch={setSearch} />
      </div>
      <div className="feed-page">
        <InfiniteScroll
          dataLength={videosFeed.length}
          next={getVideos}
          hasMore={search.trim().length ? false : true} // Always set to true to continuously load
          loader={<h4>Loading...</h4>}
        >
          {videosFeed.map((v, vi) => (
            <div key={vi} className="feed-view-area">
              <VideoArea videosFeed={videosFeed} setVideosFeed={setVideosFeed} video={v} />
            </div>
          ))}
        </InfiniteScroll>
      </div>
      <div>
        {search.length && videosFeed.length === 0 ? <CreateImagine /> : null}
      </div>
    </div>
  );
}
