import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import {
  Alert,
  Badge,
  Button,
  Input,
  Popconfirm,
  Skeleton,
  Spin,
  Tag,
  message,
} from "antd";
import { FaArrowDown, FaExpandAlt, FaPlay } from "react-icons/fa";
import ReactAudioPlayer from "react-audio-player";
import { LoadingOutlined } from "@ant-design/icons";

import temp from "../../../../images/56f4b8301c915c89bdfb278a9b9f6bbb.png";
import { GoDotFill } from "react-icons/go";
import { MdClose } from "react-icons/md";
import AddBlock from "./AddBlock";
import axios from "axios";

export default function Blocks() {
  const { editor, seteditor, user } = useContext(SearchContext);
  const [p, setP] = useState();
  const [callApi, setCallApi] = useState(1);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    if ((editor.iPageNo > 1 && callApi > 1) || editor.blocks === undefined) {
      getBlocksGenerated();
    }
  }, [callApi]);

  const getBlocksGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/block/?page=${
          editor.bPageNo ? editor.bPageNo : 1
        }&limit=20&sort_by=${-1}&user_id=${user && user.user_id}&block_type=${
          editor.blockType ? editor.blockType : ""
        }`
      );
      setLoading2(false);
      // Update the state with the retrieved jobs data
      let all = editor.blocks ? editor.blocks.concat(response.data?.blockList): response.data?.blockList;
      // Update the state with the retrieved jobs data
      seteditor({
        ...editor,
        blocks: all,
        totalPBlock: response.data?.totalPages,
      });

      // setTimeout(() => {
      //   setCallApi(callApi + 1);
      // }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const UpdateBlock = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/block/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          const all = editor.blocks.map((b) =>
            b._id === id ? response.blockData : b
          );
          seteditor({ ...editor, blocks: all });
        } else {
          // message.error("somehing went wrong");
        }
      });
  };
  const changeValue = (row, key, value) => {
    row[key] = value;
    const all = editor.blocks.map((b) => (b._id === row._id ? row : b));
    seteditor({ ...editor, blocks: all });
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/block/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = editor.blocks.filter((p) => p._id !== row._id);
          seteditor({ ...editor, blocks: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  return (
    <div style={{ paddingRight: "10px" }} className="videos-left-ed">
      {editor.blocks && editor.blocks.length === 0 ? (
        <div className="left-alert-bcs">
          <Alert
            message="Info Text"
            description="Info Description Info Description Info Description Info Description"
            type="info"
          />
        </div>
      ) : null}
      {editor.blocks &&
        editor.blocks.map((g, gi) => {
          return (
            <Badge.Ribbon
              color="#BC1823"
              text={
                g.block_type === "writing_style"
                  ? "Writing Style"
                  : g.block_type
              }
            >
              <div
                key={gi}
                style={{
                  width: "100%",
                  marginRight: "0px",

                  height: "auto",
                }}
                className="video-card-story"
              >
                {g.block_type === "product" ||
                g.block_type === "character" ||
                g.block_type === "logo" ||
                g.block_type === "backdrop" ? (
                  <div className="sound-thumbnail-img">
                    <img className="" src={g.file_url} alt="" />

                    <div className="sound-thmb-qa">
                      <div className="delete-icon-c">
                        <Popconfirm
                          title="Delete the task"
                          onConfirm={() => deleteTask(g)}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="right"
                        >
                          <b>
                            <MdClose className="hover" />
                          </b>
                        </Popconfirm>
                      </div>
                      <div className="name-tg-b">
                        <input
                          style={{
                            width: "100%",
                          }}
                          type="text"
                          placeholder="Block Name"
                          onChange={(e) =>
                            changeValue(g, "block_name", e.target.value.trim())
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_name: g.block_name,
                              },
                              g._id
                            )
                          }
                          value={g.block_name}
                          required=""
                          className="form-control border-0 shadow-sm px-4 "
                        />
                      </div>
                      <div className="bpttpm-icn-wc">
                        <GoDotFill
                          onClick={() => {
                            UpdateBlock(
                              {
                                status:
                                  g.status === "active" ? "pending" : "active",
                              },
                              g._id
                            );
                            changeValue(
                              g,
                              "status",
                              g.status === "active" ? "pending" : "active"
                            );
                          }}
                          style={{
                            fontSize: "20px",
                            color: g.status === "active" ? "green" : "red",
                          }}
                          className="hover"
                        />
                      </div>
                    </div>
                  </div>
                ) : g.block_type === "writing_style" ? (
                  <div className="sound-thumbnail-img">
                    <div className="sound-thmb-qa writing-style-input">
                      <div className="delete-icon-c">
                        <b onClick={() => deleteTask(g)}>
                          <MdClose className="hover" />
                        </b>
                      </div>
                      <div className="name-tg-b ">
                        <input
                          style={{
                            width: "100%",
                          }}
                          type="text"
                          placeholder="Block Name"
                          onChange={(e) =>
                            changeValue(g, "block_name", e.target.value.trim())
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_name: g.block_name,
                              },
                              g._id
                            )
                          }
                          value={g.block_name}
                          required=""
                          className="form-control border-0 shadow-sm px-4 "
                        />
                        <Input.TextArea
                          style={{ marginTop: "10px" }}
                          placeholder="Writing Text"
                          onChange={(e) =>
                            changeValue(g, "block_text", e.target.value)
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_text: g.block_text,
                              },
                              g._id
                            )
                          }
                          value={g.block_text}
                          autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                      </div>
                      <div className="bpttpm-icn-wc">
                        <GoDotFill
                          onClick={() => {
                            UpdateBlock(
                              {
                                status:
                                  g.status === "active" ? "pending" : "active",
                              },
                              g._id
                            );
                            changeValue(
                              g,
                              "status",
                              g.status === "active" ? "pending" : "active"
                            );
                          }}
                          style={{
                            fontSize: "20px",
                            color: g.status === "active" ? "green" : "red",
                          }}
                          className="hover"
                        />
                      </div>
                    </div>
                  </div>
                ) : g.block_type === "voice" ? (
                  <div className="sound-thumbnail-img">
                    {p === g._id ? null : (
                      <div className="play-icon-coive0">
                        <FaPlay onClick={() => setP(g._id)} />
                      </div>
                    )}
                    <div className="sound-thmb-qa">
                      <div className="delete-icon-c">
                        <Popconfirm
                          title="Delete the block"
                          onConfirm={() => deleteTask(g)}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          placement="right"
                        >
                          <b>
                            <MdClose className="hover" />
                          </b>
                        </Popconfirm>
                      </div>
                      <div className="name-tg-b">
                        <input
                          style={{
                            width: "100%",
                          }}
                          type="text"
                          placeholder="Block Name"
                          onChange={(e) =>
                            changeValue(g, "block_name", e.target.value.trim())
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_name: g.block_name,
                              },
                              g._id
                            )
                          }
                          value={g.block_name}
                          required=""
                          className="form-control border-0 shadow-sm px-4 "
                        />
                      </div>
                      {p === g._id ? (
                        <ReactAudioPlayer
                          style={{ marginTop: "10px" }}
                          src={g.file_url}
                          autoPlay
                          controls
                        />
                      ) : null}
                      <div className="bpttpm-icn-wc">
                        <GoDotFill
                          onClick={() => {
                            UpdateBlock(
                              {
                                status:
                                  g.status === "active" ? "pending" : "active",
                              },
                              g._id
                            );
                            changeValue(
                              g,
                              "status",
                              g.status === "active" ? "pending" : "active"
                            );
                          }}
                          style={{
                            fontSize: "20px",
                            color: g.status === "active" ? "green" : "red",
                          }}
                          className="hover"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Badge.Ribbon>
          );
        })}
      {editor.totalPBlock && editor.totalPBlock > editor.bPageNo ? (
        <div className="load-more-btn">
          {loading2 ? (
            <Spin indicator={antIcon} />
          ) : (
            <Tag
              onClick={() => {
                seteditor({
                  ...editor,
                  bPageNo: editor.bPageNo + 1,
                });
                setLoading2(true);
                setCallApi(callApi + 1);
              }}
              className={`editor-tag-tab  active-tg-wdt`}
            >
              Load More <FaArrowDown />
            </Tag>
          )}
        </div>
      ) : null}
    </div>
  );
}
