import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import CreateVideo from "./CreateVideo";

export default function CreateBox() {
  const { wc, setWC } = useContext(SearchContext);

  return (
    <div className="create-box-om">
      <CreateVideo />
    </div>
  );
}
