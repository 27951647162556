import {
  message,
  Tabs,
  Tooltip,
  Upload,
  Button,
  Typography,
  Tag,
  Switch,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import { FaQuestionCircle } from "react-icons/fa";
import SearchContext from "../../../../../Context/SearchContext";
const { Option } = Select;
export default function Settings() {
  const { organization, setOrganization, setShowPrice } =
    useContext(SearchContext);
  const [save, setSave] = useState();
  const [userName, setuserName] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [UploadedFile2, setUplaoded2] = useState([]);
  const [showApiKey, setShowApiKey] = useState(false);
  const [rp, setRp] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ]);

  useEffect(() => {
    if (organization === undefined) {
      getOrganization();
    }
  }, []);

  const getOrganization = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setOrganization(response.organization);
        }
      });
  };

  const copyApiKeyToClipboard = () => {
    // Copy the API key to the clipboard
    const textField = document.createElement("textarea");
    textField.innerText = organization.api_key;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const updateOrganization = (obj, id, g) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    let keys = Object.keys(obj);
    let values = Object.keys(obj).values;

    for (let i = 0; i < keys.length; i++) {
      formData.append(keys[i], obj[keys[i]]);
    }

    if (UploadedFile.length) {
      formData.append("image", UploadedFile[0].originFileObj);
    }
    if (UploadedFile2.length) {
      formData.append("fonts", UploadedFile2[0].originFileObj);
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setOrganization(response.organizationData);
        }
      });
  };
  //   const updateOrganization = () => {
  //     setSave(false);
  //     const user = JSON.parse(window.localStorage.getItem("user"));
  //     const formData = new FormData();
  //     formData.append("business_name", organization.business_name);
  //     formData.append("color_code", organization.color_code);
  //     if (UploadedFile.length) {
  //       formData.append("image", UploadedFile[0].originFileObj);
  //     }
  //     if (UploadedFile2.length) {
  //       formData.append("fonts", UploadedFile2[0].originFileObj);
  //     }
  //     const requestOptions = {
  //       method: "PUT",
  //       headers: {
  //         // "Content-Type": "application/json",
  //         "x-api-key": process.env.REACT_APP_API_KEY,
  //       },
  //       body: formData,
  //     };
  //     fetch(
  //       `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
  //       requestOptions
  //     )
  //       .then((response) => response.json())
  //       .then((response) => {
  //         setUplaoded([]);
  //         setUplaoded2([]);
  //         if (response && response.isSuccess) {
  //           setOrganization(response.organizationData);
  //         }
  //       });
  //   };

  const props = {
    name: "file",
    status: "done",
    multiple: false,
    listType: "picture-card",

    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    fileList: UploadedFile,

    onChange(info) {
      setSave(true);
      console.log(UploadedFile);
      if (info.fileList.length) {
        setUplaoded([...[], info.file]);
      } else {
        setUplaoded([]);
      }
    },
  };

  const props2 = {
    name: "file",
    status: "done",
    multiple: false,

    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    fileList: UploadedFile2,

    onChange(info) {
      setSave(true);
      console.log(UploadedFile2);
      if (info.fileList.length) {
        setUplaoded2([...[], info.file]);
      } else {
        setUplaoded2([]);
      }
    },
  };
// ...

return (
  <div className="acc-topc">
    <div className=" account-page-c">
      <h5 className="settings-p-bn">Settings</h5>
      {organization ? (
        <div className="tags-date-area settings-bz-c right-area-det-m">
          <table>
            <tbody>
              <tr>
                <td>
                  <h6>Shield:</h6>
                </td>
                <td>
                  <Switch
                    onChange={(v) => {
                      console.log(v);
                      setSave(true);
                      setOrganization({
                        ...organization,
                        shield: v,
                      });
                      updateOrganization({ shield: v });
                    }}
                    checked={organization.shield}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h6>Evaluation:</h6>
                </td>
                <td>
                  <Switch
                    onChange={(v) => {
                      setSave(true);
                      setOrganization({
                        ...organization,
                        eval: v,
                      });
                      updateOrganization({ eval: v });
                    }}
                    checked={organization.eval}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h6>Observe:</h6>
                </td>
                <td>
                  <Switch
                    onChange={(v) => {
                      setSave(true);
                      setOrganization({
                        ...organization,
                        observe: v,
                      });
                      updateOrganization({ observe: v });
                    }}
                    checked={organization.observe}
                 //   style={{ background: 'white', borderColor: 'white' }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h6>Augment:</h6>
                </td>
                <td>
                  <Switch
                    onChange={(v) => {
                      setSave(true);
                      setOrganization({
                        ...organization,
                        augment: v,
                      });
                      updateOrganization({ augment: v });
                    }}
                    checked={organization.augment}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <h6>Retention Policy:</h6>
                </td>
                <td>
                  {/* Assuming Select is a dropdown component */}
                  <Select
                    value={organization.retention_policy ? organization.retention_policy : 24}
                    onChange={(v) => {
                      setSave(true);
                      setOrganization({
                        ...organization,
                        retention_policy: v,
                      });
                      updateOrganization({ retention_policy: v });
                    }}
                  >
                    {rp.map((r, ri) => (
                      <Option value={r} key={ri}>
                        {r}
                      </Option>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>
                  <h6>Platform Credits:</h6>
                </td>
                <td style={{ display: "flex" }}>
                  <b>{organization.platform_credits}</b>
                  <Button
                    className={`editor-tag-tab active-tg-wdt`}
                    onClick={() => setShowPrice(true)}
                    size="small"
                    style={{ marginLeft: "10px" }}
                  >
                    Add Credits
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          {organization && organization.subscription === "free" ? (
            <b style={{ marginLeft: "10px" }}>
              You currently have up to 20 minutes of free usage to try out the platform
            </b>
          ) : null}
        </div>
      ) : null}
    </div>
  </div>
);

}
