import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import {
  Affix,
  Alert,
  Button,
  Dropdown,
  Progress,
  Select,
  Skeleton,
  Tooltip,
  message,
} from "antd";
import { CgNotes } from "react-icons/cg";
import { FaArrowLeft, FaHistory, FaMagic, FaPlay, FaPlus } from "react-icons/fa";
import { RiChatVoiceFill } from "react-icons/ri";
import { BiBookBookmark, BiLibrary, BiSolidHomeSmile } from "react-icons/bi";
import { BsFillChatDotsFill, BsFillInfoCircleFill } from "react-icons/bs";
import Generate from "./Generate";
import axios from "axios";
import { MdClose, MdSmsFailed } from "react-icons/md";
import GreenTick from "../../../../components/GreenTick";
import Goals from "./Goals";

import { v4 as uuidv4 } from "uuid";
import RightGenerate from "./RightGenerate";
import ReactPlayer from "react-player";

const { Option } = Select;
export default function VideoBlank({ data }) {
  const { editor, seteditor, user, stories, setStories, setShowPager } =
    useContext(SearchContext);
  const [callImg, setCallImg] = useState(1);
  const [imgId, setImgId] = useState();
  // const [createdImg, setCreatedImg] = useState();
  const [failed, setFailed] = useState();
  const [play, setPlay] = useState(false);

  const callGet = (d) => {
    setImgId(d);
    setCallImg(callImg + 1);
    // seteditor({ ...editor, iconsRight: true });
  };
  useEffect(() => {
    if (imgId) {
      setFailed();
      getImgGenerated();
    }
  }, [callImg]);

  const getImgGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/${imgId && imgId._id}`
      );

      // Update the state with the retrieved jobs data

      if (response.data?.imageData?.quene === "progress") {
        setTimeout(() => {
          setCallImg(callImg + 1);
          setImgId({
            ...imgId,
            progress: response.data?.imageData.progress_bar,
          });
        }, 3000);
      } else if (response.data?.imageData?.quene === "complete") {
        setImgId();
        console.log(response.data.imageData.position, editor.position);
        let all = stories.data
          ? stories.data.filter((s) => s.position !== undefined)
          : [];
        all.push(response.data?.imageData);
        seteditor({
          ...editor,
          activeContent:
            response.data.imageData.position === editor.position
              ? response.data.imageData
              : editor.activeContent,
        });
        setStories({
          ...stories,
          data: all.sort((a, b) => a.position - b.position),
        });
      } else {
        setImgId();
        setFailed(response.data?.imageData.query);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  const updateValue = (obj, id, total) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          // removeData({ _id: id });
        }
      });
  };
  const removeData = (d) => {
    let all = stories.data.filter((f) => f._id !== d._id);
    setStories({
      ...stories,
      data: all,
    });
  };
  const changeData = (d) => {
    let all = stories.data.map((f) => (f._id === d._id ? d : f));
    console.log(all, d);
    setStories({
      ...stories,
      data: all,
    });
  };
  const addData = (d) => {
    let all = [...stories.data, d];

    setStories({
      ...stories,
      data: all,
    });
    setShowPager(false);
  };
  return (
    <div className="content-ed-main">
      <div className="content-img-bx">
        <div className="coonetnt-img-bx-m1">
          {data && data.file_url ? (
            <div className="blank-page-imhg-c">
              <div className="close-icon space-bb">
                {data.file_url2 || data.file_text ? (
                  <FaArrowLeft
                    onClick={() => {
                      seteditor({
                        ...editor,
                        activeContent: {
                          ...editor.activeContent,
                          file_url2: undefined,
                          file_text: undefined,
                        },
                      });
                      changeData({
                        ...editor.activeContent,
                        file_url2: undefined,
                        file_text: undefined,
                      });
                    }}
                  />
                ) : (
                  <span />
                )}

                <MdClose
                  onClick={() => {
                    seteditor({
                      ...editor,
                      activeContent: {},
                    });

                    changeData({ _id: editor.activeContent._id });
                    if (stories.active) {
                      updateValue(
                        { project_id: "deleted" },
                        editor.activeContent._id
                      );
                    }
                  }}
                />
              </div>

              {data.file_text ? (
                <div className="blank-page-imhg-c">
                  <div className="video-container video-container-round">
                    <ReactPlayer
                      playing
                      url={editor.activeContent.file_url}
                      controls
                    />
                  </div>
                  <div className="bx-iucon-sx">{data.file_text}</div>
                </div>
              ) : data.file_url2 ? (
                <div className="video-container video-container-round">
                  <ReactPlayer
                    playing
                    url={editor.activeContent.file_url2}
                    controls
                  />
                </div>
              ) : (
                play ? (
                    <div className="video-container video-container-round">
                    <ReactPlayer
                      playing
                      url={editor.activeContent.file_url}
                      controls
                    />
                    </div>
                  ) : (
                    <div className="video-thumbnail-img img-2-sx">
                      <img src={data.root_image} alt="" />
                      <div className="icons-bx-ri-vd2">
                        <span className="play-blur-glow">
                          <Button
                            onClick={() => setPlay(true)}
                            size="large"
                            className="large-play-btn"
                          >
                            <FaPlay className="r-atc-incs" />
                          </Button>
                        </span>
                      </div>
                    </div>
                  )
              )}

              {stories.data &&
              stories.data[stories.data.length - 1]?.media_type ===
                undefined ? null : (
                <div className="close-icon plus-icon">
                  <FaPlus
                    onClick={() => {
                      let id = uuidv4();

                      // seteditor({
                      //   ...editor,
                      //   activeContent: { _id: id },

                      //   // position: stories.data?.length
                      //   //   ? stories.data.length
                      //   //   : editor.position,
                      // });
                      addData({ _id: id });
                      message.info("Created At End");
                    }}
                  />
                </div>
              )}
              {(editor.generateImg === "text2video" ||
                editor.generateImg === "image_ad" ||
                editor.generateImg === "image_new") &&
              editor.image_id === data._id ? (
                <RightGenerate />
              ) : null}
            </div>
          ) : // ) : imgId || imgLoading ? (
          imgId || data.imgLoading ? (
            <div className="img-loader-bx">
              <Skeleton.Image active />
              {imgId ? (
                <Progress
                  status="active"
                  percent={imgId.progress ? imgId.progress : 0}
                />
              ) : null}
            </div>
          ) : (
            <div className="blank-area-box">
              {editor.imageBlank === "generate" ? (
                <div>
                  {failed ? (
                    <Alert
                      message="Generation Failed"
                      description="Your generation failed. We’re sorry about that. Please try again"
                      type="info"
                      showIcon
                      className="info-elrt-m"
                      icon={<MdSmsFailed />}
                    />
                  ) : null}
                  <Generate callGet={callGet} />
                </div>
              ) : null}
            </div>
          )}
        </div>

        {editor.activeContent &&
        editor.activeContent.imgGoals &&
        editor.activeContent.file_url ? (
          <Goals />
        ) : null}
      </div>
      {editor.position ? (
        <div
          onClick={() => console.log(stories, editor)}
          className="position-circle"
        >
          {editor.position}
        </div>
      ) : null}
    </div>
  );
}
