import { Input, Modal, Select, notification } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import axios from "axios";
import { BiTime } from "react-icons/bi";

export default function ReImagineButton({ video, setReimagine, reimagine }) {
  const { user, assist, setShowPrice, organization } =
    useContext(SearchContext);
  const [textField, setTextField] = useState(""); // State to store text input
  const [duration, setDuration] = useState(4); // State to store duration input
  const { Option } = Select; // Destructure Option from Select

  const openNotificationWithIcon = (type) => {
    notification.success({
      duration: 0,
      message: "Video is being created. Check studio for updates",
    });
  };

  const handleSendClick2 = async () => {
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/remix",
        {
          query: textField,
          user_id: user && user.user_id,
          duration: duration,
          // voice,
          image_id: video._id,
        }
      );
      if (response.data?.isSuccess) {
        openNotificationWithIcon();
        setReimagine();
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };
  return (
    <Modal
      open={reimagine}
      onCancel={() => {
        setReimagine();
      }}
      width={600}
      className="no-buttons-modal "
    >
      <div className="create-tab">
        {/* Create Tab Content Goes Here */}
        <div
          className="InputContainer"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Input.TextArea
            placeholder="Type your text here"
            value={textField}
            className="form-control border-0  px-4"
            onChange={(e) => setTextField(e.target.value)}
            autoSize={{ minRows: 1, maxRows: 4 }}
          />
        </div>
        <div className="duration-box-m">
          <div className="InputContainer">
            <Select
              placeholder="Time"
              style={{ width: 120 }} // Set the desired width in pixels
              value={duration}
              onChange={(value) => setDuration(value)}
            >
              {/* <Option disabeled value={""}>
  Duration
</Option> */}
              <Option value={1}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                3s
              </Option>
              <Option value={2}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                6s
              </Option>
              <Option value={4}>
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                12s
              </Option>
              <Option value={6}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                18s
              </Option>
              <Option value={8}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                24s
              </Option>
              <Option value={10}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                30s
              </Option>
              <Option value={12}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                36s
              </Option>
              <Option value={14}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                42s
              </Option>
              <Option value={16}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                48s
              </Option>
              <Option value={18}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                54s
              </Option>
              <Option value={20}>
                {" "}
                <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                1m
              </Option>
            </Select>
          </div>

          <div className="create-button-con">
            <button onClick={handleSendClick2} className="button-21">
              Imagine
              <div className="hoverEffect">
                <div></div>
              </div>
            </button>
          </div>
        </div>
        <div className="card21">
          <div className="card21-img"></div>
          <div className="card21-info">
            <p className="text21-body">Imagine The World Around You</p>
            <p className="txt-card-21">
              Olm lets you generate original videos from scratch up to 1 min
              long. That can be short scenes, educational guides and much more.
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
}
