import {
  Alert,
  Col,
  Input,
  Row,
  Select,
  Collapse,
  theme,
  Spin,
  message,
  Button,
  Modal,
  TimePicker,
  Slider,
  notification,
  Tooltip,
  Popconfirm,
  Tag,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BiTime } from "react-icons/bi";
import {
  FaCheck,
  FaPlay,
  FaQuestion,
  FaQuestionCircle,
  FaStar,
} from "react-icons/fa";
import ReactPlayer from "react-player";

import {
  AiFillThunderbolt,
  AiOutlineCloseCircle,
  AiOutlineQuestionCircle,
  AiTwotoneThunderbolt,
} from "react-icons/ai";
import noneImg from "../../../../images/2222628-200.png";

import { useNavigate } from "react-router-dom";
import SearchContext from "../../../../Context/SearchContext";

const ff = {
  1: "Very Slow",
  2: "Slow",
  3: "Normal",
  4: "Fast",
  5: "Very Fast",
};
const ffd = {
  1: "very_slow",
  2: "slow",
  3: "normal",
  4: "fast",
  5: "very_fast",
};
export default function CreateVideo() {
  let playerRef = useRef();
  const navigate = useNavigate();
  const {
    user,
    assist,
    setShowPrice,
    organization,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
  } = useContext(SearchContext);
  const { token } = theme.useToken();
  const [textField, setTextField] = useState(""); // State to store text input
  const [duration, setDuration] = useState(2); // State to store duration input
  const [generatedID, setGeneratedID] = useState();
  const [generatedVID, setGeneratedVID] = useState();
  const [activeVideo, setActiveVideo] = useState();
  const [clip, setClip] = useState();
  const [voice, setVoice] = useState(false);
  const [editVideo, setEditVideo] = useState();
  const [callApi, setCallApi] = useState(1);
  const [creativity, setCreativity] = useState(1);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const { Option } = Select; // Destructure Option from Select
  const [searched, setSearched] = useState({}); // Add style state
  const [motion, setMotion] = useState(3); // Add style state
  const [modalVisible, setModalVisible] = useState(false);
  const [styles, setStyles] = useState([]);
  const [video_style, setvideo_style] = useState("");
  const [selectedStyle, setSelectedStyle] = useState("");
  const [events, setevents] = useState("");
  const [search, setSearch] = useState("");
  const [searchST, setSearchST] = useState("");
  const [environment, setEnvironment] = useState("");
  const [tone, setTone] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [suggestoins, setSuggestoins] = useState();
  const [reimagine, setReimagine] = useState();
  const [isQuick, setIsQuick] = useState();
  const [mood, setMood] = useState(0.5);
  const [vfx, setvfx] = useState(true);
  const [isAdwance, setIsAdwance] = useState();

  useEffect(() => {
    if (search.length) {
      getVideos();
    } else {
      getVideoGenerated();
    }
  }, [callApi, search]);

  useEffect(() => {
    if (searchST.trim().length) {
      fetchStylesS();
    } else {
      fetchStyles();
    }
  }, [searchST]);

  useEffect(() => {
    if (organization && organization.subscription === "Pro") {
      setDuration(1);
    }
  }, [organization]);

  const fetchStylesS = () => {
    const obj = { query: searchST };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/vector/style/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setStyles([...[], ...data]);
        }
      });
  };

  const fetchStyles = async () => {
    try {
      // Fetch style data from the API
      const styleResponse = await axios.get(
        `https://embed.axv.ai/api/vector/style`
      );

      // Update the state with the retrieved style data from vectorList
      setStyles(styleResponse.data?.vectorList);
    } catch (error) {
      console.error("Error fetching style data:", error);
    }
  };

  const getVideoGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=original&status=generated`
      );

      // Update the state with the retrieved jobs data
      setGeneratedVID(response.data?.imageList);
      setGeneratedID();
      setIsQuick();
      if (isThunder !== "thunder") {
        setTimeout(() => {
          setCallApi(callApi + 1);
        }, 10000);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  const getVideos = () => {
    const obj = { query: search, user_id: user && user.user_id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search/user`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setGeneratedVID([...[], ...data]);
        }
      });
  };

  const handleSendClick = async (srch) => {
    const selectedStyleObject = styles.find(
      (styleObj) => styleObj.name === selectedStyle
    );
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      stream_id: srch && srch._id,
      stream: srch ? "init" : undefined,
      duration: duration,
      voice,
      mood,
      username: user && user.username,
      profile_picture: user && user.profile_picture,
      source_image: assist?.selAssetID?._id,
      style: selectedStyleObject?.metaname,
      video_style,
      events,
      creativity,
      environment,
      motion: ffd[motion],
      vfx,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/video",
        obj
      );
      window.localStorage.setItem("video-id", JSON.stringify(response.data));
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }

      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };

  const formatter1 = (value) => `${ff[value]}`;

  const itemsAdwance = () => {
    return (
      <table className="table-box-adwance">
        <tr>
          <td>
            Mood{" "}
            <Tooltip title="Mood">
              <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
            </Tooltip>
          </td>
          <td>
            <div>
              <Slider
                defaultValue={mood}
                onChange={(v) => setMood(v)}
                min={0}
                max={1}
                step={0.25}
              />
            </div>
          </td>
        </tr>

        <tr>
          <td>
            Motion{" "}
            <Tooltip title="Motion">
              <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
            </Tooltip>{" "}
          </td>
          <td>
            <div>
              <Slider
                defaultValue={motion}
                onChange={(v) => setMotion(v)}
                min={1}
                max={5}
                step={1}
                tooltip={{
                  formatter: formatter1,
                }}
              />
            </div>
          </td>
        </tr>

        <tr>
          <td>
            Tone{" "}
            <Tooltip title="Tone">
              <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
            </Tooltip>{" "}
          </td>
          <td>
            <div className="color-select-boxz">
              <div style={{ color: "black" }} onClick={() => setTone("none")}>
                None
              </div>
              <div
                style={{ background: "blue" }}
                onClick={() => setTone("blue")}
              >
                {tone === "blue" ? <FaCheck /> : null}
              </div>
              <div style={{ background: "red" }} onClick={() => setTone("red")}>
                {tone === "red" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "linear-gradient(to right, blue , red)" }}
                onClick={() => setTone("duotone")}
              >
                {tone === "duotone" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "aqua" }}
                onClick={() => setTone("aqua")}
              >
                {tone === "aqua" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "lightblue" }}
                onClick={() => setTone("ice_blue")}
              >
                {tone === "ice_blue" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "burlywood" }}
                onClick={() => setTone("aqua")}
              >
                {tone === "aqua" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "yellow" }}
                onClick={() => setTone("yellow")}
              >
                {tone === "yellow" ? <FaCheck /> : null}
              </div>
              <div
                style={{
                  background: "linear-gradient(to right, black , white)",
                }}
                onClick={() => setTone("black_white")}
              >
                {tone === "black_white" ? <FaCheck /> : null}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td></td>
          <td>
            <div className="flex-end">
              <Button onClick={() => setIsAdwance()} type="primary">
                Close
              </Button>
            </div>
          </td>
        </tr>
      </table>
    );
  };

  return (
    <Row className="create-tab-main-row">
      <Col className="create-tab-main" span={24}>
        <div className="create-tab">
          {/* Create Tab Content Goes Here */}
          <div
            // ref={ref1}
            className="InputContainer"
            // style={{ display: "flex", alignItems: "center" }}
          >
            <Input.TextArea
              placeholder="Start Your Idea Here"
              value={
                organization && organization.onboard === "tour"
                  ? "story about artificial intelligence the dawn of a new era"
                  : textField
              }
              className="form-control border-0  px-4"
              onChange={(e) => {
                // if (e.target.value === "") {
                //   setSuggestoins();
                // } else {
                //   getQueries(e.target.value);
                // }
                setTextField(e.target.value);
              }}
              autoSize={{ minRows: 1, maxRows: 4 }}
              allowClear
              style={{ borderRadius: "20px" }}
            />
          </div>
          <div className="duration-box-m">
            <div ref={ref2} className="InputContainer">
              <Select
                placeholder="Time"
                style={{ width: "150px" }} // Set the desired width in pixels
                value={duration}
                onChange={(value) => {
                  if (
                    organization &&
                    organization.subscription === "Pro" &&
                    value !== 1
                  ) {
                    setShowPrice(true);
                  } else {
                    setDuration(value);
                  }
                }}
              >
                <Option value={1}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  3s
                </Option>
                <Option value={2}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  6s
                </Option>
                <Option value={4}>
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  12s
                </Option>
                <Option value={6}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  18s
                </Option>
                <Option value={8}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  24s
                </Option>
                <Option value={10}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  30s
                </Option>
                <Option value={12}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  36s
                </Option>
                <Option value={14}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  42s
                </Option>
                <Option value={16}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  48s
                </Option>
                <Option value={18}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  54s
                </Option>
                <Option value={20}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  1m
                </Option>
              </Select>
            </div>

            <Modal
              title="Select Style"
              open={modalVisible}
              onCancel={() => setModalVisible(false)}
              className="black-modal no-buttons-modal"
            >
              <div className="form-group mb-3">
                <input
                  style={{ maxWidth: "250px", margin: "0 auto" }}
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchST(e.target.value)}
                  value={searchST}
                  required=""
                  className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    border:
                      selectedStyle === ""
                        ? "2px solid white"
                        : "2px solid black",
                  }}
                  onClick={() => setSelectedStyle("")}
                  className="style-card-mn"
                >
                  <img
                    onClick={() => console.log(selectedStyle)}
                    src={noneImg}
                    alt=""
                  />
                  <span> None</span>
                </div>

                {styles &&
                  styles.map((style, index) => (
                    <div
                      key={index}
                      className="style-card-mn"
                      style={{
                        border:
                          selectedStyle === style.name
                            ? "2px solid white"
                            : "2px solid black",
                        // Add a border for selected style
                      }}
                      onClick={() => setSelectedStyle(style.name)}
                    >
                      <img
                        src={style.file_url}
                        alt={style.name}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginBottom: "10px",
                          borderRadius: "90%",
                        }}
                      />
                      <span>{style.name}</span>
                    </div>
                  ))}
              </div>
            </Modal>

            <div className="create-button-con">
              <button
                ref={ref3}
                onClick={() => {
                  handleSendClick();
                  setGeneratedID(textField);
                }}
                className="button-21"
              >
                Imagine
                <div className="hoverEffect">
                  <div></div>
                </div>
              </button>
            </div>
          </div>
          <div>
            <Tag
              onClick={() => {
                setIsAdwance(true);
              }}
              className="custom-button"
              style={{ marginTop: "15px", marginLeft: "8px" }}
            >
              Advanced Settings
            </Tag>
            <Modal
              open={isAdwance !== undefined}
              onCancel={() => {
                setIsAdwance();
              }}
              width={500}
              className="no-buttons-modal "
            >
              <h5>Advanced Settings</h5>
              {itemsAdwance()}
            </Modal>
          </div>

          <div className="cta-buttons-c">
            {isQuick ? (
              <div className="quick-generates-div">
                <div className="clicked-thunder-query">
                  <span>
                    {isQuick} <AiFillThunderbolt />
                  </span>
                </div>
              </div>
            ) : null}
            {suggestoins && suggestoins.length ? (
              <div className="quick-generates-div">
                <h6>
                  <span>
                    Quick Stream{" "}
                    <Tooltip title="Quickstream allows you to generate videos in seconds. The videos are still original and unique to you">
                      <span className="question-circle-c">?</span>
                    </Tooltip>
                  </span>{" "}
                  <AiTwotoneThunderbolt color="orange" />
                </h6>
                {suggestoins.map((s, si) => {
                  return (
                    <button
                      onClick={() => {
                        handleSendClick(s);
                        setIsQuick(s.query);
                        setSuggestoins();
                        setTimeout(() => {
                          setIsQuick();
                          getVideoGenerated("thunder");
                        }, 5000);
                      }}
                      key={si}
                      class="cta"
                    >
                      <span>
                        {s.query}{" "}
                        <b style={{ color: "#4158d0" }}>
                          {parseInt(Number(s.video_duration))}s
                        </b>
                      </span>
                      <svg viewBox="0 0 13 10" height="10px" width="15px">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </button>
                  );
                })}
              </div>
            ) : null}
            
          </div>

          <div>
            {generatedID ? (
              <Alert
                className="gradient-alert info-alert-bx"
                message={
                  <div>
                    Generating video{" "}
                    <b>
                      <q>{generatedID}</q>
                    </b>
                  </div>
                }
                type="info"
                showIcon
                icon={
                  <div className="spiner-main-box">
                    <div className="spinner"></div>{" "}
                    <FaPlay className="thunder-icon" />
                  </div>
                }
              />
            ) : null}
          </div>
        </div>
      </Col>
    </Row>
  );
}
