import { Modal } from "antd";
import React, { useContext, useState } from "react";
import Content from "./Content";
import SearchContext from "../../../Context/SearchContext";

export default function Options() {
  const [activeTab, setActiveTab] = useState();
  const { type, setType } = useContext(SearchContext);
  return (
    <div className="tabs-new-wc">
      <button
        onClick={() => {
          setActiveTab("video");
          setType("video");
        }}
        className={`button-tab ${activeTab === "video" ? "active-btn-t" : ""}`}
      >
        Start From Video
      </button>
      <button
        onClick={() => {
          setActiveTab("image");
          setType("image");
        }}
        className={`button-tab ${activeTab === "image" ? "active-btn-t" : ""}`}
      >
        Start From Image
      </button>
      <button
        onClick={() => setActiveTab("View Characters")}
        className={`button-tab ${
          activeTab === "View Characters" ? "active-btn-t" : ""
        }`}
      >
        View Characters
      </button>
      <button
        onClick={() => setActiveTab("View Environments")}
        className={`button-tab ${
          activeTab === "View Environments" ? "active-btn-t" : ""
        }`}
      >
        View Environments
      </button>
      <Modal
        open={activeTab !== undefined}
        onCancel={() => {
          setActiveTab();
        }}
        width={900}
        className="no-buttons-modal black-modal top-20-modal"
      >
        {activeTab === "video" || activeTab === "image" ? (
          <Content activeTab={activeTab} />
        ) : null}
      </Modal>
    </div>
  );
}
