import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "antd";
import vd from "../../../images/Mountains_34d.mp4";
import img1 from "../../../images/video-img.jpg";
import { FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
const YourComponent = () => {
  const navigate = useNavigate();
  const [show1, setShow1] = useState();
  const [show2, setShow2] = useState();
  const [show3, setShow3] = useState();
  const [s1, setS1] = useState(false);
  const [s2, setS2] = useState(false);
  const [s3, setS3] = useState(false);
  useEffect(() => {
    // Set s1 to true after 5 seconds
    const timeout1 = setTimeout(() => {
      setS1(true);
    }, 1000);

    // Set s2 to true after 10 seconds
    const timeout2 = setTimeout(() => {
      setS2(true);
    }, 8000);

    // Set s3 to true after 15 seconds
    const timeout3 = setTimeout(() => {
      setS3(true);
    }, 15000);

    // Cleanup timeouts to avoid memory leaks
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, []);
  return (
    <div className="container">
      <div className="video-sec-hmb">
        {/* Left side */}
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <motion.div
              transition={{
                ease: "easeOut",
                duration: 1,
                delay: 0,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="vdioe-hm-left"
            >
              <h1>A New Way To Handle Multimedia</h1>
              <p>
                Leverage the most capable AI model that engages with and creates
                multimedia files, offering a new approach to achieving your
                goals. ModelUI, with its intuitive single-text interface, is
                reshaping the way tasks are accomplished.
              </p>
              <div className="buttons-area-bx-lft">
                <Button
                  onClick={() => navigate("/signup")}
                  size="large"
                  className={`editor-tag-tab active-tg-wdt theme-shade-btn`}
                >
                  Get Started
                </Button>
                <Button
                  onClick={() => navigate("/")}
                  size="large"
                  className={`editor-tag-tab active-tg-wdt theme-shade-btn`}
                >
                  Workflows
                </Button>
              </div>
            </motion.div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="vdioe-hm-right">
              <Row>
                <Col span={24}>
                  <motion.div
                    transition={{ ease: "easeOut", duration: 1 }}
                    initial={{ opacity: 0, scale: 0.5, y: 200 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                  >
                    <div className="card-bx-h4">
                      <div className="red-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Video Generation</h6>
                        <h4>
                          <Typewriter
                            options={{
                              strings:
                                " A video of an art exhibition with classical music  in the background and close-ups of the artworks ",
                              autoStart: s1,
                              cursor: "",
                              delay: 20,
                            }}
                          />
                        </h4>
                        <div className="icon-bx-section4">
                          <motion.div
                            transition={{
                              ease: "easeOut",
                              duration: 1,
                              delay: 6,
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                          >
                            <button
                              onClick={() =>
                                setShow1(
                                  "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/513125bb5b33056f0aa07bb3a8393a0c.mp4?generation=1705456501355454&alt=media"
                                )
                              }
                              type="button"
                              class="btn"
                            >
                              <strong>Watch</strong>
                              <div id="container-stars">
                                <div id="stars"></div>
                              </div>

                              <div id="glow">
                                <div class="circle"></div>
                                <div class="circle"></div>
                              </div>
                            </button>
                          </motion.div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </Col>
                <Col span={24}>
                  <motion.div
                    transition={{ ease: "easeOut", duration: 1, delay: 8 }}
                    initial={{ opacity: 0, scale: 0.5, y: 200 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                  >
                    <div className="card-bx-h4">
                      <div className="green-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Editing</h6>
                        <h4>
                          <Typewriter
                            options={{
                              strings:
                                "Enhance the cityscape thunderstorm video with dramatic lighting effects and intense color grading",
                              autoStart: s2,
                              cursor: "",
                              delay: 20,
                            }}
                          />{" "}
                        </h4>

                        <motion.div
                          transition={{
                            ease: "easeOut",
                            duration: 1,
                            delay: 14,
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          className="icon-bx-section4 "
                        >
                          <button
                            onClick={() =>
                              setShow2({
                                file_url:
                                  "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/74113e0700adfe28ac383f36fba9fc29.mp3?generation=1705404204326984&alt=media",
                                root_image:
                                  "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/957821474013de01d5e0da0103d54c13.png?generation=1705404111717017&alt=media",
                              })
                            }
                            type="button"
                            class="btn"
                          >
                            <strong>Listen</strong>
                            <div id="container-stars">
                              <div id="stars"></div>
                            </div>

                            <div id="glow">
                              <div class="circle"></div>
                              <div class="circle"></div>
                            </div>
                          </button>
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                </Col>
                <Col span={24}>
                  <motion.div
                    transition={{ ease: "easeOut", duration: 1, delay: 15 }}
                    initial={{ opacity: 0, scale: 0.5, y: 200 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                  >
                    <div className="card-bx-h4">
                      <div className="blue-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Music Generation</h6>
                        <h4>
                          <Typewriter
                            options={{
                              strings:
                                "Create a music track for a video featuring athletes in action, showcasing images of different sports",
                              autoStart: s3,
                              cursor: "",
                              delay: 20,
                            }}
                          />{" "}
                        </h4>
                        <div className="icon-bx-section4">
                          <motion.div
                            transition={{
                              ease: "easeOut",
                              duration: 1,
                              delay: 21,
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                          >
                            <button
                              onClick={() =>
                                setShow3(
                                  "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/957821474013de01d5e0da0103d54c13.png?generation=1705404111717017&alt=media"
                                )
                              }
                              type="button"
                              class="btn"
                            >
                              <strong>Watch</strong>
                              <div id="container-stars">
                                <div id="stars"></div>
                              </div>

                              <div id="glow">
                                <div class="circle"></div>
                                <div class="circle"></div>
                              </div>
                            </button>
                          </motion.div>
                        </div>{" "}
                      </div>
                    </div>
                  </motion.div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* Right side with video */}
      </div>
      <Modal
        open={show1 !== undefined}
        onCancel={() => {
          setShow1();
        }}
        width={700}
        className="no-buttons-modal black-modal"
      >
        {show1 ? (
          <div className="video-container radius-video">
            <ReactPlayer controls url={show1} playing />
          </div>
        ) : null}
      </Modal>
      <Modal
        open={show2 !== undefined}
        onCancel={() => {
          setShow2();
        }}
        width={700}
        className="no-buttons-modal black-modal"
      >
        {show2 ? (
          <div>
            <div className="video-thumbnail-imgg height-img-mx">
              <img src={show2.root_image} alt="" />
            </div>
            <div
              className=" audio-player-mn-bx"
              // ref={ref10}
            >
              {/* <h5>{m.media_type}</h5> */}
              <ReactAudioPlayer src={show2.file_url} controls />
              {/* <h6>{m.query}</h6> */}
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        open={show3 !== undefined}
        onCancel={() => {
          setShow3();
        }}
        width={700}
        className="no-buttons-modal black-modal"
      >
        {show3 ? (
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
            >
              <div
                style={{ padding: "5px" }}
                className="video-thumbnail-imgg height-img-mx"
              >
                <img src={show3} alt="" />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
            >
              <div
                style={{ padding: "5px" }}
                className="video-thumbnail-imgg height-img-mx"
              >
                <img src={show3} alt="" />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
            >
              <div
                style={{ padding: "5px" }}
                className="video-thumbnail-imgg height-img-mx"
              >
                <img src={show3} alt="" />
              </div>
            </Col>
          </Row>
        ) : null}
      </Modal>
    </div>
  );
};

export default YourComponent;
