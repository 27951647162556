import {
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { RiSettings3Fill } from "react-icons/ri";
import { MdAddCircle } from "react-icons/md";
import SearchContext from "../../../../../Context/SearchContext";
import titleImg from "../../../../../images/huminoid.png";
import AddBlock from "../../left/AddBlock";
import thumbnail from "../../../../../images/8ab29ff281f0601a467983a9657dc481.png";
const { Option } = Select;

export default function ConnectBehavior() {
  const {
    user,
    behavior,
    setBehavior,
    tasks,
    setTasks,
    images,
    setImages,
    listData,
    setListData,
  } = useContext(SearchContext);

  const [add, setAdd] = useState();
  const [loadingP, setLoadingP] = useState(false);
  const [previewText, setPreviewText] = useState("");

  useEffect(() => {
    if (behavior.data === undefined) {
      getbehavior();
    }
  }, []);

  const saveBehavior = (obj) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/behavior`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          let all = [data.data, ...behavior.data];
          setBehavior({ ...behavior, data: all });
        } else {
          message.error("something went wrong");
        }
      });
  };
  const updateBehavior = (obj, id) => {
    delete obj._id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/behavior/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          let all = behavior.data.map((m) =>
            m._id === id ? data.behaviorData : m
          );
          setBehavior({ ...behavior, data: all });
        } else {
          message.error("something went wrong");
        }
      });
  };

  const getbehavior = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/behavior?page=1&limit=10&user_id=${
        user.user_id
      }&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setBehavior({
            ...behavior,
            data: data.behaviorList,
            totalPages: data.totalPages,
            selected: { _id: "auto" },
          });
        }
      });
  };
  const deleteBehavior = (row) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/behavior/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = behavior.data.filter((p) => p._id !== row._id);

          setBehavior({
            ...behavior,
            data: all,
            selected: { _id: "auto" },
            callConnection: behavior.callConnection
              ? behavior.callConnection + 1
              : 1,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const adjustCLick = (b) => {
    if (tasks.activeIcon === "axons" || tasks.activeIcon === "chat_log") {
      setBehavior({
        ...behavior,
        selected: b,
        callConnection:
          tasks.activeIcon === "axons"
            ? behavior.callConnection
              ? behavior.callConnection + 1
              : 1
            : behavior.callConnection,
        callBlocks:
          tasks.activeIcon === "block"
            ? behavior.callBlocks
              ? behavior.callBlocks + 1
              : 1
            : behavior.callBlocks,
        callChat:
          tasks.activeIcon === "chat_log"
            ? behavior.callChat
              ? behavior.callChat + 1
              : 1
            : behavior.callChat,
      });
    } else if (tasks.activeIcon === "block") {
      setImages({
        ...images,
        // data: data.imageList,
        blocks: undefined,
        totalPBlock: undefined,
        pageNoB: 1,
      });
      setBehavior({
        ...behavior,
        selected: b,
        callBlocks:
          tasks.activeIcon === "block"
            ? behavior.callBlocks
              ? behavior.callBlocks + 1
              : 1
            : behavior.callBlocks,
      });
    }
  };
  const PreviewImage = (text) => {
    const obj = { query: text };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/create/preview/image`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoadingP(false);
        if (data.isSuccess) {
          setAdd({ ...add, headshot: data.data?.data });
        } else {
          message.error();
          console.error("Failed Try Again");
        }
      })
      .catch((error) => {
        setLoadingP(false);

        console.error("Failed Try Again");
      });
  };

  return (
    <div style={{ paddingTop: "0px" }} className="active-goals-tc21">
      <div className="active-conn-c  behaveior-c">
        <img className="huminoid-img-t" src={titleImg} alt="" />
        <div
          style={{ overflowX: "visible" }}
          className="behaviour-cards-bx behavrious-tabs-c"
        >
          <div
            className={`behaviour-cards ${
              tasks.activeIcon === "block" ? " selected-bx-c" : ""
            }`}
            onClick={() => {
              setTasks({ ...tasks, activeIcon: "block" });
            }}
          >
            <b>Elements</b>
          </div>
          <div
            className={`behaviour-cards ${
              tasks.activeIcon === "axons" ? " selected-bx-c" : ""
            }`}
            onClick={() => {
              setTasks({ ...tasks, activeIcon: "axons" });
            }}
          >
            <b>Skills</b>
          </div>
          <div
            className={`behaviour-cards ${
              tasks.activeIcon === "chat_log" ? " selected-bx-c" : ""
            }`}
            onClick={() => {
              setTasks({ ...tasks, activeIcon: "chat_log" });
            }}
          >
            <b>History</b>
          </div>
        </div>

        <div className="behaviour-cards-bx">
          <div
            className={`behaviour-cards ${
              "auto" === behavior.selected?._id ? " selected-bx-c" : ""
            }`}
            onClick={() => adjustCLick({ _id: "auto" })}
          >
            <b>Default</b>
          </div>
          {behavior.data &&
            behavior.data.map((b, bi) => {
              return (
                <Tooltip
                  title={
                    <img
                      className="behaviour-cards-img"
                      src={b.headshot ? b.headshot : thumbnail}
                      alt=""
                    />
                  }
                >
                  <div
                    key={bi}
                    className={`behaviour-cards ${
                      b._id === behavior.selected?._id ? " selected-bx-c" : ""
                    }`}
                    onClick={() => adjustCLick(b)}
                  >
                    <b>{b.name}</b>
                    <RiSettings3Fill
                      onClick={() => setAdd(b)}
                      className="gear-icon-c show-hover"
                    />
                  </div>
                </Tooltip>
              );
            })}
        </div>
        <Tooltip title="Add Behavior">
          <div
            className={`icon-add-plus-c `}
            // size="small"
            onClick={() => {
              setAdd({
                name: "",
                moderation_shield: false,
                copyright_shield: false,
                security_shield: false,
                retention_policy: 1,
                user_id: user.user_id,
              });
            }}
            style={{ marginLeft: "10px" }}
          >
            <MdAddCircle />
          </div>
        </Tooltip>
        <AddBlock type={"Blocks"} />
        <Modal
          open={add !== undefined}
          onCancel={() => {
            setAdd();
            setPreviewText("");
          }}
          className="no-buttons-modal black-modal top-20-modal"
        >
          <h5 className="create-btn-h">
            {" "}
            {add && add._id ? "Update" : "Add"} Behavior
          </h5>
          {add ? (
            <div className=" behavior-table-add">
              <table>
                <tbody>
                  <tr className="input-bx-pas">
                    <td>
                      <h6>Name:</h6>
                    </td>
                    <td>
                      <Input
                        size="large"
                        placeholder="Name"
                        onChange={(e) => {
                          setAdd({ ...add, name: e.target.value });
                        }}
                        value={add.name}
                        className={`grey-input`}
                      />
                    </td>
                  </tr>
                  <tr className="input-bx-pas">
                    <td>
                      <h6>Moderation Shield:</h6>
                    </td>
                    <td>
                      <Switch
                        onChange={(e) => {
                          setAdd({ ...add, moderation_shield: e });
                        }}
                        checked={add.moderation_shield}
                      ></Switch>
                    </td>
                  </tr>
                  <tr className="input-bx-pas">
                    <td>
                      <h6>Copyright Shield:</h6>
                    </td>
                    <td>
                      <Switch
                        onChange={(e) => {
                          setAdd({ ...add, copyright_shield: e });
                        }}
                        checked={add.copyright_shield}
                      ></Switch>
                    </td>
                  </tr>
                  <tr className="input-bx-pas">
                    <td>
                      <h6>Security Shield:</h6>
                    </td>
                    <td>
                      <Switch
                        onChange={(e) => {
                          setAdd({ ...add, security_shield: e });
                        }}
                        checked={add.security_shield}
                      ></Switch>
                    </td>
                  </tr>
                  <tr className="input-bx-pas">
                    <td>
                      <h6>Retention Policy:</h6>
                    </td>
                    <td>
                      <Select
                        value={add.retention_policy}
                        aria-label="Default select example"
                        onChange={(e) => {
                          setAdd({ ...add, retention_policy: e });
                        }}
                        className="grey-input"
                        style={{ width: "80px" }}
                      >
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                      </Select>
                    </td>
                  </tr>
                  <tr className="input-bx-pas">
                    <td className="heading-h-table">
                      <h6>Headshot:</h6>
                    </td>
                    <td>
                      <div className="preview-input-c">
                        <Input.TextArea
                          className="grey-input"
                          placeholder="Type Anything"
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          onChange={(e) => {
                            setPreviewText(e.target.value);
                          }}
                          checked={previewText}
                        />
                        {previewText && previewText.length ? (
                          loadingP ? (
                            <Spin />
                          ) : (
                            <Button
                              size="small"
                              className={`editor-tag-tab active-tg-wdt `}
                              onClick={() => {
                                setLoadingP(true);
                                PreviewImage(previewText);
                              }}
                            >
                              Create
                            </Button>
                          )
                        ) : null}
                      </div>
                      {add.headshot ? (
                        <div>
                          <img
                            className="preview-img-c-m"
                            src={add.headshot}
                            alt=""
                          />
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
          {add && add.name ? (
            <div className="flex-end">
              <Button
                onClick={() => {
                  deleteBehavior(add);
                  setPreviewText("");
                  setAdd();
                }}
                style={{ marginRight: "10px" }}
                className={`editor-tag-tab active-tg-wdt `}
              >
                Delete
              </Button>
              {add._id ? (
                <Button
                  onClick={() => {
                    updateBehavior(add, add._id);
                    setAdd();
                    setPreviewText("");
                  }}
                  className={`editor-tag-tab active-tg-wdt `}
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    saveBehavior(add);
                    setAdd();
                    setPreviewText("");
                  }}
                  className={`editor-tag-tab active-tg-wdt `}
                >
                  Save
                </Button>
              )}
            </div>
          ) : null}
        </Modal>
      </div>
    </div>
  );
}
