import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/SearchContext";
import { Button, Col, Modal, Row, Spin, Tag, Tooltip, message } from "antd";
import Header from "../../components/Header";
import FullLoader from "../storyProject/components/FullLoader";
import { LoadingOutlined } from "@ant-design/icons";
import { FaArrowDown } from "react-icons/fa";
import { GiExpand } from "react-icons/gi";
import { TbArrowRight } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocale } from "antd/es/locale";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

export default function SoundsQuery() {
  const { setTasks, user } = useContext(SearchContext);
  const location = useLocation();
  const [related, setRelated] = useState();
  const [showNotD, setshowNotD] = useState();
  const [callApi, setCallApi] = useState();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [images, setImages] = useState({});
  const [show, setShow] = useState();
  const [cat, setCat] = useState();
  const [query, setquery] = useState();

  const navigate = useNavigate();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  useEffect(() => {
    let o = window.location.pathname.lastIndexOf("/");
    let count = window.location.pathname.slice(7, o);
    setCat(count);
    let q = window.location.pathname.slice(o + 1);
    getImages(count, q.replaceAll("-", " "));
  }, [query, location]);
  useEffect(() => {
    let o = window.location.pathname.lastIndexOf("/");
    let count = window.location.pathname.slice(7, o);
    let q = window.location.pathname.slice(o + 1);
    relatedData(count, q.replaceAll("-", " "));
  }, [query, location]);
  const relatedData = (c, v) => {
    const obj = {
      query: c + " " + v,
      media_type: "sound",
     // user_id: user && user.user_id ? user.user_id : "64dc1de41d6e72846d0bb3f2",
      // media_type: "original",
      pageSize: 12,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/library/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setRelated(data);
        }
      });
  };

  const getImages = (cat, q) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image/library?page=${
        images.imgPNO ? images.imgPNO : 1
      }&limit=1&sort_by=${-1}&media_type=sound&count=${[
        cat,
      ]}&text=${q}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            cImages: data.imageList,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  const copyToClipboard = () => {
    const textToCopy = `${window.location.origin}${window.location.pathname}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  const topRight = (m) => {
    return (
      <div style={{ zIndex: 10 }} className={`top-right-popup `}>
        <b onClick={() => setShow(m)} className="show-hover">
          <GiExpand
            style={{
              position: "relative",
            }}
            className="attach-popup-c"
          />
        </b>

        <b
          onClick={() => {
            setquery(m.query?.replaceAll(" ", "-"));
            navigate(`/video/${cat}/${m.query?.replaceAll(" ", "-")}`);
          }}
          style={{ marginLeft: "5px" }}
          className="show-hover hover"
        >
          <TbArrowRight />
        </b>
      </div>
    );
  };
  return loading ? (
    <FullLoader />
  ) : (
    <div className="images-main-p ">
      <Header />
      <div className="container">
        <Row>
          <Col lg={{ span: 4 }} md={{ span: 3 }} sm={24} xs={24}></Col>
          {images.cImages &&
            images.cImages.map((m, mi) => {
              return (
                <Col
                  lg={{ span: 16 }}
                  md={{ span: 18 }}
                  sm={24}
                  xs={24}
                  key={mi}
                >
                  <div
                    className="display-goal-st-s1"
                    style={{ color: "white", width: "100%" }}
                  >
                    <p className="black-query-bc">
                      {m.query}

                      <span>
                        <Tag
                          style={{ marginTop: "10px" }}
                          onClick={copyToClipboard}
                        >
                          Share
                        </Tag>

                        {user && user.user_id ? (
                          <a
                            style={{ marginTop: "10px" }}
                            href={m.file_url}
                            download
                          >
                            <Tag>Download</Tag>
                          </a>
                        ) : (
                          <Tag onClick={() => setshowNotD(true)}>Download</Tag>
                        )}
                      </span>
                    </p>
                    <div className="video-thumbnail-imgg img-top-bx">
                      <img
                        style={{ maxHeight: "400px" }}
                        src={m.root_image}
                        alt=""
                      />
                    </div>
                    <div className="card-bx-con-fl">
                      <ReactAudioPlayer src={m.file_url} autoPlay controls />
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
        <div className="related-bx-d">
          <Row>
            {related &&
              related.slice(0, 12).map((m, mi) => {
                return (
                  <Col
                    className="img-height-adjusted"
                    lg={{ span: 4 }}
                    md={{ span: 6 }}
                    sm={8}
                    xs={8}
                    key={mi}
                  >
                    <div
                      className="display-goal-st-s1"
                      style={{ color: "white", width: "100%" }}
                    >
                      <div
                        onClick={() => {
                          setquery(m.query?.replaceAll(" ", "-"));
                          navigate(
                            `/sound/${cat}/${m.query?.replaceAll(" ", "-")}`
                          );
                        }}
                        className="video-thumbnail-imgg"
                      >
                        <img src={m.root_image} alt="" />

                        <div className="cat-hover-det">
                          <p>{m.query}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <Modal
        open={show !== undefined}
        onCancel={() => setShow()}
        width={600}
        className="no-buttons-modal black-modal"
      >
        {show ? (
          <div className="download-show-img">
            <img src={show.file_url} alt="" />
            <div className="flex-end">
              <a style={{ marginTop: "10px" }} href={show.file_url} download>
                <Button className={`editor-tag-tab  active-tg-wdt`}>
                  Download
                </Button>
              </a>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        open={showNotD}
        onCancel={() => setshowNotD()}
        width={600}
        className="no-buttons-modal black-modal"
      >
        <div
          style={{ maxHeight: "200px" }}
          className="no-heigh-div-c editor-container"
        >
          <div className="card21">
            <div className="card21-img"></div>
            <div className="card21-info">
              <p className="text21-body">No Assets Available</p>
              <p className="txt-card-21">
                Leverage generative AI to reimagine your videos with Olm Studio.
                Add objects, characters into moments that didn't exist before.
              </p>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "15px" }} className="flex-end">
          {" "}
          <Button
            onClick={() => navigate("/signup")}
            className={`editor-tag-tab  active-tg-wdt`}
          >
            SignUp
          </Button>
        </div>
      </Modal>
    </div>
  );
}
