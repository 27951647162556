import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import axios from "axios";
import { Select } from "antd";
const { Option } = Select;

export default function Search({ search, setSearch }) {
  const { editor, seteditor, editorLeft, seteditorLeft } =
    useContext(SearchContext);

  return (
    <div className="search-main-ed">
      <input
        style={{
          width: 300,
        }}
        type="text"
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        required=""
        className="form-control border-0 shadow-sm px-4 "
      />
      {/* <div class="container8">
        <input
          onBlur={() => {
            if (search.trim().length === 0) {
              setShow(false);
            }
          }}
          value={search}
          type="text"
          name="text"
          class="input8"
          ref={inputRef}
          required=""
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Type to search..."
          style={{ width: search.trim().length || show ? "300px" : "10px" }}
        />
        <div class="icon8">
          {show ? (
            <MdClose
              onClick={() => {
                setShow(false);
                inputRef.current.blur();
                // .focus()
              }}
            />
          ) : (
            <AiOutlineSearch
              onClick={() => {
                setShow(true);
                inputRef.current.focus();
                // .focus()
              }}
            />
          )}
        </div>
      </div> */}
      {editor.cType === "block" ? (
        <div className="block-type-c">
          <Select
            placeholder="Type"
            style={{
              width: 300,
              height:"36px",
              borderRadius
              :"7px"
            }}
            value={editor.blockType ? editor.blockType : ""}
            onChange={(value) => {
              seteditor({ ...editor, blockType: value });
              seteditorLeft(editorLeft + 1);
            }}
            className="grey-input"
          >
            <Option value={""}>All Blocks</Option>
            <Option value={"writing_style"}>Writing Style</Option>
            <Option value={"product"}>Product</Option>
            <Option value={"character"}>Character</Option>
            <Option value={"logo"}>Logo</Option>
            <Option value={"backdrop"}>Backdrop</Option>
            <Option value={"voice"}>Voice</Option>
          </Select>
        </div>
      ) : null}
    </div>
  );
}
