import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  Typography,
  Checkbox,
  Row,
  Col,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import SearchContext from "../../../../../Context/SearchContext";
import TaskIdAssets from "./TaskIdAssets";
import ConnectAlerts from "./ConnectAlerts";
import cardImg from "../../../../../images/18.png";
import { MdClose, MdDoNotDisturb } from "react-icons/md";
import GreenTick from "../../../../../components/GreenTick";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRight, FaPlay } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { motion, useInView } from "framer-motion";

const { Paragraph } = Typography;

export default function ChatLogTask() {
  const { user } = useContext(SearchContext);

  const [chat, setChat] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [shield, setSheild] = useState();

  useEffect(() => {
    if (chat === undefined) {
      setLoadeing(true);
      getChat();
    }
  }, [pageNo]);

  const getChat = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/chat_log?page=${pageNo}&limit=15&sort_by=${-1}&user_id=${
        user?.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setChat(data.chatlogList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const deleteInvite = (id) => {
    const filtereted = chat.filter((t) => t._id !== id);
    setChat(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <div className="assets-bx-h1">
      <div className="active-goals-tc21">
        <ConnectAlerts
          t1={"Understanding Generations."}
          t2={"Augment"}
          p1={
            "Model Generate gives you a single interface to interact with and create for multiple types of data at the same time. This includes creating image, video, sound, voice, music all in the same flow. Try today by simply entering a query."
          }
          p2={
            "You consume credits anytime you send a request for generation. You are charged on each successful media generation whether that is text, images, videos, sound or music. To learn more about how pricing works. See here."
          }
          cardimg={cardImg}
          isAugment={true}
        />
        <motion.div
          transition={{
            ease: "easeOut",
            duration: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div>
            <h5>Log</h5>

            <div className="cards-s4-bx">
              <Row>
                {chat &&
                  chat.map((c, ci) => {
                    return (
                      <Col
                        key={ci}
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                      >
                        <div className="card-bx-h4">
                          <div
                            className={`${
                              c.color_code ? c.color_code : "green"
                            }-card-s4`}
                          >
                            <div className="liner-2px"></div>
                            <h6>{c.task_title ? c.task_title : "Unknown"}</h6>
                            <Paragraph
                              ellipsis={{
                                rows: 1,
                                // expandable: true,
                                symbol: ".",
                              }}
                            >
                              <h4>{c.query}</h4>
                            </Paragraph>
                            <div className="icon-bx-section4">
                              <span>
                                <FaPlay />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
