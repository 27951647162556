import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import SearchContext from "../../Context/SearchContext";
import VideoArea from "./components/VideoArea";
import { Button } from "antd";
import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function WatchTry() {
  const { user } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [videosFeed, setVideosFeed] = useState([]);

  // const userId = "64e920acf96bb2a83107e5f3";

  const getVideos = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/image/watch/free`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setVideosFeed([...videosFeed, ...data.imageList]);
        }
      });
  };

  useEffect(() => {
    getVideos();
  }, []);

  return loading ? (
    <div className="loading-full-page">
      <div className="spiner-main-box">
        <div className="spinner"></div> <FaPlay />
      </div>
    </div>
  ) : (
    <div>
      <div className="fixed-header">
        <Header />
      </div>
      <div className="feed-page">
        {videosFeed.map((v, vi) => (
          <div key={vi} className="feed-view-area">
            <VideoArea video={v} />
          </div>
        ))}
      </div>
      <div style={{paddingBottom:"20px"}} className="load-more-c">
        <Link to="/login">
          <Button className="custom-button">See More Videos Like This</Button>
        </Link>
      </div>
    </div>
  );
}
