import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  Typography,
  Checkbox,
  Tooltip,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import SearchContext from "../../../../../Context/SearchContext";
import TaskIdAssets from "./TaskIdAssets";
import ConnectAlerts from "./ConnectAlerts";
import cardImg from "../../../../../images/17.png";
import { MdClose, MdDoNotDisturb } from "react-icons/md";
import GreenTick from "../../../../../components/GreenTick";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import FullLoader from "../../../../storyProject/components/FullLoader";
const { Paragraph } = Typography;

export default function ChatLog() {
  const { user, behavior } = useContext(SearchContext);

  const [Org, setOrg] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [callApi, setCallApi] = useState(1);

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [shield, setSheild] = useState();

  useEffect(() => {
    if (Org === undefined) {
      setLoadeing(true);
      getOrg();
    }
  }, [pageNo, callApi]);

  useEffect(() => {
    if (Org !== undefined && behavior.callChat) {
      setPageNo(1);
      setTotalPages();
      setOrg();
      setCallApi(callApi + 1);
    }
  }, [behavior.callChat]);

  const getOrg = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/chat_log?page=${pageNo}&limit=15&sort_by=${-1}&user_id=${
        user?.user_id
      }&router_id=${behavior.selected ? behavior.selected._id : "auto"}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setOrg(data.chatlogList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const deleteInvite = (id) => {
    const filtereted = Org.filter((t) => t._id !== id);
    setOrg(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (text, row) => (
        <span style={{ fontSize: "11px" }}>{moment(text).fromNow()}</span>
      ),
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      width: 400,
      render: (text, row) => (
        <div style={{ maxWidth: "400px" }}>
          <Paragraph
            ellipsis={{
              rows: 4,
              // expandable: true,
              symbol: "see more",
            }}
            className="desc-p-crdjjkl"
          >
            {text}
          </Paragraph>
        </div>
      ),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      width: 200,
      render: (text, row) => <div className="description-height">{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (text, row) => (
        <div className="status-bx-hng">
          {text === "failed" ? <MdClose className="red-c" /> : null}
          {text === "complete" ? (
            <GreenTick width={"20px"} height={"20px"} />
          ) : null}
          {text === "progress" ? <Spin indicator={antIcon} /> : null}
          {text === "flagged" ? <IoIosWarning className="yellow-c" /> : null}
        </div>
      ),
    },
    {
      title: "Icon",
      dataIndex: "shield",
      key: "shield",
      width: 100,
      render: (text, row) => (
        <div>
          {text[0]?.status ? (
            <div>
              {text[0]?.status === "failed" ? (
                <MdClose className="red-c" />
              ) : null}
              {text[0]?.status === "complete" ? (
                <GreenTick width={"20px"} height={"20px"} />
              ) : null}
              {text[0]?.status === "progress" ? (
                <Spin indicator={antIcon} />
              ) : null}
              {text[0]?.status === "failed" ||
              text[0]?.status === "complete" ? (
                <FaArrowRight
                  onClick={() => setSheild(row)}
                  style={{ marginLeft: "10px" }}
                  className="hover"
                />
              ) : null}
            </div>
          ) : (
            <MdDoNotDisturb className="yellow-c" />
          )}
        </div>
      ),
    },

    {
      title: "Assets",
      dataIndex: "Assets",
      key: "Assets",
      width: 20,
      render: (text, row) => <TaskIdAssets row={row} />,
    },

    {
      title: "",
      dataIndex: "",
      key: "02",
      width: 70,

      render: (text, row) => (
        <span>
          <Tag
            className="hover"
            onClick={() => displayDelete(row._id)}
            color="rgb(185, 40, 40)"
          >
            D
          </Tag>
        </span>
      ),
    },
  ];
  return (
    <div className="active-goals-tc21">
      <div className="assets-bx-h1">
        <div
          style={{ borderBlockColor: "transparent" }}
          className="active-conn-c"
        >
          <div className="help-text-con">
            <a
              style={{ textDecoration: "none" }}
              href="https://guide.dendric.com/engrams/history"
              target="blank"
            >
              <Tooltip title="Help">
                <button className="btn-yel-c">
                  <span>History Guide</span>

                  <FaArrowRight />
                </button>
              </Tooltip>
            </a>
          </div>
        </div>
        {/* <ConnectAlerts
          t1={""}
          p1={
            "Model Insights give you the ability to understand model generations in a better way. You can point model insights to our models or your own internal models. To learn more, click the link below."
          }
          t2={""}
          p2={
            "Model Insights give you the ability to understand model generations in a better way. You can point model insights to our models or your own internal models. "
          }
          cardimg={cardImg}
          isShield={true}
          //isObserve={true}
        /> */}

        {Org === undefined ? <FullLoader /> : null}
        {Org ? (
          <Table
            pagination={{
              pageSize: 15,
              current: pageNo,
              total: totalPages * 15,
              onChange: (v) => {
                setOrg();
                setPageNo(v);
              },
              size: "small",
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={Org}
            className="black-table pagination-white table-2-p"
          />
        ) : null}
        <Modal
          width={400}
          open={shield !== undefined}
          onCancel={() => setSheild()}
          className="no-buttons-modal black-modal"
        >
          <h5>Insights</h5>
          {shield ? (
            <div
              style={{ marginTop: "20px" }}
              className="tags-date-area full-w-table"
            >
              <table>
                <tr>
                  <td>
                    <b>Status:</b>
                  </td>
                  <td>
                    {" "}
                    {shield.shield[0]?.status === "failed" ? (
                      <MdClose className="red-c" />
                    ) : null}
                    {shield.shield[0]?.status === "complete" ? (
                      <GreenTick width={"20px"} height={"20px"} />
                    ) : null}
                    {shield.shield[0]?.status === "progress" ? (
                      <Spin indicator={antIcon} />
                    ) : null}
                    {shield.shield[0]?.status === undefined ? (
                      <MdDoNotDisturb className="yellow-c" />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>PII Safety:</b>
                  </td>
                  <td>
                    {" "}
                    {shield.shield[0]?.pii_info === "failed" ? (
                      <MdClose className="red-c" />
                    ) : null}
                    {shield.shield[0]?.pii_info === "complete" ? (
                      <GreenTick width={"20px"} height={"20px"} />
                    ) : null}
                    {shield.shield[0]?.pii_info === "progress" ? (
                      <Spin indicator={antIcon} />
                    ) : null}
                    {shield.shield[0]?.pii_info === undefined ? (
                      <MdDoNotDisturb className="yellow-c" />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Blocked Keywords:</b>
                  </td>
                  <td>
                    {" "}
                    {shield.shield[0]?.blocked_keywords === "failed" ? (
                      <MdClose className="red-c" />
                    ) : null}
                    {shield.shield[0]?.blocked_keywords === "complete" ? (
                      <GreenTick width={"20px"} height={"20px"} />
                    ) : null}
                    {shield.shield[0]?.blocked_keywords === "progress" ? (
                      <Spin indicator={antIcon} />
                    ) : null}
                    {shield.shield[0]?.blocked_keywords === undefined ? (
                      <MdDoNotDisturb className="yellow-c" />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Blocked Topics:</b>
                  </td>
                  <td>
                    {" "}
                    {shield.shield[0]?.blocked_topics === "failed" ? (
                      <MdClose className="red-c" />
                    ) : null}
                    {shield.shield[0]?.blocked_topics === "complete" ? (
                      <GreenTick width={"20px"} height={"20px"} />
                    ) : null}
                    {shield.shield[0]?.blocked_topics === "progress" ? (
                      <Spin indicator={antIcon} />
                    ) : null}
                    {shield.shield[0]?.blocked_topics === undefined ? (
                      <MdDoNotDisturb className="yellow-c" />
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Copyright Protection:</b>
                  </td>
                  <td>
                    {" "}
                    {shield.shield[0]?.copyright === "failed" ? (
                      <MdClose className="red-c" />
                    ) : null}
                    {shield.shield[0]?.copyright === "complete" ? (
                      <GreenTick width={"20px"} height={"20px"} />
                    ) : null}
                    {shield.shield[0]?.copyright === "progress" ? (
                      <Spin indicator={antIcon} />
                    ) : null}
                    {shield.shield[0]?.copyright === undefined ? (
                      <MdDoNotDisturb className="yellow-c" />
                    ) : null}
                  </td>
                </tr>
              </table>
            </div>
          ) : null}
        </Modal>
        <Modal
          width={400}
          open={dDelete !== undefined}
          onCancel={() => displayDelete()}
          className="no-buttons-modal black-modal"
        >
          <h5 className="create-btn-h">Delete Org</h5>

          <div className="bx-form-brand">
            <h6>Are you sure you want to delete?</h6>
            <div className="delet-btns-h">
              <Button size="sm" onClick={() => displayDelete()} variant="light">
                Cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  deleteInvite(dDelete);
                  displayDelete();
                }}
                variant="danger"
                size="sm"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
