import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Divider, Space, Tour } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import SearchContext from "../Context/SearchContext";
import { useLocation } from "react-router-dom";
const TourOnboard = () => {
  const {
    ref0,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    ref6,
    ref7,
    ref8,
    ref9,
    ref10,
    ref11,
    organization,
    setOrganization,
    images,
    setImages,
    setTasks,
    tasks,
  } = useContext(SearchContext);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/" &&
      organization &&
      organization.onboard === "tour" &&
      organization.onboardStep === undefined
    ) {
      setOrganization({ ...organization, onboardStep: 1 });
      setOpen(true);
    }
  }, [organization, location]);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const steps = [
    {
      title: "Welcome ",
      description:
        "Welcome to ModelUI, the platform for unleashing creativity and enhancing productivity through a single interface",

      target: () => ref0.current,
    },
    {
      title: "Create",
      description:
        "This icon allows you complete tasks and generate and edit content across different mediums including video, audio, voice and images",

      target: () => ref1.current,
    },
    {
      title: "Query",
      description:
        "Here is where you give a description of the task you want to complete or asset you want to create. This time we’ll be creating a video.",

      target: () => ref2.current,
    },
    {
      title: "Imagine",
      description: "Click this to begin the task or creation",

      target: () => ref3.current,
    },
    {
      title: "Status",
      description:
        "Your asset being created will appear here with a progress bar until completed.",

      target: () => ref4.current,
    },
    {
      title: "Play Video",
      description:
        "Here is where you give a description of the video you want to create.",

      target: () => ref5.current,
    },
    {
      title: "Creation",
      description:
        "Our model can also edit video, and perform many more tasks around video, images and audio, voice.",

      target: () => ref6.current,
    },
    {
      title: "Multiple Mediums",
      description:
        "Now lets increase the complexity and create both an image and sound in the same query. Our models can handle multiple requests in the same query",

      target: () => ref7.current,
    },
    {
      title: "Results",
      description:
        "Both an image and sound were created above. Take a listen and click next",

      target: () => ref8.current,
    },
    {
      title: "Blocks",
      description:
        "We also give you the power and ability to add characters or products into assets created",

      target: () => ref9.current,
    },
    {
      title: "Characters",
      description:
        "Upload a mid shot or face image of your character, provide a name, and you can now use them in your queries.",

      target: () => ref10.current,
    },
    {
      title: "Thank You",
      description:
        "Enjoy your free trial and let us know if you need any help, have concerns or any feedback.",

      target: () => ref11.current,
    },
  ];
  const adjustState = (e) => {
    let video = {
      attachment: "Test 1",
      blocks: [],
      count: [],
      createdAt: "2023-12-13T09:19:03.419Z",
      date: "2023-12-12T13:09:37.591Z",
      favs: 0,
      file_url:
        "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/177b3e803eff80fbb47ad19f4f7e972f.mp4?generation=1702459529606439&alt=media",
      index: "pending",
      likes: 0,
      map_load: [],
      media_type: "original",
      preanimation:
        "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/21d53264d6281f00f6c169eb83f42196.mp4?generation=1702459220158410&alt=media",
      profile_picture:
        "https://storage.googleapis.com/fussydata/lc2tdvtbqrh4xzjfx2h5evdwdm.png",
      progress_bar: 40,
      quene: "progress",
      query: "runing",
      root_image:
        "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/41cda95a869150e18fc8fb64278ba6a8.png?generation=1702459165943468&alt=media",
      seed_data: "89586410",
      status: "generated",
      thumbnail: [],
      updatedAt: "2023-12-13T09:25:29.730Z",
      user_id: "64dc1de41d6e72846d0bb3f2",
      username: "Olm",
      video_duration: "6.3",
      __v: 0,
      _id: "6579770730df090d3cd9a9fa",
    };
    if (e === 2) {
      setImages({ ...images, data: [video] });
    }

    if (e === 5) {
      setImages({
        ...images,
        data: [{ ...video, progress_bar: 100, quene: "complete" }],
      });
    }
    if (e === 6) {
      setTasks({ ...tasks, activeT: "sound" });
    }
    if (e === 8) {
      let sound = {
        _id: "6593f7da8d2b5d2d83f8392a",
        meta_title: "New Sound",
        meta_description: "Create New Sound includes",
        job_type: "new_sound",
        task_id: "6593f7c38d2b5d2d83f8390a",
        progress_bar: 100,
        status: "generated",
        date: "2024-01-02T11:35:44.654Z",
        index: "pending",
        query: "i need 1 image of a dog and then a sound of birds chirping",
        quene: "complete",
        user_id: "64dc1de41d6e72846d0bb3f2",
        root_image:
          "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/4e421903e0399d1d9c514ad29b1bafa1.png?generation=1704196347006584&alt=media",
        media_type: "sound",
        video_duration: null,
        count: [],
        likes: 0,
        favs: 0,
        username: "Olm",
        profile_picture: null,
        blocks: [],
        map_load: [],
        asset_notes: [],
        revisions: [],
        thumbnail: [],
        createdAt: "2024-01-02T11:47:38.594Z",
        updatedAt: "2024-01-02T11:52:27.096Z",
        __v: 0,
        file_url:
          "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/eb7455b73722ebd4749776cf81720427.mp3?generation=1704196315551903&alt=media",
      };
      let image = {
        _id: "6593f7da8d2b5d2d83f83926",
        file_url:
          "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/f1bfdcd9b018b7b527d17dbe3c04fcc9.png?generation=1704196058374750&alt=media",
        task_id: "6593f7c38d2b5d2d83f8390a",
        progress_bar: 100,
        status: "generated",
        date: "2024-01-02T11:35:44.654Z",
        query: "1 image of a dog",
        quene: "complete",
        user_id: "64dc1de41d6e72846d0bb3f2",
        media_type: "image",
        video_duration: null,
        count: [],
        likes: 0,
        favs: 0,
        username: "Olm",
        profile_picture: null,
        blocks: [],
        map_load: [],
        asset_notes: [],
        revisions: [],
        thumbnail: [],
        createdAt: "2024-01-02T11:47:38.470Z",
        updatedAt: "2024-01-02T11:47:38.470Z",
        __v: 0,
      };
      let all = images.data;

      all[1] = image;
      all[2] = sound;
      setImages({ ...images, data: all });
    }
    if (e === 9) {
      let block = [
        {
          _id: "6580f8643e196272ef72c6c8",
          block_type: "character",
          block_name: "Terry",
          user_id: "64dc1de41d6e72846d0bb3f2",
          file_url:
            "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/242b525c72e03df50ebbad97783e642b.jpg?generation=1702951005365050&alt=media",
          tags: [],
          createdAt: "2023-12-19T01:56:52.232Z",
          updatedAt: "2023-12-20T09:01:32.496Z",
          __v: 0,
          description: "A white medium build man with black hair",
        },
      ];
      setImages({
        ...images,
        // data: data.imageList,
        blocks: block,
      });
      setTasks({ ...tasks, activeIcon: "block" });
    }
    if (e === 11) {
      setTasks({ ...tasks, activeIcon: "task" });
    }
  };
  // images.data
  return (
    <>
      {organization &&
      organization.onboard !== "complete" &&
      location.pathname === "/" ? (
        <Tour
          onFinish={() => {
            updateOrganization("onboard", "complete");
            setOrganization({ ...organization, onboard: "complete" });
            setImages({
              ...images,
              data: [],
              blocks: [],
            });
          }}
          open={organization && organization.onboard === "tour"}
          // onClose={() => setOpen(false)}
          steps={steps}
          onChange={(e) => {
            console.log(e)
            setOrganization({ ...organization, onboardStep: e });
            adjustState(e);
          }}
          on
          className="my-tour-cfx"
          duration={1000}
        />
      ) : null}
    </>
  );
};
export default TourOnboard;
