import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Header from "../../../components/Header";
import { Button } from "antd";
import SearchContext from "../../../Context/SearchContext";
import CreateBox from "./create/CreateBox";

export default function BigVideo() {
  const { wc, setWC } = useContext(SearchContext);

  return (
    <div>
      <div className="bigger-video-bx">
        <span className="play-big-text">
          <h3 className="">Title Tetsing</h3>
          <div className="button-test">
            <Button>Test</Button>
            <Button>Test</Button>
          </div>
        </span>

        {wc?.show ? (
          <div className="create-main-con-2">
           
            <CreateBox />
          </div>
        ) : null}

        <ReactPlayer
          url={
            "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/6b4334f7fb5c45196057aac660fe49f9.mp4?generation=1694522923255948&alt=media"
          }
          playing
        />
      </div>
    </div>
  );
}
