import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import Card from "./Card";

export default function Created({title}) {
  const { user } = useContext(SearchContext);
  const [generatedVID, setGeneratedVID] = useState();
  const [callApi, setCallApi] = useState(1);

  useEffect(() => {
    getVideoGenerated();
  }, [callApi]);

  const getVideoGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=original&status=generated`
      );

      // Update the state with the retrieved jobs data
      setGeneratedVID(response.data?.imageList);

      if (isThunder !== "thunder") {
        setTimeout(() => {
          setCallApi(callApi + 1);
        }, 10000);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 5,
    initialSlide: 0,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div >
      <h3>{title}</h3>
      <Slider {...settings}>
        {generatedVID &&
          generatedVID.map((g, gi) => {
            return <Card allVideos={generatedVID} setALlVideos={setGeneratedVID} key={gi} data={g} />;
          })}
      </Slider>
    </div>
  );
}
