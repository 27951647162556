import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";

import SearchContext from "../../Context/SearchContext";
import Jobs from "./tabs/Jobs";
import Content from "./tabs/Content";
import Create from "./tabs/Create";
import Profile from "./tabs/Profile";

import DevelopersHub from "./tabs/DevelopersHub";
import Character from "./tabs/Character";
export default function Console() {
  const { setassist, assist } = useContext(SearchContext);
  const [activeTab, setActiveTab] = useState("Create");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="">
      <Header />
      <div className="container ">
        <div className="tabs-main-bx ">
          {/* <Segmented defaultValue="Library" options={[ 'Weekly','Library', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']} /> */}
          <div className="all-gen-video-txt">
            <button
              onClick={() => handleTabClick("Content")}
              className={`button-tab ${
                activeTab === "Content" ? "active-btn-t" : ""
              }`}
            >
              Library
            </button>
            <button
              onClick={() => handleTabClick("Character")}
              className={`button-tab ${
                activeTab === "Character" ? "active-btn-t" : ""
              }`}
            >
              Character
            </button>
            <button
              onClick={() => handleTabClick("Create")}
              className={`button-tab ${
                activeTab === "Create" ? "active-btn-t" : ""
              }`}
            >
              Imagine
            </button>
            <button
              onClick={() => handleTabClick("Jobs")}
              className={`button-tab ${
                activeTab === "Jobs" ? "active-btn-t" : ""
              }`}
            >
              History
            </button>
            <button
              onClick={() => handleTabClick("Profile")}
              className={`button-tab ${
                activeTab === "Profile" ? "active-btn-t" : ""
              }`}
            >
              Profile
            </button>
            <button
              onClick={() => handleTabClick("Developer Hub")}
              className={`button-tab ${
                activeTab === "Developer Hub" ? "active-btn-t" : ""
              }`}
            >
              Developer
            </button>
          </div>
     
          {activeTab === "Content" ? (
            <div className="business-tools-btn2">
              <button
                onClick={() => setassist({ ...assist, isUpload: true })}
                className="custom-button"
                style={{ marginLeft: "5px", height: "36px" }}
              >
                Upload
              </button>
            </div>
          ) : null}
          {activeTab === "Create" ? (
            <div className="business-tools-btn2 hide-mobile">
              <button
                className="custom-button"
                style={{ marginLeft: "5px", height: "36px" }}
              >
                Get Ideas
              </button>
            </div>
          ) : null}
          <div className="business-tools-btn">
            <a target="blank" href="https://platform.olm.ai/">
              <button className="custom-button" style={{ marginRight: "5px" }}>
                Business Tools
              </button>
            </a>
          </div>

          {activeTab === "Content" && (
            <div className="content-tab">
              <Content />
            </div>
          )}
          {activeTab === "Profile" && <Profile />}
          {activeTab === "Developer Hub" && <DevelopersHub />}
          {activeTab === "Create" && <Create />}
          {activeTab === "Character" && <Character />}
          {activeTab === "Jobs" && (
            <div className="jobs-tab">
              {/* Jobs Tab Content Goes Here */}

              <Jobs />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
