import React, { useContext } from "react";
import WcHeader from "./components/WcHeader";
import BigVideo from "./components/BigVideo";
import "./wcStyle.css";
import Header from "../../components/Header";
import Created from "./components/Created";
import Options from "./components/Options";
import SearchContext from "../../Context/SearchContext";
import CreateBox from "./components/create/CreateBox";
export default function WatchCreate() {
  const { wc, setWC } = useContext(SearchContext);

  return (
    <div className="watch-create-container">
      <WcHeader />
      {/* <Header /> */}
      <BigVideo />

      <div className="created-slider-bx">
        <Options />
        <Created title="Created" />
      </div>
    </div>
  );
}
