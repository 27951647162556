import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchContext from "../Context/SearchContext";
import { Alert, Button, Dropdown, Modal, Tag } from "antd";
// import logo from "../images/fx-y.png";

import logo from "../images/nlogo-white.png";

import CreateImagine from "./CreateImagine";
import Imagine from "../pages/storyProject/components/Imagine";
import { TbProgressBolt } from "react-icons/tb";
import HeaderCreate from "../pages/editor/components/contents/Tasks/HeaderCreate";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import MyAccount from "./account/components/MyAccount";
import MyOrganization from "./account/components/MyOrganization";
import MyTeam from "./team/MyTeam";
import Pricing from "./onboard/Pricing";
import Credit from "./Credit";
import HeaderCreateShare from "../pages/editor/components/contents/Tasks/HeaderCreateShare";
import AddWorkspace from "./AddWorkspace";
export default function Header({
  showCreate,
  windowWidth,
  generated,
  generatedVID,
  setGeneratedVID,
  search,
  setSearch,
  displayCreate,
  getVideos22,
  showP,
  showCreateShare,
}) {
  const navigate = useNavigate();
  const {
    user,
    setUser,
    setOrganization,
    organization,
    resetAll,
    videosFeed,
    setVideosFeed,
    setTasks,
    tasks,
  } = useContext(SearchContext);
  const location = useLocation();
  const [path, setPath] = useState("/");
  const [display, setDisplay] = useState();

  useEffect(() => {
    if (location) {
      setPath(location.pathname);
    }
  }, [location]);

  const items = [
    {
      key: "1",
      label: <span onClick={() => setDisplay("account")}>Account</span>,
    },
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];

  const logout = () => {
    window.localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
    setUser();
    setOrganization();
    resetAll();
  };

  const getVideos = (v) => {
    const obj = { query: v };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setVideosFeed([...[], ...data]);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
  };

  const changeSearch = (v) => {
    setSearch(v);
    if (v.trim().length) {
      getVideos(v.trim());
    } else {
      getVideos2();
    }
  };

  const getVideos2 = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/image/watch?user_id=${user && user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setVideosFeed([...[], ...data.imageList]);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
  };
  const isWatchPage = path === "/watch";
  const isStudioPage = path === "/studio";
  const isHomePage = path === "/";
  const isLoggedIn = user && user.name;

  return (
    <div className="header-container">
      <nav
        style={{ background: isLoggedIn ? "#0d0d0d" : "black" }}
        className="navbar navbar-expand-lg navbar-light "
      >
        <div className="container-fluid my-container-flui">
          {/* Make the logo a link to the home page */}
          <Link
            onClick={() =>
              setTasks({
                ...tasks,
                activeIcon: "task",
                changeHome: tasks.changeHome ? tasks.changeHome + 1 : 1,
                stopGet: undefined,
              })
            }
            to="/"
          >
            <a className="navbar-brand" href="#">
              <img
                style={{
                  margin: "-5px 0px",
                  width: isLoggedIn ? "40px" : "50px",
                }}
                className="logo-img-bc"
                src={logo}
                alt=""
              />
            </a>
          </Link>

          <div className=" navbar-my-main">
            <span />
            {showCreate && windowWidth && windowWidth > 850 ? (
              <div className="header-c-desk">
                <HeaderCreate />
              </div>
            ) : null}
            {showCreateShare && windowWidth && windowWidth > 850 ? (
              <div className="header-c-desk">
                <HeaderCreateShare />
              </div>
            ) : null}
            <div className="header-right-c">
              {isLoggedIn ? <AddWorkspace /> : null}
              {organization ? <Credit /> : null}
              {isLoggedIn ? (
                <div className="roadm-t-div" style={{ marginLeft: "20px" }}>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                  >
                    <span
                      style={{ display: "flex", alignItems: "center" }}
                      className="btn-appr signop-btn"
                    >
                      {user.name} <MdOutlineKeyboardArrowDown />
                    </span>
                  </Dropdown>
                </div>
              ) : (
                <form className="d-flex so-login-links">
                  {/* <b onClick={() => navigate("/pricing")}>Pricing</b>
                  <b onClick={() => navigate("/company")}>Company</b> */}
                  <b className="hide-mobile" onClick={() => navigate("/login")}>Login</b>
                  <b className="hide-mobile" onClick={() => navigate("/signup")}>Sign Up</b>
                  <b className="hide-desktop" onClick={() => navigate("/login")}>Get Started</b>

                </form>
              )}
            </div>
          </div>
        </div>
      </nav>

      <Modal
        className={`no-buttons-modal black-modal`}
        open={display !== undefined}
        onCancel={() => setDisplay()}
        width={900}
      >
        {/* <h5 className="create-btn-h">Hi {user && user.name}</h5> */}

        <div className="bx-form-brand">
          <div style={{ marginBottom: "10px" }} className="bx-main-sel-prf">
            <Tag
              onClick={() => setDisplay("account")}
              className={`editor-tag-tab ${
                display === "account" ? "  active-tg-wdt" : ""
              }`}
            >
              <span>Account {display === "account" ? <TiTick /> : ""}</span>
            </Tag>
            <Tag
              onClick={() => setDisplay("Organization")}
              className={`editor-tag-tab ${
                display === "Organization" ? "  active-tg-wdt" : ""
              }`}
            >
              <span>
                Organization {display === "Organization" ? <TiTick /> : ""}
              </span>
            </Tag>
            <Tag
              onClick={() => setDisplay("Team")}
              className={`editor-tag-tab ${
                display === "Team" ? "  active-tg-wdt" : ""
              }`}
            >
              <span>Team {display === "Team" ? <TiTick /> : ""}</span>
            </Tag>
            <Tag
              onClick={() => setDisplay("Billing")}
              className={`editor-tag-tab ${
                display === "Billing" ? "  active-tg-wdt" : ""
              }`}
            >
              <span>Billing {display === "Billing" ? <TiTick /> : ""}</span>
            </Tag>
          </div>
          {display === "account" ? <MyAccount /> : null}
          {display === "Organization" ? <MyOrganization /> : null}
          {display === "Team" ? <MyTeam /> : null}
          {display === "Billing" ? (
            <div className="bx-cls-bmbs">
              <div
                className="no-heigh-div-c full-h-bx-a"
                style={{
                  width: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop:"20px"
                }}
              >
                <a
                  href="https://billing.stripe.com/p/login/00gfZz7uLeaH50Y288"
                  target="_blank"
                  style={{textDecoration:"none"}}
                >
                  <Alert
                    message="Billing"
                    description={
                      <div>
                        <div>
                          Click here to access the billing portal. Securely
                          managed by Stripe. You can manage your account's
                          billing and payment information, change your payment
                          method or billing address.
                        </div>
                      </div>
                    }
                    type="info"
                    showIcon
                    className="brand-alert bg-txt-a"
                  />
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
}
