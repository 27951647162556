import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Collapse,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import { FaArrowRight, FaPlay } from "react-icons/fa";
import ReactPlayer from "react-player";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { TiTickOutline } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SearchContext from "../../../Context/SearchContext";
import GreenTick from "../../../components/GreenTick";
import { useNavigate } from "react-router-dom";
import TagsGroup from "../../../components/TagsGroup";

export default function Jobs() {
  const navigate = useNavigate();
  const { user, setDisplayVid } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(1);
  const [callApi2, setCallApi2] = useState(1);
  const [loading2, setLoading2] = useState(true);
  const [generated, setGenerated] = useState({ pageNo: 1 });
  const [video, setVideo] = useState();

  useEffect(() => {
    getVideos();
  }, [callApi]);

  useEffect(() => {
    if (callApi2 > 1 && generated.pageNo === 1) {
      getVideos();
    }
  }, [callApi2]);

  const getVideos = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        generated.pageNo
      }&limit=6&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=original&status=generated`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (generated.pageNo === 1) {
          setTimeout(() => {
            setCallApi2(callApi2 + 1);
          }, 20000);
        }
        if (data && data.isSuccess) {
          setGenerated({
            ...generated,
            videos: data.imageList,
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };
  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let all = generated.videos.map((m) =>
            m._id === id ? response.imageData : m
          );

          setGenerated({
            ...generated,
            videos: all,
          });
        } else {
          message.error("somehing went wrong");
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "Query",
      key: "query",
      render: (_, record) => (
        <Space size="middle">
          <span>{record.query}</span>
        </Space>
      ),
    },

    {
      title: "Created At",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <span>{moment(record.createdAt).fromNow()}</span>
        </Space>
      ),
    },
    {
      title: "",
      key: "v",
      render: (_, record) => (
        <Space size="middle">
          {record.quene === "complete" ? (
            <Button
              size="small"
              onClick={() => setVideo(record)}
              type="primary"
            >
              <FaPlay />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    // {
    //   title: "Display",
    //   key: "v",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Select
    //         bordered={false}
    //         placeholder="Display"
    //         style={{ width: 90 }} // Set the desired width in pixels
    //         value={record.display ? record.display : "private"}

    //         onChange={(value) => {changeValue(value)}}
    //       >
    //         <Option value={"private"}>Private</Option>
    //         <Option value={"public"}>Public</Option>
    //       </Select>
    //     </Space>
    //   ),
    // },

    {
      title: "Quene",
      key: "quene",
      render: (_, record) => (
        <Space size="middle">
          <span className="icons-bx-sd">
            {record.quene === "complete" ? (
              <GreenTick width="22px" height={"22px"} />
            ) : record.quene === "progress" ? (
              <Spin indicator={antIcon} />
            ) : (
              <AiOutlineCloseCircle className="red-tick-t" />
            )}
          </span>
        </Space>
      ),
    },
    {
      title: "",
      key: "arow",
      render: (_, record) => (
        <Space
          onClick={() => {
            setDisplayVid(record);
            navigate(`/video/${record._id}`);
          }}
          size="middle"
        >
          <span className="icons-bx-sd">
            <FaArrowRight />
          </span>
        </Space>
      ),
    },
  ];

  const copyToClipboard = () => {
    const textToCopy = `${window.location.origin}/share/${video._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  return (
    <>
      {loading2 ? (
        <div className="loading-full-page2">
          <div className="spiner-main-box">
            <div className="spinner"></div> <FaPlay />
          </div>
        </div>
      ) : (
        <div>
          {generated.videos && generated.videos.length ? (
            <Table
              pagination={{
                pageSize: 8,
                current: generated.pageNo,
                total: generated.totalPages * 6,
                onChange: (v) => {
                  setGenerated({ ...generated, pageNo: v });
                  setCallApi(callApi + 1);
                },
                size: "small",
                showSizeChanger: false,
              }}
              dataSource={generated.videos}
              columns={columns}
              className="table-jobs"
            />
          ) : (
            <div className="no-heigh-div-c ">
              <div className="card21">
                <div className="card21-img"></div>
                <div className="card21-info">
                  <p className="text21-body">Video Generation Jobs</p>
                  <p className="txt-card-21">
                    On this tab, you'll find a history of all your AI powered
                    video generations that you've ever done on Olm
                  </p>
                </div>
              </div>
            </div>
          )}
          <Modal
            open={video !== undefined}
            onCancel={() => setVideo()}
            className="no-buttons-modal "
          >
            {video ? (
              <div className="video-container radius-video">
                <ReactPlayer
                  controls
                  url={video.file_url}
                  playing
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </div>
            ) : null}
            <Collapse defaultActiveKey={""} accordion>
              <Collapse.Panel header="Tags" key="count">
                <TagsGroup
                  tags={video && video.count ? video.count : []}
                  color="blue"
                  onTagChange={(v) => {
                    updateValue({ count: v }, video?._id);

                    setVideo({
                      ...video,
                      count: v,
                    });
                  }}
                />
              </Collapse.Panel>
            </Collapse>
            <Button
              className="custom-button"
              style={{ marginRight: "10px" }}
              onClick={copyToClipboard}
            >
              Share
            </Button>
          </Modal>
        </div>
      )}
    </>
  );
}
