import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import ReactPlayer from "react-player";
import SearchContext from "../../Context/SearchContext";
import { Button, Modal, message } from "antd";
import { FaPlay, FaStar } from "react-icons/fa";
import Paragraph from "antd/es/typography/Paragraph";
import Avatar from "react-avatar";

export default function Profile() {
  const [profile, setProfile] = useState();
  const { user } = useContext(SearchContext);
  const [video, setVideo] = useState();

  useEffect(() => {
    getVideos(window.location.pathname.slice(9));
  }, []);

  const getVideos = (username) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image/user?username=${username}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setProfile(data.imageList);
        } else {
        }
      });
  };

  const copyToClipboard = () => {
    const textToCopy = `${window.location.origin}/share/${video._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };

  const changeVideo = (row) => {
    row.likes = video.is_liked ? row.likes - 1 : row.likes + 1;
    row.is_liked = !row.is_liked;
    setVideo(video);

    const all = profile.map((m) => (m._id === row._id ? row : m));
    setProfile(all);
  };
  const changeFav = (row) => {
    row.favs = video.is_fav ? row.favs - 1 : row.favs + 1;
    row.is_fav = !row.is_fav;
    setVideo(video);

    const all = profile.map((m) => (m._id === row._id ? row : m));
    setProfile(all);
  };

  const updateLikes = (obj, id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/like`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          //    setDisplayVid(response.imageData)
        } else {
          // message.error("somehing went wrong");
        }
      });
  };

  const updateFav = (obj, id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/fav`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          //    setDisplayVid(response.imageData)
        } else {
          // message.error("somehing went wrong");
        }
      });
  };

  return (
    <div className="profile-page">
      <Header />
      <div className="container profile-mn my-5">
        {profile && profile.length ? (
          <div className="profile-feed-a">
            <div className="profile-name">
              <h5>
                {profile[0].profile_picture ? (
                  <img alt="" src={profile[0].profile_picture} />
                ) : (
                  <Avatar
                    style={{ margin: "0px 5px" }}
                    round
                    name={profile[0].username.toUpperCase()}
                    size="40"
                    color="rgb(33, 0, 85)"
                  />
                )}
                {profile[0].username}
              </h5>
            </div>
            <div className="row">
              {profile.map((p, pi) => {
                return (
                  <div key={pi} className="col-lg-4 col-md-6 col-sm-6">
                    <div className="play-feed-profile">
                      <div>
                        <img src={p.frame} alt="" />
                      </div>
                      {/* <div className="video-container">
                        <ReactPlayer
                         
                          url={p.file_url}
                          
                        />
                      </div> */}
                      <div className="icons-bx-ri-vd2">
                        <span className="play-blur-glow">
                          <Button
                            onClick={() => setVideo(p)}
                            size="large"
                            className="large-play-btn"
                          >
                            <FaPlay className="r-atc-incs" />
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        width={800}
        className="no-buttons-modal no-close-model black-modal "
        open={video !== undefined}
        onCancel={() => setVideo()}
      >
        {video ? (
          <div>
            <div className="video-container radius-video">
              <ReactPlayer controls url={video.file_url} playing  config={{
                file: {
                  attributes: {
                    controlsList: "nodownload", 
                  },
                },
              }}/>
            </div>
            <div>
              <div className="likes-fav-video">
                <div>
                  <div className="likes-area">
                    <span className="heart-icon">
                      <div title="Like" className="heart-container">
                        <input
                          id="Give-It-An-Id"
                          className="checkbox"
                          onClick={() => {
                            changeVideo(video);
                            updateLikes(
                              {
                                image_id: video._id,
                                username: user.username,
                              },
                              video._id
                            );
                          }}
                          checked={video.is_liked}
                          type="checkbox"
                          readOnly
                        />
                        <div className="svg-container">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg-outline"
                            viewBox="0 0 24 24"
                          >
                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg-filled"
                            viewBox="0 0 24 24"
                          >
                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="100"
                            width="100"
                            className="svg-celebrate"
                          >
                            <polygon points="10,10 20,20"></polygon>
                            <polygon points="10,50 20,50"></polygon>
                            <polygon points="20,80 30,70"></polygon>
                            <polygon points="90,10 80,20"></polygon>
                            <polygon points="90,50 80,50"></polygon>
                            <polygon points="80,80 70,70"></polygon>
                          </svg>
                        </div>
                      </div>

                      <b>{video.likes ? video.likes : 0}</b>
                    </span>
                    <span className="star-div">
                      <FaStar
                        color={video.is_fav ? "#c850c0" : "white"}
                        onClick={() => {
                          changeFav(video);
                          updateFav(
                            {
                              image_id: video._id,
                              username: user.username,
                            },
                            video._id
                          );
                        }}
                      />
                      <b>{video.favs}</b>
                    </span>
                  </div>
                </div>
                <b className="white-color-paragraph">
                  {" "}
                  <Paragraph
                    ellipsis={{
                      rows: 1,
                      expandable: false,
                      symbol: "",
                    }}
                  >
                    {video.query}
                  </Paragraph>
                </b>
              </div>
              <div className="reimagine-b-mai">
                <Button
                  className="custom-button"
                  style={{ marginRight: "10px" }}
                  onClick={copyToClipboard}
                >
                  Share
                </Button>
                {/* <Button
                  className="custom-button"
                  onClick={() => setReimagine(video)}
                >
                  Reimagine
                </Button> */}
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
