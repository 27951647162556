import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button } from "antd";

export default function CreateWc() {
  const { wc, setWC } = useContext(SearchContext);

  return (
    <div>
      <Button
        onClick={() => {
          setWC({ ...wc, show: true });
        }}
      >
        CreateWc
      </Button>
    </div>
  );
}
