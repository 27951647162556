import { Button, Col, Modal, Row, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../../../Context/SearchContext";
import { AiOutlineQuestionCircle } from "react-icons/ai";
export default function ConnectAlerts({
  display,
  cardimg,
  t1,
  t2,
  p1,
  dynamicLink,
  p2,
  t3,
  p3,
  isAugment,
  isObserve,
  isShield,
  is4th,
}) {
  const { user, listData, setListData, setOrganization, organization } =
    useContext(SearchContext);
  const [connect, setConnect] = useState();
  const updateOrganization = (obj, id, g) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    let keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
      formData.append(keys[i], obj[keys[i]]);
    }

    // if (UploadedFile.length) {
    //   formData.append("image", UploadedFile[0].originFileObj);
    // }
    // if (UploadedFile2.length) {
    //   formData.append("fonts", UploadedFile2[0].originFileObj);
    // }
    const requestOptions = {
      method: "PUT",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setOrganization(response.organizationData);
        }
      });
  };
  return (
    <Row className="connect-top-con">
      <Col
        xs={{ span: 24 }}
        sm={{ span: is4th ? 12 : 24 }}
        lg={{ span: is4th ? 6 : 8 }}
        md={{ span: is4th ? 6 : 8 }}
      >
        <img className="img-card-cont" src={cardimg} alt="" />
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: is4th ? 12 : 24 }}
        lg={{ span: is4th ? 6 : 8 }}
        md={{ span: is4th ? 6 : 8 }}
      >
        <div style={{ height: "100%" }} className="no-heigh-div-c">
          <div style={{ paddingTop: "10px" }} className="card21">
            <div className="card21-info">
              <p className="text21-body">{t1}</p>
              <p className="txt-card-21">{p1}</p>
              <div className="button-area-card">
                <a href={dynamicLink} target="_blank" rel="noopener noreferrer">
                  {/* Use your dynamic link variable here */}
                  <Button
                    className={`editor-tag-tab active-tg-wdt`}
                    size="small"
                  >
                    Learn More
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: is4th ? 12 : 24 }}
        lg={{ span: is4th ? 6 : 8 }}
        md={{ span: is4th ? 6 : 8 }}
      >
        <div style={{ height: "100%" }} className="no-heigh-div-c ">
          {organization ? (
            <div style={{ paddingTop: "10px" }} className="card21">
              <div className="card21-info">
                <p className="text21-body">{t2}</p>
                <p className="txt-card-21">{p2}</p>
                <div className="button-area-card">
                  {display ? (
                    <Button
                      className={
                        organization.connect ? "green-button" : "red-button"
                      }
                      onClick={() => setConnect(true)}
                      size="small"
                    >
                      {organization.connect ? "Turn Off" : "Turn On"}
                    </Button>
                  ) : isAugment ? (
                    <div>
                      <Button
                        className={
                          organization.augment ? "green-button" : "red-button"
                        }
                        onClick={() => {
                          updateOrganization({
                            augment: organization.augment ? false : true,
                          });
                          setOrganization({
                            ...organization,
                            augment: organization.augment ? false : true,
                          });
                        }}
                        size="small"
                      >
                        {organization.augment ? "Turn Off" : "Turn On"}
                      </Button>
                      <Tooltip title="AUGMENT">
                        <AiOutlineQuestionCircle
                          style={{
                            cursor: "pointer",
                            color: "white",
                            marginLeft: "10px",
                          }}
                        />{" "}
                      </Tooltip>{" "}
                    </div>
                  ) : isObserve || isShield ? (
                    <div className="btn-sh-bcn">
                      <div style={{ marginBottom: "10px" }}>
                        <b style={{ marginRight: "25px" }}>Shield :</b>
                        <Button
                          className={
                            organization.shield ? "green-button" : "red-button"
                          }
                          onClick={() => {
                            updateOrganization({
                              shield: organization.shield ? false : true,
                            });
                            setOrganization({
                              ...organization,
                              shield: organization.shield ? false : true,
                            });
                          }}
                          size="small"
                        >
                          {organization.shield ? "Turn Off" : "Turn On"}
                        </Button>
                        <Tooltip title="shield">
                          <AiOutlineQuestionCircle
                            style={{
                              cursor: "pointer",
                              color: "white",
                              marginLeft: "10px",
                            }}
                          />{" "}
                        </Tooltip>{" "}
                      </div>
                    </div>
                  ) : (
                    <div style={{ height: "24px" }}></div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <Modal
            width={400}
            open={connect !== undefined}
            onCancel={() => setConnect()}
            className="no-buttons-modal black-modal"
          >
            <h5 className="create-btn-h">
              {organization?.connect ? "Disconnect" : "Connection"}
            </h5>

            <div className="bx-form-brand">
              <h6>
                Are you sure you want to
                {organization?.connect ? "Disconnect" : "Connect"}?
              </h6>
              <div className="delet-btns-h">
                <Button size="sm" onClick={() => setConnect()} variant="light">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    updateOrganization({
                      connect: organization.connect ? false : true,
                    });
                    setConnect();
                  }}
                  variant="danger"
                  size="sm"
                >
                  {organization?.connect ? "Disconnect" : "Connect"}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </Col>
      {is4th ? (
        <Col
          xs={{ span: 24 }}
          sm={{ span: is4th ? 12 : 24 }}
          lg={{ span: is4th ? 6 : 8 }}
          md={{ span: is4th ? 6 : 8 }}
        >
          {" "}
          <div style={{ height: "100%" }} className="no-heigh-div-c ">
            <div style={{ paddingTop: "10px" }} className="card21">
              <div className="card21-info">
                <p className="text21-body">{t3}</p>
                <p className="txt-card-21">{p3}</p>
                <div className="button-area-card">
                  <a href="https://docs.openfxs.com/" target="blank">
                    <Button
                      className={`editor-tag-tab active-tg-wdt`}
                      size="small"
                    >
                      Contact Us
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ) : null}
    </Row>
  );
}
