import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Input,
  Progress,
  Row,
  Spin,
  Select,
  Collapse,
  Slider,
  Pagination,
  Tag,
  Popover,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { MdClose, MdKeyboardBackspace, MdKeyboardVoice } from "react-icons/md";
import Paragraph from "antd/lib/typography/Paragraph";
import {
  FaArrowDown,
  FaArrowRight,
  FaAudioDescription,
  FaCode,
  FaDownload,
  FaFileAudio,
  FaImage,
  FaInfo,
  FaMapMarkerAlt,
  FaMinus,
  FaMusic,
  FaPlay,
  FaQuestion,
  FaShare,
  FaUpload,
} from "react-icons/fa";
import { LiaShareSolid } from "react-icons/lia";
import { TbArrowRight, TbShare3 } from "react-icons/tb";
import { LoadingOutlined } from "@ant-design/icons";

//   import UploadAssets from "./UploadAssets";
//   import ReImagineButton from "./ReImagineButton";
import SearchContext from "../../../../../Context/SearchContext";
import ReactAudioPlayer from "react-audio-player";
import { BsFiletypeCsv, BsInfo, BsQuestion, BsSoundwave } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdAttach } from "react-icons/io";
import DetailModal from "./DetailModal";
import {
  AiOutlineDownload,
  AiOutlineQuestionCircle,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { CiTextAlignCenter } from "react-icons/ci";

import UpScale from "./UpScale";
import UpScaleImage from "./UpScaleImage";
import Map from "./Map";
import ConnectAlerts from "./ConnectAlerts";
import cardImg from "../../../../../images/18.png";
import ActiveConnections from "./ActiveConnections";
import OpenCsvAsset from "./OpenCsvAsset";
import OpenCodeAsset from "./OpenCodeAsset";
import GenerateLoadingVideo from "./GenerateLoadingVideo";
import AssetChat from "./AssetChat";

export default function Assets({ loading2, setLoading2 }) {
  const {
    images,
    setImages,
    setTasks,
    tasks,
    ref4,
    organization,
    ref5,
    ref6,
    ref9,
    ref8,
    loginChange,
    user,
    setCallApi,
    callApi,
  } = useContext(SearchContext);
  const [active, setActive] = useState();
  const [waiting, setWaiting] = useState();
  const [check, setChek] = useState();
  const [show, setShow] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (check === waiting) {
      setActive(waiting);
    }
  }, [check]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = images.data.filter((p) => p._id !== row._id);
          setImages({ ...images, data: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  useEffect(() => {
    // if (organization?.onboard === "complete") {
      getImages();
    // }
  }, [callApi, tasks.callGenerate]);

  const getImages = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        images.pageNo
      }&limit=20&sort_by=${-1}&user_id=${user ? user.user_id : "map"}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (tasks.stopGet === undefined) {
          if (data && data.isSuccess) {
            let all =
              images.pageNo === 1
                ? data.imageList
                : images.data.concat(data.imageList);
            setImages({
              ...images,
              // data: data.imageList,
              data: all,
              totalPages: data.totalPages,
            });
            if (images.pageNo === 1) {
              setTimeout(() => {
                setCallApi(callApi + 1);
              }, 5000);
            }
          } else {
            message.warning("Something went wrong");
          }
        }
      });
  };
  const adjustClick = (m) => {
    let attachment = images.attachment ? images.attachment : [];
    if (attachment.filter((f) => f === m.file_url).length) {
      attachment = attachment.filter((f) => f !== m.file_url);
    } else {
      attachment.push(m.file_url);
    }
    setImages({
      ...images,
      attachment: attachment,
      imageId:
        m.media_type === "image"
          ? m._id === images.imageId?._id
            ? undefined
            : m
          : images.imageId,
      videoId:
        m.media_type === "original"
          ? m._id === images.videoId?._id
            ? undefined
            : m
          : images.videoId,
      soundId:
        m.media_type === "sound"
          ? m._id === images.soundId?._id
            ? undefined
            : m
          : images.soundId,
      musicId:
        m.media_type === "music"
          ? m._id === images.musicId?._id
            ? undefined
            : m
          : images.musicId,
      voiceId:
        m.media_type === "voice"
          ? m._id === images.voiceId?._id
            ? undefined
            : m
          : images.voiceId,
    });
  };
  const topLeft = (m) => {
    return (
      <div className={`top-left-popup `}>
        <Tooltip title="Attach">
          <b className="show-hover">
            <IoMdAttach
              onClick={() => {
                adjustClick(m);
              }}
              style={{
                background:
                  (images.attachment &&
                    images.attachment.filter((f) => f === m.file_url).length) ||
                  images.imageId?._id === m._id ||
                  images.videoId?._id === m._id ||
                  images.soundId?._id === m._id ||
                  images.musicId?._id === m._id ||
                  images.voiceId?._id === m._id
                    ? "#BC1823"
                    : "black",
                position: "relative",
              }}
              className="circular-icon hover"
            />
          </b>
        </Tooltip>
      </div>
    );
  };
  const copyToClipboard = (m) => {
    const textToCopy = `${window.location.origin}/share/${m._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  const topRight = (m) => {
    return (
      <div className={`top-right-popup `}>
        {/* {m.media_type === "original" ? <UpScale m={m} /> : null} */}
        {/* {m.media_type === "image" ? <UpScaleImage m={m} /> : null} */}
        <a href={m.file_url} download>
          <Tooltip title="Download">
            <b style={{ marginLeft: "5px" }} className="show-hover">
              <AiOutlineDownload />
            </b>
          </Tooltip>
        </a>
        <Tooltip title="Info">
          <b
            style={{ marginLeft: "5px" }}
            onClick={() => copyToClipboard(m)}
            className="show-hover"
          >
            <AiOutlineShareAlt />
          </b>
        </Tooltip>
        <Tooltip title="Info">
          <b onClick={() => setShow(m)} className="show-hover">
            <BsInfo
              style={{
                position: "relative",
              }}
              className="attach-popup-c"
            />
          </b>
        </Tooltip>
        <b
          onClick={() => {
            setTasks({ ...tasks, activeGoal: m });
            navigate(`/asset/${m._id}`);
          }}
          style={{ marginLeft: "5px" }}
          className="show-hover"
        >
          <TbArrowRight />
        </b>
      </div>
    );
  };
  const bottomLeft = (m) => {
    return (
      <div className={`bottom-left-popup `}>
        <Popconfirm
          title="Delete this asset"
          onConfirm={() => deleteTask(m)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
          placement="right"
          trigger={"click"}
        >
          <b className="show-hover">
            <MdClose
              style={{
                position: "relative",
                zIndex: 120,
              }}
              className="circular-icon hover"
            />
          </b>
        </Popconfirm>
        <AssetChat row={m} />
      </div>
    );
  };
  const bottomRight = (m) => {
    return (
      <div className="icons-bx-ri-vd2s">
        <Button onClick={() => setActive(m._id)} size="large">
          <FaPlay style={{ marginLeft: "2px" }} className="r-atc-incs" />
        </Button>
      </div>
    );
  };
  const topCenter = (m) => {
    return m.quene === "complete" &&
      m.job_type === "variation" &&
      m.meta_title ? (
      <div
        style={{ justifyContent: "flex-end" }}
        className="top-right-popup hide-hover progress-card-title"
      >
        <span className="transparent-meta-title">{m.meta_title}</span>
      </div>
    ) : null;
  };

  const codeCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo value={m.progress_bar} id={m._id} />
        ) : (
          <div className="video-thumbnail-imgg">
            <img src={m.file_url} alt="" />

            {m.quene === "complete" ? topLeft(m) : null}
            {m.quene === "complete" || m.quene === "failed"
              ? bottomLeft(m)
              : null}
            {m.quene === "complete" ? (
              <div className={`top-right-popup `}>
                <OpenCodeAsset row={m} />
              </div>
            ) : null}
            <div className="icons-bx-ri-vd2s">
              <Button
                //   onClick={() => setPlay(m._id)}
                size="large"
              >
                <FaCode className="r-atc-incs" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };
  const csvCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {" "}
        {m.quene === "progress" ? (
          <GenerateLoadingVideo value={m.progress_bar} id={m._id} />
        ) : (
          <div className="video-thumbnail-imgg">
            <img src={m.root_image} alt="" />

            {m.quene === "complete" ? topLeft(m) : null}
            {m.quene === "complete" || m.quene === "failed"
              ? bottomLeft(m)
              : null}
            {m.quene === "complete" ? (
              <div className={`top-right-popup `}>
                <OpenCsvAsset row={m} />
              </div>
            ) : null}
            <div className="icons-bx-ri-vd2s">
              <Button
                //   onClick={() => setPlay(m._id)}
                size="large"
              >
                <BsFiletypeCsv className="r-atc-incs" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };
  const videoCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo value={m.progress_bar} id={m._id} />
        ) : (
          <div className="video-thumbnail-imgg">
            {m._id === active || organization?.onboardStep === 6 ? (
              <div
                style={{ position: "relative" }}
                className="video-container video-container-round"
              >
                <ReactPlayer url={m.file_url} controls />
              </div>
            ) : (
              <img src={m.root_image} alt="" />
            )}
            {topCenter(m)}
            {m.quene === "complete" ? topLeft(m) : null}
            {m.quene === "complete" ? topRight(m) : null}
            {m._id === active || organization?.onboardStep === 6 ? null : (
              <>
                {bottomRight(m)}
                {m.quene === "complete" || m.quene === "failed"
                  ? bottomLeft(m)
                  : null}
              </>
            )}
          </div>
        )}
      </div>
    );
  };
  const AudioCard = (m) => {
    return m._id === active || organization?.onboardStep === 10 ? (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        <div className="video-thumbnail-imgg video-thumbnail-imgg2s">
          <img src={m.root_image} alt="" />
          {topCenter(m)}

          <div
            onClick={() => {
              setTasks({ ...tasks, activeGoal: m });
              navigate(`/asset/${m._id}`);
            }}
            className=" audio-player-mn-bx"
            // ref={ref10}
          >
            {/* <h5>{m.media_type}</h5> */}
            <ReactAudioPlayer src={m.file_url} controls />
            {/* <h6>{m.query}</h6> */}
          </div>
        </div>
      </div>
    ) : (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo value={m.progress_bar} id={m._id} />
        ) : (
          <div className="video-thumbnail-imgg">
            <img src={m.root_image} alt="" />
            {m.quene === "complete" ? topRight(m) : null}
            {m.quene === "complete" ? topLeft(m) : null}
            {m.quene === "complete" || m.quene === "failed"
              ? bottomLeft(m)
              : null}

            {m.quene === "progress" ? (
              <div className="bottom-left-popup ">
                <a href={`https://${m.meta_link}`} target="`blank">
                  <Tag className={`editor-tag-tab  active-tg-wdt`}>
                    {" "}
                    Learn More
                  </Tag>
                </a>
              </div>
            ) : null}
            <div className="icons-bx-ri-vd2s">
              <Button onClick={() => setActive(m._id)} size="large">
                {m.media_type === "music" ? (
                  <FaMusic className="r-atc-incs" />
                ) : null}
                {m.media_type === "sound" ? (
                  <BsSoundwave className="r-atc-incs" />
                ) : null}
                {m.media_type === "voice" ? (
                  <MdKeyboardVoice className="r-atc-incs" />
                ) : null}
              </Button>
            </div>

            {m.quene === "failed" ? (
              <div className="progress-bc failed-bcv">
                <Tag color="rgb(134, 0, 0)">Failed</Tag>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  const textCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo value={m.progress_bar} id={m._id} />
        ) : (
          <div className="video-thumbnail-imgg">
            <img src={m.file_url} alt="" />
            {topCenter(m)}

            {m.quene === "complete" ? (
              <div className={`top-right-popup `}>
                <div className="preview-butn-bx show-hover">
                  <Tag
                    onClick={() => setShow(m)}
                    className={`editor-tag-tab  active-tg-wdt`}
                  >
                    Preview
                  </Tag>
                </div>
              </div>
            ) : null}
            {m.quene === "complete" ? topLeft(m) : null}
            {m.quene === "complete" || m.quene === "failed"
              ? bottomLeft(m)
              : null}

            {m.quene === "progress" ? (
              <div className="bottom-left-popup ">
                <a href={`https://${m.meta_link}`} target="`blank">
                  <Tag className={`editor-tag-tab  active-tg-wdt`}>
                    {" "}
                    Learn More{" "}
                  </Tag>
                </a>
              </div>
            ) : null}

            <div className="icons-bx-ri-vd2s">
              {m.model_urls?.slice(0, 3).map((u, ui) => {
                return (
                  <Tooltip title={m.models[ui]}>
                    <img className="model-urls-img-c" src={u} alt="" key={ui} />
                  </Tooltip>
                );
              })}
              <Button
                //   onClick={() => setPlay(m._id)}
                size="large"
              >
                <CiTextAlignCenter className="r-atc-incs" />
              </Button>
            </div>

            {m.quene === "failed" ? (
              <div className="progress-bc failed-bcv">
                <Tag color="rgb(134, 0, 0)">Failed</Tag>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  const ImageCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo value={m.progress_bar} id={m._id} />
        ) : (
          <div className="video-thumbnail-imgg">
            <img src={m.file_url} alt="" />
            {topCenter(m)}

            {m.quene === "complete" ? topRight(m) : null}
            {m.quene === "complete" ? topLeft(m) : null}
            {m.quene === "complete" || m.quene === "failed"
              ? bottomLeft(m)
              : null}

            {m.quene === "progress" ? (
              <div className="bottom-left-popup ">
                <a href={`https://${m.meta_link}`} target="`blank">
                  <Tag className={`editor-tag-tab  active-tg-wdt`}>
                    {" "}
                    Learn More{" "}
                  </Tag>
                </a>
              </div>
            ) : null}

            <div className="icons-bx-ri-vd2s">
              <Button
                //   onClick={() => setPlay(m._id)}
                size="large"
              >
                <FaImage className="r-atc-incs" />
              </Button>
            </div>

            {m.quene === "failed" ? (
              <div className="progress-bc failed-bcv">
                <Tag color="rgb(134, 0, 0)">Failed</Tag>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  const mapCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        {m.quene === "progress" ? (
          <GenerateLoadingVideo value={m.progress_bar} id={m._id} />
        ) : (
          <div className="video-thumbnail-imgg">
            <img src={m.file_url} alt="" />
            {topCenter(m)}

            {m.quene === "complete" ? topRight(m) : null}
            {m.quene === "complete" ? topLeft(m) : null}
            {m.quene === "complete" || m.quene === "failed"
              ? bottomLeft(m)
              : null}

            {m.quene === "progress" ? (
              <div className="bottom-left-popup ">
                <a href={`https://${m.meta_link}`} target="`blank">
                  <Tag className={`editor-tag-tab  active-tg-wdt`}>
                    {" "}
                    Learn More{" "}
                  </Tag>
                </a>
              </div>
            ) : null}

            <div className="icons-bx-ri-vd2s">
              <Map row={m} />
            </div>

            {m.quene === "failed" ? (
              <div className="progress-bc failed-bcv">
                <Tag color="rgb(134, 0, 0)">Failed</Tag>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  return (
    <div

    // onClick={() => console.log(generate)}
    >
      <div className="active-goals-tc21">
        {/* {tasks.activeIcon !== "search" ? (
          <ConnectAlerts
            t1={"Understanding Actions"}
            t2={"Consumption"}
            p1={
              "Model Actions gives you a single interface to create, edit and interact with multiple types of data at the same time. This includes creating image, video, sound, voice, music all in the same flow. Try today by simply entering a query above."
            }
            p2={
              "You consume credits anytime you send a request for generation. You are charged on each successful media generation at different rates. To learn more about how pricing works. See here."
            }
            cardimg={cardImg}
            isAugment={true}
            dynamicLink={"https://docs.modelui.com/actions/introduction"}
          />
        ) : null} */}
        <ActiveConnections />
        <DetailModal show={show} setShow={setShow} />
        <h1>sssss</h1>
        <Row ref={ref8}>
          {images.data &&
            images.data.map((m, mi) => {
              return (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  lg={{ span: 6 }}
                  md={{ span: 8 }}
                  key={mi}
                  ref={
                    mi === 0 && organization?.onboardStep === 4
                      ? ref4
                      : mi === 0 && organization?.onboardStep === 5
                      ? ref5
                      : mi === 0 && organization?.onboardStep === 6
                      ? ref6
                      : null
                  }
                  style={{
                    minHeight: "200px",
                    opacity:
                      organization?.onboardStep && organization?.onboardStep < 4
                        ? 0
                        : 1,
                  }}
                  //   className="display-goal-st-s"
                >
                  {m.media_type === "original" ? videoCard(m) : null}
                  {m.media_type === "csv" ? csvCard(m) : null}
                  {m.media_type === "code" ? codeCard(m) : null}
                  {m.media_type === "text" ? textCard(m) : null}
                  {m.media_type === "map" ? mapCard(m) : null}
                  {m.media_type === "image" ? ImageCard(m) : null}
                  {m.media_type === "sound" ||
                  m.media_type === "voice" ||
                  m.media_type === "music"
                    ? AudioCard(m)
                    : null}
                </Col>
              );
            })}
        </Row>
        {images.totalPages && images.totalPages > images.pageNo ? (
          <div className="load-more-btn">
            {loading2 ? (
              <Spin indicator={antIcon} />
            ) : (
              <Tag
                onClick={() => {
                  setImages({
                    ...images,
                    pageNo: images.pageNo + 1,
                  });
                  setLoading2(true);
                  setCallApi(callApi + 1);
                }}
                className={`editor-tag-tab  active-tg-wdt`}
              >
                Load More <FaArrowDown />
              </Tag>
            )}
          </div>
        ) : null}
        {images.data && images.data.length === 0 ? (
          <div className="no-heigh-div-c ">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Assets Available</p>
                <p className="txt-card-21">
                  Leverage generative AI to reimagine your assets with FX
                  Studio. Add objects, characters into moments that didn't exist
                  before.
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
