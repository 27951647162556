import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Input,
  Progress,
  Row,
  Spin,
  Select,
  Collapse,
  Slider,
  Pagination,
  Tag,
  Popover,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { MdClose, MdKeyboardBackspace, MdKeyboardVoice } from "react-icons/md";
import Paragraph from "antd/lib/typography/Paragraph";
import {
  FaArrowDown,
  FaArrowRight,
  FaAudioDescription,
  FaFileAudio,
  FaImage,
  FaInfo,
  FaMinus,
  FaMusic,
  FaPlay,
  FaQuestion,
  FaShare,
  FaUpload,
} from "react-icons/fa";
import { LiaShareSolid } from "react-icons/lia";
import { TbArrowRight, TbShare3 } from "react-icons/tb";
import { LoadingOutlined } from "@ant-design/icons";

//   import UploadAssets from "./UploadAssets";
//   import ReImagineButton from "./ReImagineButton";
import SearchContext from "../../../../../Context/SearchContext";
import ReactAudioPlayer from "react-audio-player";
import { BsInfo, BsQuestion, BsSoundwave } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IoMdAttach } from "react-icons/io";
import DetailModal from "./DetailModal";
import { AiOutlineQuestionCircle, AiOutlineShareAlt } from "react-icons/ai";
import UpScale from "./UpScale";
import UpScaleImage from "./UpScaleImage";

export default function TaskIdAssets({ row }) {
  const {
    user,

    setTasks,
    tasks,
    organization,
  } = useContext(SearchContext);
  const [images, setImages] = useState({ pageNo: 1 });
  const [active, setActive] = useState();
  const [waiting, setWaiting] = useState();
  const [loading, setLoading] = useState(false);
  const [check, setChek] = useState();
  const [loading2, setLoading2] = useState();
  const [taskID, setTaskID] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (check === waiting) {
      setActive(waiting);
    }
  }, [check]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  useEffect(() => {
    if (taskID) {
      if (row.task_id) {
        getTask(row.task_id);
      } else {
        setImages({
          ...images,
          // data: data.imageList,
          data: [],
        });
        setLoading(false);
      }
    }
  }, [taskID]);

  const getTask = (ID) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/task/${ID}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        setLoading(false);

        if (data && data.isSuccess) {
          let all =
            images.pageNo === 1 ? data.task : images.data.concat(data.task);
          setImages({
            ...images,
            // data: data.imageList,
            data: all,
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = images.data.filter((p) => p._id !== row._id);
          setImages({ ...images, data: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const copyToClipboard = (m) => {
    const textToCopy = `${window.location.origin}/share/${m._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  const topRight = (m) => {
    return (
      <div className={`top-right-popup `}>
        {m.media_type === "original" ? <UpScale m={m} /> : null}
        {m.media_type === "image" ? <UpScaleImage m={m} /> : null}
        <Tooltip title="Info">
          <b
            style={{ marginLeft: "5px" }}
            onClick={() => copyToClipboard(m)}
            className="show-hover"
          >
            <AiOutlineShareAlt />
          </b>
        </Tooltip>

        <b
          onClick={() => {
            setTasks({ ...tasks, activeGoal: m });
            navigate(`/asset/${m._id}`);
          }}
          style={{ marginLeft: "5px" }}
          className="show-hover"
        >
          <TbArrowRight />
        </b>
      </div>
    );
  };
  const bottomLeft = (m) => {
    return (
      <div className={`bottom-left-popup `}>
        <Popconfirm
          title="Delete this asset"
          onConfirm={() => deleteTask(m)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
          placement="right"
        >
          <b className="show-hover">
            <MdClose
              style={{
                position: "relative",
              }}
              className="circular-icon hover"
            />
          </b>
        </Popconfirm>
      </div>
    );
  };
  const bottomRight = (m) => {
    return (
      <div className="icons-bx-ri-vd2s">
        <Button onClick={() => setActive(m._id)} size="large">
          <FaPlay style={{ marginLeft: "2px" }} className="r-atc-incs" />
        </Button>
      </div>
    );
  };

  const videoCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        <div className="video-thumbnail-imgg">
          {m._id === active || organization?.onboardStep === 6 ? (
            <div
              style={{ position: "relative" }}
              className="video-container video-container-round"
            >
              <ReactPlayer url={m.file_url} controls playing />
            </div>
          ) : (
            <img src={m.root_image} alt="" />
          )}
          {m.quene === "complete" ? topRight(m) : null}
          {m._id === active || organization?.onboardStep === 6 ? null : (
            <>
              {bottomRight(m)}
              {m.quene === "complete" || m.quene === "failed"
                ? bottomLeft(m)
                : null}
            </>
          )}

          {m.quene === "progress" ? (
            <div className="bottom-left-popup ">
              <a href={`https://${m.meta_link}`} target="`blank">
                <Tag className={`editor-tag-tab  active-tg-wdt`}>
                  {" "}
                  Learn More
                </Tag>
              </a>
            </div>
          ) : null}

          {m.quene === "progress" ? (
            <div className="progress-bc">
              <Progress
                active
                type="circle"
                percent={m.progress_bar}
                size={60}
              />
            </div>
          ) : null}
          {m.quene === "complete" ? (
            <div className="top-right-popup progress-card-title">
              <Tag color="rgb(134, 0, 0)">{m.meta_title}</Tag>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const AudioCard = (m) => {
    return m._id === active || organization?.onboardStep === 10 ? (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        <div className="video-thumbnail-imgg video-thumbnail-imgg2s">
          <div
            style={{
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              padding: "10px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setTasks({ ...tasks, activeGoal: m });
              navigate(`/asset/${m._id}`);
            }}
            className="video-container video-container-round"
            // ref={ref10}
          >
            {/* <h5>{m.media_type}</h5> */}
            <ReactAudioPlayer src={m.file_url} controls />
            {/* <h6>{m.query}</h6> */}
          </div>
        </div>
      </div>
    ) : (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        <div className="video-thumbnail-imgg">
          <img src={m.root_image} alt="" />
          {m.quene === "complete" ? topRight(m) : null}
          {m.quene === "complete" || m.quene === "failed"
            ? bottomLeft(m)
            : null}

          {m.quene === "progress" ? (
            <div className="bottom-left-popup ">
              <a href={`https://${m.meta_link}`} target="`blank">
                <Tag className={`editor-tag-tab  active-tg-wdt`}>
                  {" "}
                  Learn More
                </Tag>
              </a>
            </div>
          ) : null}
          <div className="icons-bx-ri-vd2s">
            <Button onClick={() => setActive(m._id)} size="large">
              {m.media_type === "music" ? (
                <FaMusic className="r-atc-incs" />
              ) : null}
              {m.media_type === "sound" ? (
                <BsSoundwave className="r-atc-incs" />
              ) : null}
              {m.media_type === "voice" ? (
                <MdKeyboardVoice className="r-atc-incs" />
              ) : null}
            </Button>
          </div>
          {m.quene === "progress" ? (
            <div className="progress-bc">
              <Progress
                active
                type="circle"
                percent={m.progress_bar}
                size={70}
              />
            </div>
          ) : null}
          {m.quene === "failed" ? (
            <div className="progress-bc failed-bcv">
              <Tag color="rgb(134, 0, 0)">Failed</Tag>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const ImageCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
      >
        <div className="video-thumbnail-imgg">
          <img src={m.file_url} alt="" />
          {m.quene === "complete" ? topRight(m) : null}
          {m.quene === "complete" || m.quene === "failed"
            ? bottomLeft(m)
            : null}

          {m.quene === "progress" ? (
            <div className="bottom-left-popup ">
              <a href={`https://${m.meta_link}`} target="`blank">
                <Tag className={`editor-tag-tab  active-tg-wdt`}>
                  {" "}
                  Learn More{" "}
                </Tag>
              </a>
            </div>
          ) : null}

          <div className="icons-bx-ri-vd2s">
            <Button
              //   onClick={() => setPlay(m._id)}
              size="large"
            >
              <FaImage className="r-atc-incs" />
            </Button>
          </div>
          {m.quene === "progress" ? (
            <div className="progress-bc">
              <Progress
                active
                type="circle"
                percent={m.progress_bar}
                size={70}
              />
            </div>
          ) : null}
          {m.quene === "failed" ? (
            <div className="progress-bc failed-bcv">
              <Tag color="rgb(134, 0, 0)">Failed</Tag>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <>
      {" "}
      <Button
        size="small"
        onClick={() => {
          setLoading(true);
          setTaskID(row);
        }}
        className={`editor-tag-tab active-tg-wdt`}
        type="primary"
      >
        Assets
      </Button>
      <Modal
        width={800}
        open={taskID !== undefined}
        onCancel={() => setTaskID()}
        className="no-buttons-modal black-modal"
      >
        <h5>Generations</h5>
        {loading ? <Spin /> : null}
        {taskID && taskID._id ? (
          <div className="active-goals-tc21">
            <Row>
              {images.data?.goals &&
                images.data.goals.map((m, mi) => {
                  return (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 6 }}
                      md={{ span: 8 }}
                      key={mi}
                      style={{ minHeight: "200px" }}
                      //   className="display-goal-st-s"
                    >
                      {m.media_type === "original" ? videoCard(m) : null}
                      {m.media_type === "image" ? ImageCard(m) : null}
                      {m.media_type === "sound" ||
                      m.media_type === "voice" ||
                      m.media_type === "music"
                        ? AudioCard(m)
                        : null}
                    </Col>
                  );
                })}
            </Row>
            {images.totalPages && images.totalPages > images.pageNo ? (
              <div className="load-more-btn">
                {loading2 ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <Tag
                    onClick={() => {
                      setImages({
                        ...images,
                        pageNo: images.pageNo + 1,
                      });
                      setLoading2(true);
                      // setCallApi(callApi + 1);
                    }}
                    className={`editor-tag-tab  active-tg-wdt`}
                  >
                    Load More <FaArrowDown />
                  </Tag>
                )}
              </div>
            ) : null}
            {images.data && images.data.length === 0 ? (
              <div className="no-heigh-div-c editor-container">
                <div className="card21">
                  <div className="card21-img"></div>
                  <div className="card21-info">
                    <p className="text21-body">No Assets Available</p>
                    <p className="txt-card-21">
                      Leverage generative AI to reimagine your assets with FX
                      Studio. Add objects, characters into moments that didn't
                      exist before.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal>
    </>
  );
}
