import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Table,
  Tag,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import UploadAssets from "./UploadAssets";
import Dragger from "antd/lib/upload/Dragger";
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import GreenTick from "../../../components/GreenTick";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineCloseCircle } from "react-icons/ai";

let allv = [];
const { Option } = Select; // Destructure Option from Select
let tl = 0;
let allTl = [];
export default function Character() {
  const {
    user,
    organization,
    setOrganization,
    setassist,
    assist,
    setShowPrice,
  } = useContext(SearchContext);
  const [fileList, setFileList] = useState([]);
  const [callApi, setCallApi] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [pageNo2, setPageNo2] = useState(1);
  const [loading, setLoading] = useState(1);
  const [imgData, setImgData] = useState();
  const [totalPages, setTotalPages] = useState();
  const [totalPages2, setTotalPages2] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [uploadObj, setUploadObj] = useState();
  const [UploadedFile, setUplaoded] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [del, setDel] = useState(null);
  const [isChange, setIsChange] = useState(null);
  const [step, setStep] = useState(1);
  const [mediaType, setmediaType] = useState("object");
  const [makePublic, setMakePublic] = useState();
  const [marketData, setmarketData] = useState();
  const [price, setPrice] = useState();
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    if (imgData === undefined) {
      getImages();
    }
  }, [pageNo, callApi]);

  const getImages = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${pageNo}&limit=8&sort_by=${-1}&user_id=${
        user && user.user_id
      }&media_type=${mediaType}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setImgData(data.imageList);
          setTotalPages(data.totalPages);
        } else {
          message.warning("Something went wrong");
        }
      });
  };
  const getImages2 = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${pageNo2}&limit=9&sort_by=${-1}&user_id=64dc1de41d6e72846d0bb3f2&display=public`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setmarketData(data.imageList);
          setTotalPages2(data.totalPages);
        } else {
          message.warning("Something went wrong");
        }
      });
  };

  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = imgData.filter((p) => p._id !== row._id);
          setImgData(all);
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const renderFileName = () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      return (
        <>
          <img className="img-prev-ibc" src={imageUrl} alt="" />
        </>
      );
    } else {
      return <span>Drag or click to upload</span>;
    }
  };

  const removeFile = () => {
    setFileList([]);
  };
  const handleBeforeUpload = (file, fileList) => {
    setFileList([file]); // save the file object in state for later use
    return false; // don't actually upload the file
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const props = {
    listType: "picture-card",
    status: "done",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl(url);
      });
    },
  };

  const saveSelected = (f, total) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file_url", f.file_url);
    formData.append("assistant_id", "");

    formData.append("purpose", "character");
    formData.append("username", f.username);
    formData.append("meta_data", f.meta_data);
    formData.append("media_type", f.media_type);
    formData.append("user_id", user && user.user_id);
    formData.append("display", "marketplace");

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        updateValue(
          {
            file_url: f.file_url,
            purpose: "character",
            meta_data: f.meta_data,
            media_type: f.media_type,
            display: "marketplace",
            user_id: user && user.user_id,
          },
          data.data._id,
          total
        );

        if (data.message === "Compute is not available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data && data.isSuccess) {
          // const allimgs = imgData ? imgData : [];
          // setImgData([...allimgs, data.data]);
          // allv.push(data.data);

          if (organization) {
            setOrganization({ ...organization, compute: data.compute });
          }
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "We couldn't accept this visual content at this moment. Please contact us if there are any questions ",
          });
        }
      });
  };

  const saveFile = (f, i, id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const formData = new FormData();
    formData.append("file", f.originFileObj);
    formData.append("assistant_id", "");

    formData.append("purpose", "character");
    formData.append("username", name);
    formData.append("meta_data", description);
    formData.append("media_type", uploadObj && uploadObj.media_type);
    formData.append("user_id", user ? user.user_id : "map");

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUplaoded([]);
        setFileList([]);
        setImageUrl();
        setLoading(false);
        setUploadObj();
        setName("");
        setDescription("");
        if (data.message === "Compute is not available") {
          setassist({
            ...assist,
            isUpgrade: true,
          });
        }
        if (data && data.isSuccess) {
          const allimgs = imgData ? imgData : [];
          setImgData([...allimgs, data.data]);
          allv.push(data.data);

          if (organization) {
            setOrganization({ ...organization, compute: data.compute });
          }
        } else if (data.message === "Detected as adult or racy content") {
          notification.error({
            message:
              "We couldn't accept this visual content at this moment. Please contact us if there are any questions ",
          });
        }
      });
  };

  const columns = [
    {
      title: "",
      dataIndex: "file_url",
      key: "file_url",
      render: (text) => <img className="img-table" src={text} alt="" />,
    },
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Name"
          style={{ minWidth: "100px" }}
          onChange={(e) => {
            changeValue(row, "username", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ username: text }, row._id);
            }
          }}
          className="hidden-textarea black-input"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "meta_data",
      key: "meta_data",
      render: (text, row) => (
        <Input.TextArea
          style={{ minWidth: "100px" }}
          autoSize={{ minRows: 2, maxRows: 4 }}
          value={text}
          placeholder="Description"
          onChange={(e) => {
            changeValue(row, "meta_data", e.target.value);
            setIsChange(true);
          }}
          onBlur={() => {
            if (isChange) {
              updateValue({ meta_data: text }, row._id);
            }
          }}
          className="hidden-textarea black-input"
        />
      ),
    },
    {
      title: "Type",

      dataIndex: "media_type",
      key: "media_type",
      render: (text, row) => (
        <div>
          {text === "object" ? (
            <Tag className="gb-tag-brand">Character</Tag>
          ) : (
            <Tag className="gb-tag-brand">Environment</Tag>
          )}
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <div>
          {text === "verified" ? (
            <Spin indicator={antIcon} />
          ) : text === "complete" ? (
            <GreenTick width={"20px"} height={"20px"} />
          ) : (
            <AiOutlineCloseCircle className="red-tick-t" />
          )}
        </div>
      ),
    },
    // {
    //   title: "Display",
    //   dataIndex: "display",
    //   key: "display",
    //   render: (text, row) => (
    //     <div>
    //       {row.display === "public" ? (
    //         <Button
    //           className="gb-tag-brand"
    //           onClick={() => setMakePublic(row)}
    //           size="small"
    //         >
    //           Public <FaEdit style={{ marginLeft: "10px" }} />
    //         </Button>
    //       ) : (
    //         <Button onClick={() => setMakePublic(row)} size="small">
    //           Make Public
    //         </Button>
    //       )}
    //     </div>
    //   ),
    // },

    {
      title: "",
      dataIndex: "01",
      key: "01",
      render: (text, row) => (
        <div>
          {row._id === del ? null : (
            <MdDelete
              onClick={() => {
                setDel(row._id);
                deleteTask(row);
              }}
              className="delete-icon"
            />
          )}
        </div>
      ),
    },
  ];

  const changeValue = (row, key, val) => {
    row[key] = val;
    const all = imgData.map((m) => (m._id === row._id ? row : m));
    setImgData(all);
  };
  const changeValue2 = (row, key, val) => {
    row[key] = val;
    const all = marketData.map((m) => (m._id === row._id ? row : m));
    setmarketData(all);
  };

  const updateValue = (obj, id, total) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsChange(false);
        if (response && response.isSuccess) {
          if (total) {
            tl = tl + 1;
            allTl.push(response.imageData);

            if (tl === total) {
              setImgData(allTl.concat(imgData));
              setmarketData();
              setPageNo2(1);
              setTotalPages2(2);
              setStep(1);
              setPrice();
              setLoading2(false);
            }
          } else {
            let all = imgData.map((m) =>
              m._id === response.imageData._id ? response.imageData : m
            );
            setImgData(all);
          }
        } else {
          notification.error({
            message: "Data Not Saved Please Reload Page and Try Agian ",
            duration: 0,
          });
          // message.error("somehing went wrong");
        }
      });
  };
  const collectPrice = () => {
    let all = marketData.filter((f) => f.selected);
    let sum = 0;
    for (let i = 0; i < all.length; i++) {
      sum = all[i].price ? Number(all[i].price) + sum : 0 + sum;
    }
    setPrice(sum);
  };

  return (
    <div>
      <div className="table-top-filters">
        <Button
          className="plus-button-add"
          onClick={() => {
            if (organization && organization.subscription === "Pro") {
              setShowPrice(true);
            } else {
              setUploadObj({ name: "", media_type: mediaType });
            }
          }}
        >
          <FaPlus />
        </Button>
        <span>
          {/* <Button
            onClick={() => {
              getImages2();
              tl = 0;
              allTl = [];
            }}
            className="gb-tag-brand"
            style={{ marginRight: "10px" }}
          >
            Marketoplace
          </Button> */}
          <Select
            placeholder="Media Type"
            style={{ width: 150 }} // Set the desired width in pixels
            value={mediaType}
            onChange={(value) => {
              setmediaType(value);
              setImgData();
              setCallApi(callApi + 1);
            }}
            className="black-input"
          >
            <Option value={"object"}>Character</Option>
            <Option value={"environment"}>Environment</Option>
          </Select>
        </span>
      </div>
      {imgData && imgData.length === 0 ? (
        <div className="no-heigh-div-c ">
          <div style={{ maxWidth: "600px" }} className="card21">
            <div className="card21-img"></div>
            <div className="card21-info">
              <p className="text21-body">No Assets Available</p>
              <p className="txt-card-21">
                Leverage generative AI to reimagine your videos with Olm Studio.
                Add objects, characters into moments that didn't exist before.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="ant-table-container-m">
          <Table
            pagination={{
              pageSize: 8,
              current: pageNo,
              total: totalPages * 8,
              onChange: (v) => {
                setImgData();
                setPageNo(v);
              },
              size: "small",
              showSizeChanger: false,
            }}
            className="table-box-character pagination-white black-table"
            dataSource={imgData}
            columns={columns}
          />
        </div>
      )}

      <Modal
        open={uploadObj !== undefined}
        onCancel={() => {
          setUploadObj();
          setName("");
          setDescription("");
        }}
        width={400}
        className="no-buttons-modal black-modal"
      >
        {uploadObj ? (
          <div className="modal-upload-c">
            <h6>
              Upload{" "}
              {uploadObj.media_type === "object" ? "Character" : "Environment"}
            </h6>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Select
                placeholder="Media Type"
                style={{ margin: "0px 0px 20px 0px" }}
                value={uploadObj && uploadObj.media_type}
                onChange={(value) =>
                  setUploadObj({ ...uploadObj, media_type: value })
                }
              >
                <Option value={"object"}>Character</Option>
                <Option value={"environment"}>Environment</Option>
              </Select>
              <Dragger
                showUploadList={false}
                maxCount={1}
                beforeUpload={handleBeforeUpload}
                {...props}
              >
                {renderFileName()}
              </Dragger>
            </div>

            <Input
              style={{ margin: "20px 0px 0px 0px" }}
              placeholder={`${
                uploadObj.media_type === "object" ? "Character" : "Environment"
              } name`}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Input.TextArea
              style={{ margin: "20px 0px" }}
              placeholder={`${
                uploadObj.media_type === "object" ? "Character" : "Environment"
              } Detail`}
              autoSize={{ minRows: 2, maxRows: 4 }}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            {name.length && UploadedFile.length ? (
              <div>
                {loading ? (
                  <Spin />
                ) : (
                  <Button
                    onClick={() => {
                      setLoading(true);
                      saveFile(UploadedFile[0]);
                    }}
                  >
                    save
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal>
      <Modal
        open={marketData !== undefined}
        onCancel={() => {
          setmarketData();
          setPageNo2(1);
          setTotalPages2(2);
          setStep(1);
          setPrice();
        }}
        width={750}
        className="no-buttons-modal black-modal"
      >
        {marketData ? (
          <div>
            {step === 1 ? (
              <div>
                <Row>
                  {marketData.map((m, mi) => {
                    return (
                      <Col
                        key={mi}
                        lg={{ span: 8 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        style={{ padding: "10px" }}
                      >
                        <div className="market-card-bx">
                          <div>
                            <div className="user-select-m">
                              <b>{m.username}</b>{" "}
                              <span>
                                Select{" "}
                                <Checkbox
                                  checked={m.selected}
                                  onChange={(e) => {
                                    changeValue2(
                                      m,
                                      "selected",
                                      e.target.checked
                                    );
                                  }}
                                ></Checkbox>
                              </span>
                            </div>
                            <img
                              style={{ width: "100%" }}
                              className="img-table"
                              src={m.file_url}
                              alt=""
                            />
                          </div>

                          <div>Price: {m.price}</div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                {marketData.filter((f) => f.selected).length ? (
                  <div style={{ marginTop: "10px", marginBottom: "0px" }} className="next-btn-f">
                    <span />
                    <Button
                      className="gb-tag-brand"
                      onClick={() => {
                        collectPrice();
                        setStep(2);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                <Row>
                  {marketData
                    .filter((f) => f.selected)
                    .map((m, mi) => {
                      return (
                        <Col
                          key={mi}
                          lg={{ span: 8 }}
                          sm={{ span: 12 }}
                          md={{ span: 8 }}
                          style={{ padding: "10px" }}
                        >
                          <div className="market-card-bx">
                            <div>
                              <div className="user-select-m">
                                <b>{m.username}</b>{" "}
                              </div>
                              <img
                                style={{ width: "100%" }}
                                className="img-table"
                                src={m.file_url}
                                alt=""
                              />
                            </div>
                            <div>Price: {m.price}</div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <div
                  style={{ marginTop: "10px", marginBottom: "0px" }}
                  className="next-btn-f"
                >
                  <span>Total Price = ${price}</span>{" "}
                  <div>
                    <Button
                      onClick={() => {
                        setStep(1);
                      }}
                      style={{ marginRight: "10px" }}
                    >
                      Back
                    </Button>
                    {loading2 ? (
                      <Spin />
                    ) : (
                      <Button
                        className="gb-tag-brand"
                        onClick={() => {
                          setLoading2(true);
                          for (
                            let i = 0;
                            i < marketData.filter((f) => f.selected).length;
                            i++
                          ) {
                            saveSelected(
                              marketData[i],
                              marketData.filter((f) => f.selected).length
                            );
                          }
                        }}
                      >
                        Confirm
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </Modal>
      <Modal
        open={makePublic !== undefined}
        onCancel={() => {
          setMakePublic();
        }}
        width={550}
        className="no-buttons-modal black-modal"
      >
        {makePublic ? (
          <div>
            <h4>Monetize Your Characters</h4>
            <p>
              Let the Olm community discover and use your characters for their
              own videos
            </p>
            <div className="caharacter-m-mid">
              <img className="img-table" src={makePublic.file_url} alt="" />
              <div className="price-desc-d">
                <p>
                  Price:
                  <InputNumber
                    className="hidden-textarea black-input"
                    value={makePublic.price ? makePublic.price : 0}
                    onChange={(e) => {
                      setMakePublic({ ...makePublic, price: e });
                    }}
                    onBlur={() => {
                      updateValue({ price: makePublic.price }, makePublic._id);
                    }}
                  />
                </p>
                <Input.TextArea
                  style={{ minWidth: "100px" }}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  value={makePublic.meta_data}
                  placeholder="Description"
                  onChange={(e) => {
                    setMakePublic({ ...makePublic, meta_data: e.target.value });
                  }}
                  onBlur={() => {
                    updateValue(
                      { meta_data: makePublic.meta_data },
                      makePublic._id
                    );
                  }}
                  className="hidden-textarea black-input"
                />
              </div>
            </div>

            <div className="monti-m-button">
              {makePublic.display === "public" ? (
                <Button
                  onClick={() => {
                    updateValue(
                      { display: "private" },
                      makePublic && makePublic._id
                    );
                    setMakePublic();
                  }}
                >
                  Make Private
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    updateValue(
                      { display: "public" },
                      makePublic && makePublic._id
                    );
                    setMakePublic();
                  }}
                >
                  Continue
                </Button>
              )}
              <Button onClick={() => setMakePublic()}>Close</Button>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
