import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import VideoArea from "./components/VideoArea";
import { Button } from "antd";

export default function ShareVideo() {
  const [video, setVideo] = useState();

  const getVideos = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/share/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setVideo(data.image);
             // Set Open Graph meta tags for image and description
             const ogImageTag = document.createElement("meta");
             ogImageTag.setAttribute("property", "og:image");
             ogImageTag.setAttribute("content", data.image.thumbnail);
             document.head.appendChild(ogImageTag);
             const ogDescriptionTag = document.createElement("meta");
             ogDescriptionTag.setAttribute("property", "og:description");
             ogDescriptionTag.setAttribute("content", data.image.query);
             document.head.appendChild(ogDescriptionTag);
        }
      });
  };

  useEffect(() => {

    getVideos(window.location.pathname.slice(7));
  }, []);
  return (
    <div>
      <Header />
      <div className="feed-page">
        {video ? (
          <div className="feed-vide-share-c">
            <div className="feed-view-area">
              <VideoArea video={video} />
            </div>
            <div className="load-more-c">
  <Button
    className="custom-button"
    onClick={() => {
      window.location.href = "/watch"; // Replace "/watch" with the desired URL
    }}
  >
    See More Videos Like This
  </Button>
</div>

          </div>
        ) : null}
      </div>
    </div>
  );
}
