import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Input,
  Progress,
  Row,
  Spin,
  Select,
  Collapse,
  Slider,
  Pagination,
  Tag,
  Popover,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { MdClose, MdKeyboardBackspace, MdKeyboardVoice } from "react-icons/md";
import Paragraph from "antd/lib/typography/Paragraph";
import {
  FaArrowDown,
  FaArrowRight,
  FaAudioDescription,
  FaFileAudio,
  FaImage,
  FaInfo,
  FaMinus,
  FaMusic,
  FaPlay,
  FaShare,
  FaUpload,
} from "react-icons/fa";
import { LiaShareSolid } from "react-icons/lia";
import { TbArrowRight, TbShare3 } from "react-icons/tb";
import { LoadingOutlined } from "@ant-design/icons";

//   import UploadAssets from "./UploadAssets";
//   import ReImagineButton from "./ReImagineButton";
import SearchContext from "../../../../../Context/SearchContext";
import ReactAudioPlayer from "react-audio-player";
import { BsInfo, BsSoundwave } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IoMdAttach } from "react-icons/io";
import DetailModal from "./DetailModal";
import { AiOutlineShareAlt } from "react-icons/ai";
import UpScale from "./UpScale";
import UpScaleImage from "./UpScaleImage";

export default function RelatedAssets({ data, mainD, setData }) {
  const {
    images,
    setImages,
    setTasks,
    tasks,
    ref4,
    organization,
    ref5,
    ref6,
    ref9,
    ref10,
  } = useContext(SearchContext);
  const [active, setActive] = useState();
  const [waiting, setWaiting] = useState();
  const [check, setChek] = useState();
  const [show, setShow] = useState();
  const navigate = useNavigate();

  const topRight = (m) => {
    return (
      <div className={`top-right-popup2 `}>{m.job_type?.replace("_", " ")}</div>
    );
  };
  const videoCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
        onClick={() =>
          setTasks({
            ...tasks,
            // data: data.imageList,
            activeGoal: m,
          })
        }
      >
        <div className="video-thumbnail-imgg">
          <img src={m.root_image} alt="" />
          {topRight(m)}
          {m.quene === "complete" ? bottomLeft(m) : null}

          {m.quene === "complete" ? topLeft(m) : null}
          {m.quene === "progress" ? (
            <div className="progress-bc">
              <Progress
                active
                type="circle"
                percent={m.progress_bar}
                size={70}
              />
            </div>
          ) : null}
          {m.quene === "failed" ? (
            <div className="progress-bc failed-bcv">
              <Tag color="rgb(134, 0, 0)">Failed</Tag>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const AudioCard = (m) => {
    return (
      <div
        className="display-goal-st-s1"
        style={{ color: "white", width: "100%" }}
        onClick={() =>
          setTasks({
            ...tasks,
            // data: data.imageList,
            activeGoal: m,
          })
        }
      >
        <div className="video-thumbnail-imgg">
          <img src={m.root_image} alt="" />
          {topRight(m)}
          {m.quene === "complete" ? bottomLeft(m) : null}

          {m.quene === "complete" ? topLeft(m) : null}
          <div className="icons-bx-ri-vd2s">
            <Button onClick={() => setActive(m._id)} size="large">
              {m.media_type === "music" ? (
                <FaMusic className="r-atc-incs" />
              ) : null}
              {m.media_type === "sound" ? (
                <BsSoundwave className="r-atc-incs" />
              ) : null}
              {m.media_type === "voice" ? (
                <MdKeyboardVoice className="r-atc-incs" />
              ) : null}
            </Button>
          </div>
          {m.quene === "progress" ? (
            <div className="progress-bc">
              <Progress
                active
                type="circle"
                percent={m.progress_bar}
                size={70}
              />
            </div>
          ) : null}
          {m.quene === "failed" ? (
            <div className="progress-bc failed-bcv">
              <Tag color="rgb(134, 0, 0)">Failed</Tag>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const ImageCard = (m) => {
    return (
      <div
        className="display-goal-st-s1 "
        style={{ color: "white", width: "100%" }}
        onClick={() =>
          setTasks({
            ...tasks,
            // data: data.imageList,
            activeGoal: m,
          })
        }
      >
        <div className="video-thumbnail-imgg">
          <img src={m.file_url} alt="" />
          {topRight(m)}

          {m.quene === "complete" ? topLeft(m) : null}
          {m.quene === "complete" ? bottomLeft(m) : null}
          <div className="icons-bx-ri-vd2s">
            <Button
              //   onClick={() => setPlay(m._id)}
              size="large"
            >
              <FaImage className="r-atc-incs" />
            </Button>
          </div>
          {m.quene === "progress" ? (
            <div className="progress-bc">
              <Progress
                active
                type="circle"
                percent={m.progress_bar}
                size={70}
              />
            </div>
          ) : null}
          {m.quene === "failed" ? (
            <div className="progress-bc failed-bcv">
              <Tag color="rgb(134, 0, 0)">Failed</Tag>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((d) => {
        if (d && d.isSuccess) {
          message.success("Deleted");
          const all = data.filter((p) => p._id !== row._id);
          setData(all);
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const bottomLeft = (m) => {
    return (
      <div className={`bottom-left-popup `}>
        <Popconfirm
          title="Delete this asset"
          onConfirm={() => deleteTask(m)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
          placement="right"
          trigger={"click"}
        >
          <b className="show-hover">
            <MdClose
              style={{
                position: "relative",
                zIndex: 120,
              }}
              className="circular-icon hover"
            />
          </b>
        </Popconfirm>
      </div>
    );
  };
  const adjustClick = (m) => {
    let attachment = images.attachment ? images.attachment : [];
    if (attachment.filter((f) => f === m.file_url).length) {
      attachment = attachment.filter((f) => f !== m.file_url);
    } else {
      attachment.push(m.file_url);
    }
    setImages({
      ...images,
      attachment: attachment,
      imageId:
        m.media_type === "image"
          ? m._id === images.imageId?._id
            ? undefined
            : m
          : images.imageId,
      videoId:
        m.media_type === "original"
          ? m._id === images.videoId?._id
            ? undefined
            : m
          : images.videoId,
      soundId:
        m.media_type === "sound"
          ? m._id === images.soundId?._id
            ? undefined
            : m
          : images.soundId,
      musicId:
        m.media_type === "music"
          ? m._id === images.musicId?._id
            ? undefined
            : m
          : images.musicId,
      voiceId:
        m.media_type === "voice"
          ? m._id === images.voiceId?._id
            ? undefined
            : m
          : images.voiceId,
    });
  };
  const topLeft = (m) => {
    return (
      <div className={`top-left-popup `}>
        <Tooltip title="Attach">
          <b className="show-hover">
            <IoMdAttach
              onClick={() => {
                adjustClick(m);
              }}
              style={{
                background:
                  images.attachment &&
                  images.attachment.filter((f) => f === m.file_url).length
                    ? "#BC1823"
                    : "black",
                position: "relative",
              }}
              className="circular-icon hover"
            />
          </b>
        </Tooltip>
      </div>
    );
  };
  return (
    <div

    // onClick={() => console.log(generate)}
    >
      <div className="active-goals-tc21">
        <h6 style={{ color: "white", marginTop: "10px" }}>Variations</h6>
        <DetailModal show={show} setShow={setShow} />
        <Row>
          {data &&
            [mainD].concat(data).map((m, mi) => {
              return (
                <Col
                  lg={{ span: 4 }}
                  md={{ span: 6 }}
                  key={mi}
                  ref={
                    mi === 0 && organization?.onboardStep === 4
                      ? ref4
                      : mi === 0 && organization?.onboardStep === 5
                      ? ref5
                      : mi === 0 && organization?.onboardStep === 6
                      ? ref6
                      : null
                  }
                  //   className="display-goal-st-s"
                >
                  {m.media_type === "original" ? videoCard(m) : null}
                  {m.media_type === "image" ? ImageCard(m) : null}
                  {m.media_type === "sound" ||
                  m.media_type === "voice" ||
                  m.media_type === "music"
                    ? AudioCard(m)
                    : null}
                </Col>
              );
            })}
        </Row>

        {images.data && images.data.length === 0 ? (
          <div className="no-heigh-div-c ">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Assets Available</p>
                <p className="txt-card-21">
                  Leverage generative AI to reimagine your videos with Olm
                  Studio. Add objects, characters into moments that didn't exist
                  before.
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
