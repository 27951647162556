import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchContext from "../../Context/SearchContext";
import axios from "axios";
import { Select, message } from "antd";
import moment from "moment";

export default function VideoPage() {
  const { setDisplayVid, displayVid } = useContext(SearchContext);
  const { Option } = Select;
  const getVideos = async (id) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(`https://embed.axv.ai/api/image/${id}`);

      // Update the state with the retrieved jobs data
      if (response.data && response.data.imageData) {
        setDisplayVid(response.data.imageData);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  useEffect(() => {
    if (displayVid === undefined) {
      getVideos(window.location.pathname.slice(7));
    }
  }, []);


  
  const updateValue = (obj , id) => {
  
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
     
      },
      body: JSON.stringify(obj),
    };
    fetch(
      `https://embed.axv.ai/api/image/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
         setDisplayVid(response.imageData)
        } else {
          message.error("somehing went wrong");
        }
      
      });
  };

  return (
    <div>
      <Header />
      <div className="jobs-video-main">
        <div className="table-jobs">
          {displayVid ? (
            <div>
              <div className="display-bx-main">
                <p>{displayVid.query}</p>
                <Select
                 size="large"
                  placeholder="Display"
                  style={{ width: 120 }} // Set the desired width in pixels
                  value={displayVid.display ? displayVid.display : "private"}
                  onChange={(value) => {
                    setDisplayVid({ ...displayVid, display: value });
                    updateValue({display:value} ,displayVid._id )
                  }}
                >
                  <Option value={"private"}>Private</Option>
                  <Option value={"public"}>Public</Option>
                </Select>
              </div>
              <div className="video-container radius-video">
                <ReactPlayer controls url={displayVid.file_url} playing  config={{
                file: {
                  attributes: {
                    controlsList: "nodownload", 
                  },
                },
              }}/>
              </div>
              <div className="momen-created-at">{moment(displayVid.createdAt).fromNow()}</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
