import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import ReactPlayer from "react-player";
import SearchContext from "../../Context/SearchContext";
import vid from "../../images/Mountains_34d.mp4";
import { useNavigate } from "react-router-dom";
import Editor from "../editor/Editor";
import { Button, Col, Row } from "antd";
import Footer from "../../components/Footer";
import Typewriter from "typewriter-effect";
import vd from "../../images/Mountains_34d.mp4";
import FullLoader from "../storyProject/components/FullLoader";
import "./home.css";
import VideoSection from "./components/VideoSection";
import TextSection from "./components/TextSection";
import CardsSection from "./components/CardsSection";
import BigCardSection from "./components/BigCardSection";
import PricingSection from "./components/PricingSection";

export default function Home() {
  const { user, organization } = useContext(SearchContext);
  const navigate = useNavigate();
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  // Check if user.name exists and redirect accordingly
  // if (user && user.name) {
  //   window.location.href = "/canvas";
  //   return null; // This line ensures that the component doesn't render anything
  // }
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth", // You can use 'auto' for immediate scrolling
    });
  }, []);

  return user && user.name ? (
    organization ? (
      <Editor />
    ) : (
      <FullLoader />
    )
  ) : (
    <div>
      <Header />
      <VideoSection />
      <TextSection text="You’re in control. Our vision is an AI-driven world where you lead the way, seamlessly interacting through audio, video, voice, or a blend of these mediums. Together, we unlock possibilities, make informed decisions, and carve out more time for the work we love in any preferred medium." />
      <CardsSection />
      <TextSection text="You’re in control. Our vision is an AI-driven world where you lead the way, seamlessly interacting through audio, video, voice, or a blend of these mediums. Together, we unlock possibilities, make informed decisions, and carve out more time for the work we love in any preferred medium." />
      <BigCardSection />
      <PricingSection />
    </div>
  );
}
