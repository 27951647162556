import React from "react";
import "./pricing.css";
import Header from "../../components/Header";
export default function Pricing2() {
  return (
    <div>
      <Header />
      <div class="wrap">
        <h1 class="pen-title">Pricing table</h1>
        <p class="pen-description">A simple pricing table.</p>

        <section class="pricing-section">
          <div class="pricing pricing-theme">
            <div class="pricing-item">
              <h3 class="pricing-title">Startup</h3>
              <div class="pricing-price">
                <span class="pricing-currency">$</span>9.90
              </div>
              <p class="pricing-sentence">Small business solution</p>
              <ul class="pricing-feature-list">
                <li class="pricing-feature">Unlimited calls</li>
                <li class="pricing-feature">Free hosting</li>
                <li class="pricing-feature">40MB of storage space</li>
              </ul>
              <button class="pricing-action editor-tag-tab  active-tg-wdt">
                Choose plan
              </button>
            </div>
            <div class="pricing-item">
              <h3 class="pricing-title">Startup</h3>
              <div class="pricing-price">
                <span class="pricing-currency">$</span>9.90
              </div>
              <p class="pricing-sentence">Small business solution</p>
              <ul class="pricing-feature-list">
                <li class="pricing-feature">Unlimited calls</li>
                <li class="pricing-feature">Free hosting</li>
                <li class="pricing-feature">40MB of storage space</li>
              </ul>
              <button class="pricing-action editor-tag-tab  active-tg-wdt">
                Choose plan
              </button>
            </div>

            <div class="pricing-item">
              <h3 class="pricing-title">Medium</h3>
              <div class="pricing-price">
                <span class="pricing-currency">$</span>29,90
              </div>
              <p class="pricing-sentence">Medium business solution</p>
              <ul class="pricing-feature-list">
                <li class="pricing-feature">Unlimited calls</li>
                <li class="pricing-feature">Free hosting</li>
                <li class="pricing-feature">10 hours of support</li>
                <li class="pricing-feature">Social media integration</li>
                <li class="pricing-feature">1GB of storage space</li>
              </ul>
              <button class="pricing-action editor-tag-tab  active-tg-wdt">
                Choose plan
              </button>
            </div>

            <div class="pricing-item">
              <h3 class="pricing-title">Large</h3>
              <div class="pricing-price">
                <span class="pricing-currency">$</span>59,90
              </div>
              <p class="pricing-sentence">Gigantic business solution</p>
              <ul class="pricing-feature-list">
                <li class="pricing-feature">Unlimited calls</li>
                <li class="pricing-feature">Free hosting</li>
                <li class="pricing-feature">Unlimited hours of support</li>
                <li class="pricing-feature">Social media integration</li>
                <li class="pricing-feature">Anaylitcs integration</li>
                <li class="pricing-feature">Unlimited storage space</li>
              </ul>
              <button class="pricing-action editor-tag-tab  active-tg-wdt">
                Choose plan
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
