import React, { useContext, useEffect } from "react";
import SearchContext from "../../../../../Context/SearchContext";
import { Button, Tooltip, message } from "antd";
import dendric_white from "../../../../../images/dendric_white.png";

import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import AddBlock from "../../left/AddBlock";
import GenerateBehaviour from "./GenerateBehaviour";
import { FaArrowRight } from "react-icons/fa";
export default function ActiveConnections() {
  const { connectors, setConnectors, user } = useContext(SearchContext);
  // useEffect(() => {
  //   if (connectors === undefined) {
  //     getlistConnections();
  //   }
  // }, []);
  const getlistConnections = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list/connect?page=1&limit=5&sort_by=${-1}&section=private&user_id=${
        user && user.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setConnectors(data.listList);
        }
      });
  };
  const deleteAutomateData = (data) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    // Make a DELETE request to your API here
    fetch(`https://embed.axv.ai/api/list/${data._id}`, requestOptions)
      .then((response) => response.json())
      .then((d) => {
        if (d.isSuccess) {
          let all = connectors.filter((f) => f._id !== data._id);
          setConnectors(all);
        } else {
          message.error("Failed to remove data");
        }
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        message.error("An error occurred while deleting data");
      });
  };
  return (
    <div className="active-conn-c">
      <div className="left-area-active-conn">
        <a
          style={{ textDecoration: "none" }}
          href="https://guide.dendric.com/actions"
          target="blank"
        >
          <Tooltip title="Help">
            <button className="btn-yel-c">
              <span>Action Guide</span>

              <FaArrowRight />
            </button>
          </Tooltip>
        </a>
        <AddBlock type={"Assets"} />
      </div>

      <div className="models-connected-c">
        <GenerateBehaviour />
        {/* <b style={{ marginRight: "10px", fontSize: "13px" }}>Models</b>
        <Tooltip title={"Dendric"}>
          <img className="compute-img-tbl" src={dendric_white} alt="" />
        </Tooltip>
        {connectors &&
          connectors.map((c, ci) => {
            return (
              <Tooltip key={ci} title={c.value}>
                <div className="img-main-con-cx">
                  <img className="compute-img-tbl" src={c.url} alt="" />
                  <div className="close-icon-bnc">
                    <MdClose onClick={() => deleteAutomateData(c)} />
                  </div>
                </div>
              </Tooltip>
            );
          })}
        <Tooltip title="Connections">
          <AiOutlineQuestionCircle
            style={{ cursor: "pointer", marginLeft: "10px", color: "white" }}
          />
        </Tooltip> */}
      </div>
    </div>
  );
}
