import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import SearchContext from "../../../Context/SearchContext";

export default function Card({ setALlVideos, allVideos, data }) {
  const videoRef = useRef();
  const [isHover, setIsHover] = useState();
  const [makePlay, setMakePlay] = useState(0);
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState();
  const { wc, setWC } = useContext(SearchContext);

  useEffect(() => {
    if (isHover) {
      setWC({ ...wc, hoverCard: data._id });
    }
  }, [makePlay]);
  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  return (
    <div
      onMouseOver={() => {
        setIsHover(true);
        setTimeout(() => {
          setMakePlay(makePlay + 1);
        }, 1000);
      }}
      onMouseLeave={() => {
        setIsHover();
        setWC({ ...wc, hoverCard: undefined });

        setPlay(false);
      }}
    >
      {data._id === wc.hoverCard ? (
        <div className="card-video-main-wc">
          <div className=" player-card-video">
            <ReactPlayer
              ref={videoRef}
              onReady={(v) => setDuration(videoRef.current.getDuration())}
              //   controls
              playing
              url={data.file_url}
            />
          </div>
          <div className="show-hover">
            <div className="created-card-bx-bottom">
              <b>{data.username}</b>{" "}
              {duration ? <i>{TimeCorrection(duration)}</i> : null}
            </div>
            <div className="created-card-bx-bottom">
              <h6>{data.query}</h6>
            </div>{" "}
          </div>
        </div>
      ) : (
        <img className="card-img-wc" src={data.profile_picture} alt="" />
      )}
    </div>
  );
}
