import { Button, Col, Row } from "antd";
import React from "react";
import { Fa500Px } from "react-icons/fa";

export default function PricingSection() {
  return (
    <div className="grey-clr-bx-text pricing-section-s4">
      <div className="container">
        <h5 className="what-text-h">Pricing</h5>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="card-bx-h4">
              <div className="light-card-s5">
                <div className="liner-2px"></div>
                <p>
                  and below this text, add in circle "Volume Discounts
                  Available"
                </p>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="card-bx-h4">
              <div className="light-card-s5">
                <div className="liner-2px"></div>
                <ul>
                  <ol>0.014/Per Media Generated</ol>
                </ul>
                <div className="colume-dis-d">
                  <Button
                    className={`editor-tag-tab active-tg-wdt`}
                    size="small"
                  >
                    Volume Discounts Available
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
