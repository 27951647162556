import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Input,
  Progress,
  Row,
  Spin,
  Select,
  Collapse,
  Slider,
  Pagination,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { MdClose, MdKeyboardBackspace } from "react-icons/md";
import Paragraph from "antd/lib/typography/Paragraph";
import { FaMinus, FaPlay, FaUpload } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import SearchContext from "../../../Context/SearchContext";
import UploadAssets from "./UploadAssets";

export default function Content({ activeTab }) {
  const {
    images,
    setImages,
    theme,
    setassist,
    assist,
    right,
    setRight,
    generate,
    user,
    type,
    setType,
    setGenerate,
    organization,
    setShowPrice,
  } = useContext(SearchContext);
  const playerRef = React.useRef();
  const { Option } = Select; // Destructure Option from Select

  const [loading2, setLoading2] = useState(true);
  const [callApi, setCallApi] = useState(1);
  const [callApi2, setCallApi2] = useState(1);
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(false);
  const [play, setPlay] = useState();
  const [activeVideo, setActiveVideo] = useState();
  const [act, setAct] = useState("");
  const [search, setSearch] = useState("");
  const [loadingP, setLoadingP] = useState(true);
  const [isGen, setIsGen] = useState(false);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const [clip, setClip] = useState();
  const [reimagine, setReimagine] = useState();

  useEffect(() => {
    setTimeout(() => {
      setLoadingP(false);
    }, 500);
  }, []);
  useEffect(() => {
    if (images.pageNo !== 1) {
      getImages();
    }
    if (images.data && images.pageNo === 1) {
      setLoading2(false);
    }
  }, [callApi]);

  useEffect(() => {
    if (images.pageNoV !== 1) {
      getVideos();
    }
  }, [callApi2]);

  useEffect(() => {
    if (type !== "video" && images.data === undefined) {
      getImages();
    }
    if (type === "video" && images.videos === undefined) {
      getVideos();
    }
  }, [type, isGen, refresh]);

  const getVideos = (id) => {
    const isID = JSON.parse(window.localStorage.getItem("isID"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        images.pageNoV
      }&limit=15&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=original&status=${isGen ? "generated" : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            videos: images.videos
              ? images.videos.concat(data.imageList)
              : [].concat(data.imageList),
            totalPages2: data.totalPages,
          });
        } else {
          message.warning("Something wentt wrong");
        }
      });
  };

  const getImages = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/image?page=${
        images.pageNo
      }&limit=8&sort_by=${-1}&user_id=${
        user ? user.user_id : "map"
      }&media_type=visual&status=${isGen ? "generated" : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading2(false);
        if (data && data.isSuccess) {
          setImages({
            ...images,
            data: images.data
              ? images.data.concat(data.imageList)
              : [].concat(data.imageList),
            totalPages: data.totalPages,
          });
        } else {
          message.warning("Something went wrong");
        }
      });
  };

  const loadMore = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      images.totalPages > images.pageNo &&
      !loading2
    ) {
      setImages({
        ...images,
        pageNo: images.pageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };

  const loadMore2 = (v, key) => {
    if (
      v.target.scrollHeight === v.target.offsetHeight + v.target.scrollTop &&
      images.totalPages2 > images.pageNoV &&
      !loading2
    ) {
      setImages({
        ...images,
        pageNoV: images.pageNoV + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi2(callApi2 + 1);
    }
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = images[key].filter((p) => p._id !== row._id);
          setImages({ ...images, [key]: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const adjustClick = (im, key1, key2) => {
    console.log(assist);

    setassist({
      ...assist,
      selAssetID: im,
      // upImage: undefined,
      selAssetID2: undefined,
    });
  };

  const adjustClickV = (im, key1) => {
    console.log(assist);
    setassist({
      ...assist,
      selAssetID2: im,
      selAssetID: undefined,
      upImage: undefined,
    });
  };

  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  const formatter = (value) => TimeCorrection(value);

  const saveClip = () => {
    let obj = {
      start_time: TimeCorrection(startT),
      end_time: TimeCorrection(endT),
      video_id: play._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/trim_video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.success) {
          setClip(response);
        } else {
          message.error("somehing went wrong");
        }
      });
  };

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let all = images.videos.map((m) =>
            m._id === response.imageData._id ? response.imageData : m
          );
          setImages({ ...images, videos: all });
        } else {
          message.error("somehing went wrong");
        }
      });
  };

  const changeSearch = (v) => {
    setSearch(v);
    if (v.trim().length) {
      getVideosSearch(v.trim());
    } else {
      getVideos();
    }
  };

  const getVideosSearch = (v) => {
    const obj = { query: v };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setImages({
            ...images,
            videos: data,
            totalPages2: undefined,
          });
        }
      });
  };

  return loadingP ? null : (
    <div

    // onClick={() => console.log(generate)}
    >
      <div>
        {type === "image" ? (
          <div
            onScroll={(v) => {
              loadMore(v, "pageNo");
            }}
            className="act-logs-fcv"
          >
            <Row>
              {images.data &&
                images.data.map((im, imi) => {
                  return (
                    <Col
                      key={imi}
                      lg={{ span: 6 }}
                      md={{ span: 8 }}
                      sm={{ span: 16 }}
                    >
                      <div
                        className={`img-bx-imgsc ${
                          (assist.selAssetID &&
                            im._id === assist.selAssetID._id &&
                            (right.activeRight !== "Generate" ||
                              generate.activeType === "custom_image")) ||
                          (right.activeRight === "Generate" &&
                            generate.selectedIMG &&
                            generate.activeType !== "custom_image" &&
                            generate.selectedIMG.filter((f) => f._id === im._id)
                              .length)
                            ? " sel-asset-c"
                            : ""
                        }`}
                      >
                        <div className="img-bx-mnb">
                          <img
                            onClick={() => {
                              adjustClick(im, "selectedIMG", "selectedVID");
                            }}
                            src={im.file_url}
                            alt=""
                          />
                        </div>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {images.data && images.data.length === 0 ? (
              <div className="no-heigh-div-c ">
                <div className="card21">
                  <div className="card21-img"></div>
                  <div className="card21-info">
                    <p className="text21-body">No Assets Available</p>
                    <p className="txt-card-21">
                      Leverage generative AI to reimagine your videos with Olm
                      Studio. Add objects, characters into moments that didn't
                      exist before.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {loading2 ? (
              <div className="loading-full-page2">
                <div className="spiner-main-box">
                  <div className="spinner"></div> <FaPlay />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {type === "video" ? (
          <div
            onScroll={(v) => {
              loadMore2(v, "pageNoV");
            }}
            className="act-logs-fcv"
          >
            <div className="search-video-content">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                <div style={{ marginLeft: "0px" }} class="container-s">
                  <input
                    type="text"
                    name="text"
                    class="input-s"
                    required=""
                    placeholder="Imagine Anything"
                    value={search}
                    onChange={(e) => {
                      changeSearch(e.target.value);
                    }}
                  />
                  <div class="icon-s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ionicon-s"
                      viewBox="0 0 512 512"
                    >
                      <title>Search</title>
                      <path
                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="32"
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </div>
                </div>
              </ul>
            </div>
            {images.videos && images.videos.length === 0 ? (
              <div className="no-heigh-div-c ">
                <Alert
                  message="No Assets Exist"
                  type="info"
                  showIcon
                  className="brand-alert bg-txt-a"
                />
              </div>
            ) : null}
            {images.videoImages ? (
              <div>
                <MdKeyboardBackspace
                  className="back-icon-csf"
                  onClick={() => {
                    setAct("");
                    setImages({
                      ...images,
                      videoImages: undefined,
                    });
                  }}
                />
              </div>
            ) : null}
            {images.videos && images.videos.length === 0 ? (
              <div className="no-heigh-div-c ">
                <div className="card21">
                  <div className="card21-img"></div>
                  <div className="card21-info">
                    <p className="text21-body">No Assets Available"</p>
                    <p className="txt-card-21">
                      Leverage generative AI to reimagine your videos with Olm
                      Studio. Add objects, characters into moments that didn't
                      exist before.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            <Row>
              {images.videos &&
                images.videos
                  .filter((f) => f.file_url.includes(act))
                  .map((im, imi) => {
                    return (
                      <Col
                        key={imi}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 2 }}
                      >
                        <div
                          key={imi}
                          className={`img-bx-imgsc vd-bx-nn ${
                            (assist.selAssetID2 &&
                              im._id === assist.selAssetID2._id &&
                              (right.activeRight !== "Generate" ||
                                generate.activeType === "clips" ||
                                generate.activeType === "custom_image")) ||
                            (right.activeRight === "Generate" &&
                              generate.activeType !== "clips" &&
                              generate.activeType !== "custom_image" &&
                              generate.selectedVID &&
                              generate.selectedVID.filter(
                                (f) => f._id === im._id
                              ).length)
                              ? " sel-asset-c"
                              : ""
                          }`}
                          onClick={() => {
                            console.log("clicked");
                            adjustClickV(im, generate.selectedVID);
                          }}
                        >
                          <div className="vd-nm-pr">
                            <ReactPlayer
                              url={im.file_url}
                              controls={play === im._id}
                              playing={play === im._id}
                              config={{
                                file: {
                                  attributes: {
                                    controlsList: "nodownload",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className="img-bx-imgdt">
                            <div className="img-r-ast-b2">
                              <b>
                                <Paragraph
                                  ellipsis={{
                                    rows: 2,
                                    // expandable: true,
                                    symbol: "see more",
                                  }}
                                  className="desc-p-crdjjkl"
                                >
                                  {im.meta_data}
                                </Paragraph>
                              </b>
                              {im._id !== play ? (
                                <div className="icons-bx-ri-vd">
                                  <Button onClick={() => setPlay(im._id)}>
                                    <FaPlay
                                      style={{ marginRight: "px" }}
                                      className="r-atc-incs"
                                    />
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
            </Row>
            {images.videos && images.totalPages2 && images.totalPages2 > 1 ? (
              <div className="pagination-white">
                <Pagination
                  total={images.totalPages2}
                  current={images.pageNoV}
                  showSizeChanger={false}
                  onChange={(v) => {
                    setImages({
                      ...images,
                      videos: undefined,
                      pageNoV: v,
                    });
                    setLoading2(true);
                    setCallApi2(callApi2 + 1);
                  }}
                />
              </div>
            ) : null}

            {images.videoImages &&
              images.videoImages.map((im, imi) => {
                return (
                  <div key={imi} className={`img-bx-imgsc`}>
                    <div>
                      <img src={im.file_url} alt="" />
                    </div>
                    <div className="img-bx-imgdt">
                      <div className="img-r-ast-b2">
                        <FaPlay
                          onClick={() => setPlay(act)}
                          style={{ marginRight: "10px" }}
                          className="r-atc-incs"
                        />
                        {/* <p>{im.summary}</p> */}
                      </div>

                      <i>{im.startTime}</i>
                    </div>
                  </div>
                );
              })}

            {loading2 ? (
              <div className="loading-full-page2">
                <div className="spiner-main-box">
                  <div className="spinner"></div> <FaPlay />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <Modal
          open={assist.isUpload !== undefined}
          onCancel={() => {
            setassist({ ...assist, isUpload: undefined });
          }}
          width={400}
          className="no-buttons-modal black-modal"
        >
          <div className="modal-upload-c">
            <UploadAssets type2={type} />
          </div>
        </Modal>
      </div>
    </div>
  );
}
