import React, { useContext, useEffect, useState } from "react";
import animals from "../../images/animals-img.jpg";
import soundImg from "../../images/sound-img.jpg";
import videoImd from "../../images/video-img.jpg";
import Header from "../../components/Header";
import SearchContext from "../../Context/SearchContext";
import { Col, Row, Spin, Tag, message } from "antd";
import { useNavigate } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

export default function ImagesCategory() {
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoadeing] = useState(true);
  const [pageNo, setPageNo] = useState(1);

  const [listData, setListData] = useState();

  useEffect(() => {
    getlist();
  }, [pageNo]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list?page=${pageNo}&limit=16&sort_by=${-1}&section=image`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = listData ? listData.concat(data.listList) : data.listList;
          setListData(all);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <Header />
      <div className="container">
        <h6 className="category-text-d">Images</h6>
        <p className="category-text-p">Images That Help You Standout</p>
        <Row>
          {listData &&
            listData.map((m, mi) => {
              return (
                <Col lg={{ span: 6 }} md={{ span: 8 }} key={mi}>
                  <div
                    className="display-goal-st-s1"
                    style={{ color: "white", width: "100%" }}
                    onClick={() => navigate(`/image/${m.category}`)}
                  >
                    <div className="video-thumbnail-imgg">
                      <img src={m.url} alt="" />

                      <div className="cat-hover-det1">
                        <h6>{m.value}</h6>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
        {totalPages && totalPages > pageNo ? (
          <div className="load-more-btn">
            {loading ? (
              <Spin indicator={antIcon} />
            ) : (
              <Tag
                onClick={() => {
                  setPageNo(pageNo + 1);
                  setLoadeing(true);
                }}
                className={`editor-tag-tab  active-tg-wdt`}
              >
                Load More <FaArrowDown />
              </Tag>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
