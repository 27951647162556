import {
  Alert,
  Button,
  Checkbox,
  Input,
  Modal,
  Popover,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { RiSettings3Fill } from "react-icons/ri";
import { MdAddCircle } from "react-icons/md";
import SearchContext from "../../../../../Context/SearchContext";
import titleImg from "../../../../../images/huminoid.png";
import AddBlock from "../../left/AddBlock";
import thumbnail from "../../../../../images/8ab29ff281f0601a467983a9657dc481.png";
const { Option } = Select;

export default function GenerateBehaviour() {
  const {
    user,
    behavior,
    setBehavior,
    tasks,
    setTasks,
    images,
    setImages,
    listData,
    setListData,
  } = useContext(SearchContext);

  const [add, setAdd] = useState();
  const [loadingP, setLoadingP] = useState(false);
  const [callConnection, setCallConnection] = useState(1);
  const [connections, setConnections] = useState();

  useEffect(() => {
    if (behavior.data === undefined) {
      getbehavior();
    }
  }, []);

  useEffect(() => {
    getlistConnections();
  }, [callConnection]);
  const getlistConnections = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list/connect?page=1&limit=5&sort_by=${-1}&section=private&user_id=${
        user && user.user_id
      }&router_id=${behavior.selected ? behavior.selected._id : "auto"}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setConnections(data.listList);
        }
      });
  };

  const getbehavior = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/behavior?page=1&limit=10&user_id=${
        user.user_id
      }&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setBehavior({
            ...behavior,
            data: data.behaviorList,
            totalPages: data.totalPages,
            selected: { _id: "auto" },
          });
        }
      });
  };

  const onSelect = (v) => {
    if (v === "auto") {
      adjustCLick({ _id: "auto" });
    } else {
      let item = behavior.data.filter((f) => f._id === v)[0];
      adjustCLick(item);
    }
  };
  const adjustCLick = (b) => {
    setBehavior({
      ...behavior,
      selected: b,
    });
  };

  return (
    <div className="generate-behav behaveior-c">
      <img className="huminoid-img-t" src={titleImg} alt="" />
      {behavior.selected ? (
        <img
          className="selected-img-gen-c"
          src={behavior.selected?.headshot}
          alt=""
        />
      ) : null}
      <Select
        style={{ width: "120px" }}
        className="grey-input"
        onSelect={(v) => {
          onSelect(v);
          setConnections();
          setCallConnection(callConnection + 1);
        }}
        value={behavior.selected?.name ? behavior.selected?.name : "auto"}
      >
        <Option value={"auto"}>Default</Option>
        {behavior.data &&
          behavior.data.map((b, bi) => {
            return (
              <Option value={b._id} key={bi}>
                {b.name}
              </Option>
            );
          })}
      </Select>
      <Popover
        placement="bottom"
        content={
          <div>
            {connections &&
              connections.map((c, ci) => {
                return (
                  <div key={ci} className="name-con-img">
                    <img src={c.url} alt="" />
                    <b>{c.value}</b>
                  </div>
                );
              })}
            {connections && connections.length === 0 ? (
              <Alert message="No Active Skills " type="error" />
            ) : null}
          </div>
        }
      >
        <b className="skills-hover-b">Skills</b>
      </Popover>
    </div>
  );
}
