import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  message,
  Tabs,
  Alert,
  Input,
  Table,
  Collapse,
  Switch,
  Avatar,
  Card,
} from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import {
  FaArrowDown,
  FaArrowRight,
  FaCode,
  FaImage,
  FaMusic,
  FaPlay,
} from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import { VscDebugDisconnect } from "react-icons/vsc";
import SearchContext from "../../../../../Context/SearchContext";
import { BsSoundwave } from "react-icons/bs";
import { MdKeyboardVoice } from "react-icons/md";
import ConnectAlerts from "./ConnectAlerts";
import cardimg from "../../../../../images/OpenFXs (29).png";
import AWS from "../../../../../images/AWS.png";
import Azure from "../../../../../images/Azure.png";
import Dendric from "../../../../../images/Dendric.png";
import GCP from "../../../../../images/GCP.png";
import OctoAI from "../../../../../images/OctoAI.png";
import Replicate from "../../../../../images/Replicate.png";
import togetherai from "../../../../../images/togetherai.jpeg";
import loader from "../../../../../images/nlogo-red.png";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import ConnectionModal from "./ConnectionModal";
import { CiTextAlignCenter } from "react-icons/ci";
import FullLoader from "../../../../storyProject/components/FullLoader";
const { Meta } = Card;
const { Option } = Select;
export default function ListConnect() {
  const {
    user,
    listData,
    setListData,
    behavior,
    setConnectors,
    organization,
    setShowPrice,
  } = useContext(SearchContext);

  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState();
  const [showC, setShowC] = useState();
  const [loading, setLoadeing] = useState(true);
  const [dDelete, displayDelete] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  // const [listData, setListData] = useState();
  const [connections, setConnections] = useState();
  const [cxl, setCallExpandList] = useState(0);
  const [callApi, setCallApi] = useState(1);
  const { TabPane } = Tabs;

  useEffect(() => {
    if (behavior.data !== undefined) {
      if (listData.data === undefined || callApi > 1) {
        getlist();
      } else {
        setLoadeing(false);
        if (connections === undefined) {
          getlistConnections();
        }
      }
    }
  }, [callApi, listData.callApi, behavior.data]);
  useEffect(() => {
    if (behavior.callConnection) {
      getlistConnections();
    }
  }, [behavior.callConnection]);

  useEffect(() => {
    if (cxl > 0) {
      calculateExpand();
    }
  }, [cxl]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list?page=${
        listData.pageNo
      }&limit=12&sort_by=${-1}&section=integration&compute=${
        listData.fCompute ? listData.fCompute : ""
      }&connect_type=${listData.fconnect_type ? listData.fconnect_type : ""}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];
          if (listData.pageNo && listData.pageNo > 1) {
            all = listData.data.concat(data.listList);
          } else {
            all = [...data.listList];
          }
          setListData({
            ...listData,
            data: all?.map((obj) => ({ ...obj, key: obj._id })),
            totalPages: data.totalPages,
          });
          if (connections === undefined) {
            getlistConnections();
          } else {
            setCallExpandList(cxl + 1);
          }
        }

        setLoadeing(false);
      });
  };
  const getlistConnections = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/list/connect?page=1&limit=20&sort_by=${-1}&section=private&user_id=${
        user && user.user_id
      }&router_id=${behavior.selected ? behavior.selected._id : "auto"}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setConnections(data.listList);
          setCallExpandList(cxl + 1);
        }
      });
  };
  const calculateExpand = () => {
    let all = [];
    if (listData.data) {
      for (let i = 0; i < listData.data.length; i++) {
        if (
          connections &&
          connections.filter((f) => f.category === listData.data[i].category)
            .length
        ) {
          all = [...all, listData.data[i].key];
        }
      }
      setExpandedRowKeys(all);
    }
  };
  const updateConnection = (obj, id) => {
    delete obj._id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/list/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let all = connections.map((c) =>
            c._id === id ? response.listData : c
          );
          setConnections(all);
          message.success("Saved");
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  const saveModel = (row) => {
    delete row._id;
    const obj = {
      ...row,
      section: "private",
      user_id: user?.user_id,

      status: row.cType === "Managed Service" ? "managed_service" : "api_key",
      api_key: row.cType === "Connect Your Account" ? row.api_key : "",
      guide: row.guide2,
      compute: row.compute2,
      region: row.region2,
      models: row.models2,
      secretAccessKey: row.secretAccessKey2,
      accessKeyId: row.accessKeyId2,
      bucket_id: row.bucket_id2,
      router_id: behavior.selected ? behavior.selected._id : "auto",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/list`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          getlistConnections();
          setConnectors();
        }
      });
  };

  const deleteAutomateData = (data) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    // Make a DELETE request to your API here
    fetch(`https://embed.axv.ai/api/list/${data._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess) {
          getlistConnections();
          setConnectors();

          message.success("Data deleted successfully");
        } else {
          message.error("Failed to delete data");
        }
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        message.error("An error occurred while deleting data");
      });
  };

  // ...

  // ...

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const changeValue = (row, key, value) => {
    row[key] = value;
    row.saveN = true;

    let all = connections.map((m) => (m._id === row._id ? row : m));
    setConnections(all);
  };
  const changeValueSaved = (row) => {
    row.saveN = undefined;

    let all = connections.map((m) => (m._id === row._id ? row : m));
    setConnections(all);
  };

  return (
    <div style={{ width: "100%" }} className="active-goals-tc21">
      <div className="assets-bx-h1">
        {/* <ConnectAlerts
          t1={"What are Axons"}
          p1={
            "Model Axons allows you to connect private models, open source models and software applications into a single model interface. Each application comes with their own fees and conditions."
          }
          t2={"How Pricing Works"}
          p2={
            "Start today by turning on Axons. To use this product, we charge an additional 0.002 for every query. You have the option of using ModelUI’s managed service or connecting to your account via an API Key."
          }
          t3={"Custom Model?"}
          p3={"Looking to connect your custom models? Contact Us"}
          display={true}
          cardimg={cardimg}
          is4th={true}
          dynamicLink={"https://docs.modelui.com/axons"}
        /> */}

        <div
          style={{ borderBlockColor: "transparent" }}
          className="active-conn-c"
        >
          <div className="help-text-con">
            <a
              style={{ textDecoration: "none" }}
              href="https://guide.dendric.com/engrams/skills"
              target="blank"
            >
              <Tooltip title="Help">
                <button className="btn-yel-c">
                  <span>Skills Guide</span>

                  <FaArrowRight />
                </button>
              </Tooltip>
            </a>
          </div>
          <div>
            {/* <Switch
              onChange={() => {
                setCallApi(callApi + 1);
                setListData({
                  ...listData,
                  pageNo: 1,
                  data: undefined,
                  totalPages: undefined,
                });
              }}
            >
              Active
            </Switch> */}
            <Select
              style={{ width: "150px", marginRight: "10px" }}
              className="grey-input"
              value={listData.fCompute ? listData.fCompute : ""}
              defaultValue={""}
              onChange={(v) => {
                setLoadeing(true);

                setCallApi(callApi + 1);
                setListData({
                  ...listData,
                  pageNo: 1,
                  data: undefined,
                  totalPages: undefined,
                  fCompute: v,
                });
              }}
            >
              <Option value="">All Compute</Option>
              <Option value="aws">
                <img src={AWS} alt="" className="dropdown-img" /> AWS
              </Option>
              <Option value="azure">
                <img src={Azure} alt="" className="dropdown-img" />
                Azure
              </Option>
              <Option value="dendric">
                <img src={Dendric} alt="" className="dropdown-img" />
                Dendric
              </Option>
              <Option value="gcp">
                <img src={GCP} alt="" className="dropdown-img" />
                GCP
              </Option>
              <Option value="octoai">
                <img src={OctoAI} alt="" className="dropdown-img" />
                OctoAI
              </Option>
              <Option value="togetherai">
                <img src={togetherai} alt="" className="dropdown-img" />
                TogetherAI
              </Option>
            </Select>
            <Select
              style={{ width: "120px" }}
              className="grey-input dropdown-w-icons"
              value={listData.fconnect_type ? listData.fconnect_type : ""}
              defaultValue={""}
              onChange={(v) => {
                setLoadeing(true);
                setCallApi(callApi + 1);
                setListData({
                  ...listData,
                  pageNo: 1,
                  data: undefined,
                  totalPages: undefined,
                  fconnect_type: v,
                });
              }}
            >
              <Option value="">All Type</Option>
              <Option value="video">
                <FaPlay style={{ marginRight: "5px" }} />
                Video
              </Option>
              <Option value="image">
                <FaImage style={{ marginRight: "5px" }} />
                Image
              </Option>
              <Option value="text">
                <CiTextAlignCenter style={{ marginRight: "5px" }} />
                Text
              </Option>
              <Option value="sound">
                <BsSoundwave style={{ marginRight: "5px" }} />
                Sound
              </Option>
              <Option value="voice">
                <MdKeyboardVoice style={{ marginRight: "5px" }} />
                Voice
              </Option>
              <Option value="music">
                <FaMusic style={{ marginRight: "5px" }} />
                Music
              </Option>
              <Option value="code">
                <FaCode style={{ marginRight: "5px" }} />
                Code
              </Option>
            </Select>
          </div>
        </div>

        <Row>
          {listData.data &&
            listData.data.map((m, mi) => {
              return (
                <Col
                  lg={{ span: 6 }}
                  md={{ span: 8 }}
                  key={mi}
                  //   className="display-goal-st-s"
                  style={{ padding: "10px" }}
                >
                  <Card
                    className="list-con-card"
                    cover={<img alt="example" src={m.url} />}
                    actions={[
                      connections &&
                      connections.filter((f) => f.category === m.category)
                        .length === 0 ? null : (
                        <button
                          onClick={() =>
                            setShowC(
                              connections?.filter(
                                (f) => f.category === m.category
                              )[0]
                            )
                          }
                          class="button-bhv"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            viewBox="0 0 20 20"
                            height="20"
                            fill="none"
                            class="svg-icon-bhv"
                          >
                            <g
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke="white"
                            >
                              <circle r="2.5" cy="10" cx="10"></circle>
                              <path
                                fill-rule="evenodd"
                                d="m8.39079 2.80235c.53842-1.51424 2.67991-1.51424 3.21831-.00001.3392.95358 1.4284 1.40477 2.3425.97027 1.4514-.68995 2.9657.82427 2.2758 2.27575-.4345.91407.0166 2.00334.9702 2.34248 1.5143.53842 1.5143 2.67996 0 3.21836-.9536.3391-1.4047 1.4284-.9702 2.3425.6899 1.4514-.8244 2.9656-2.2758 2.2757-.9141-.4345-2.0033.0167-2.3425.9703-.5384 1.5142-2.67989 1.5142-3.21831 0-.33914-.9536-1.4284-1.4048-2.34247-.9703-1.45148.6899-2.96571-.8243-2.27575-2.2757.43449-.9141-.01669-2.0034-.97028-2.3425-1.51422-.5384-1.51422-2.67994.00001-3.21836.95358-.33914 1.40476-1.42841.97027-2.34248-.68996-1.45148.82427-2.9657 2.27575-2.27575.91407.4345 2.00333-.01669 2.34247-.97026z"
                                clip-rule="evenodd"
                              ></path>
                            </g>
                          </svg>
                          {/* <span class="lable-bhv">Config</span> */}
                        </button>
                      ),
                      <EditOutlined key="edit" />,

                      <div class="toggle-button-cover">
                        <div
                          onClick={() => {
                            if (
                              connections &&
                              connections.filter(
                                (f) => f.category === m.category
                              ).length === 0
                            ) {
                              if (
                                organization &&
                                organization.subscription === "Pro"
                              ) {
                                setShowPrice(true);
                              } else {
                                setShowC({ ...m, _id: undefined });
                              }
                            } else if (connections) {
                              displayDelete(
                                connections.filter(
                                  (f) => f.category === m.category
                                )[0]
                              );
                            }
                          }}
                          style={{
                            background:
                              connections &&
                              connections.filter(
                                (f) => f.category === m.category
                              ).length > 0
                                ? "#263826"
                                : "#3a2121",
                          }}
                          class="button-r"
                          id="button-3"
                        >
                          <input
                            checked={
                              connections &&
                              connections.filter(
                                (f) => f.category === m.category
                              ).length > 0
                                ? true
                                : false
                            }
                            type="checkbox"
                            class="checkbox-r"
                          />
                          <div class="knobs"></div>
                          <div class="layer-r"></div>
                        </div>
                      </div>,
                    ]}
                  >
                    <Meta
                      avatar={
                        <div className="c-type-icn">
                          {m.connect_type === "video" ? (
                            <Tooltip title={"Video"}>
                              {" "}
                              <FaPlay />
                            </Tooltip>
                          ) : m.connect_type === "image" ? (
                            <Tooltip title={"Image"}>
                              {" "}
                              <FaImage />
                            </Tooltip>
                          ) : m.connect_type === "text" ? (
                            <Tooltip title={"Text"}>
                              {" "}
                              <CiTextAlignCenter />
                            </Tooltip>
                          ) : m.connect_type === "sound" ? (
                            <Tooltip title={"Sound"}>
                              {" "}
                              <BsSoundwave />
                            </Tooltip>
                          ) : m.connect_type === "voice" ? (
                            <Tooltip title={"Voice"}>
                              {" "}
                              <MdKeyboardVoice />
                            </Tooltip>
                          ) : m.connect_type === "music" ? (
                            <Tooltip title={"Music"}>
                              {" "}
                              <FaMusic />
                            </Tooltip>
                          ) : m.connect_type === "code" ? (
                            <Tooltip title={"Code"}>
                              {" "}
                              <FaCode />
                            </Tooltip>
                          ) : null}
                        </div>
                      }
                      title={m.value}
                      description={m.description}
                    />
                  </Card>
                </Col>
              );
            })}
        </Row>
        {listData.totalPages && listData.totalPages > listData.pageNo ? (
          <div className="load-more-btn">
            {loading ? (
              <Spin indicator={antIcon} />
            ) : (
              <Tag
                onClick={() => {
                  setListData({ ...listData, pageNo: listData.pageNo + 1 });
                  setLoadeing(true);
                  setCallApi(callApi + 1);
                }}
                className={`editor-tag-tab  active-tg-wdt`}
              >
                Load More <FaArrowDown />
              </Tag>
            )}
          </div>
        ) : null}
        {/* <Table
          className="brand-table black-table pagination-white"
          pagination={{
            pageSize: 15,
            current: listData.pageNo,
            total: listData.totalPages ? listData.totalPages * 10 : 1,
            onChange: (v) => {
              setListData({ ...listData, pageNo: v });
              setCallApi(callApi + 1);
              setLoadeing(true);
            },
            size: "small",
            showSizeChanger: false,
          }}
          dataSource={listData.data}
          columns={columns}
          expandable={{
            expandedRowKeys,
            onExpand: (a, b) => {
              if (a) {
                if (
                  connections &&
                  connections.filter((f) => f.category === b.category).length
                ) {
                  setExpandedRowKeys([...expandedRowKeys, b.key]);
                } else {
                  message.warning("cannot open");
                }
              } else {
                setExpandedRowKeys([
                  expandedRowKeys.filter((f) => f !== a.key),
                ]);
              }
            },
            expandedRowRender: (record) =>
              connections &&
              connections.filter((f) => f.category === record.category)
                .length ? (
                <Alert
                  showIcon
                  description={
                    <div>
                      <div className="alert-input-bx-v">
                        <Input.TextArea
                          className="grey-input"
                          style={{
                            margin: 0,
                          }}
                          placeholder="Add Guide"
                          value={
                            connections.filter(
                              (f) => f.category === record.category
                            )[0].guide
                          }
                          autoSize={{ minRows: 2, maxRows: 3 }}
                          onChange={(e) =>
                            changeValue(
                              connections.filter(
                                (f) => f.category === record.category
                              )[0],
                              "guide",
                              e.target.value.slice(0, 500)
                            )
                          }
                        />
                        <Tooltip title="Add Guide ">
                          <AiOutlineQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        </Tooltip>
                      </div>
                      <div className="learn-more-btn-exp between-flx">
                        {connections.filter(
                          (f) => f.category === record.category
                        )[0].saveN ? (
                          <button
                            className="button-787"
                            onClick={() => {
                              updateConnection(
                                {
                                  guide: connections.filter(
                                    (f) => f.category === record.category
                                  )[0].guide,
                                },
                                connections.filter(
                                  (f) => f.category === record.category
                                )[0]._id
                              );
                              changeValueSaved(
                                connections.filter(
                                  (f) => f.category === record.category
                                )[0]
                              );
                            }}
                          >
                            Build
                          </button>
                        ) : (
                          <span />
                        )}
                        <Button
                          size="small"
                          className={`editor-tag-tab active-tg-wdt`}
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  }
                  icon={
                    <GuideLoadingVideo
                      text={
                        connections.filter(
                          (f) => f.category === record.category
                        )[0].guide
                      }
                      id={
                        connections.filter(
                          (f) => f.category === record.category
                        )[0]._id
                      }
                    />
                  }
                />
              ) : null,
          }}
        /> */}
        {loading ? <FullLoader /> : null}
      </div>
      <ConnectionModal
        showC={showC}
        setShowC={setShowC}
        updateConnection={updateConnection}
        saveModel={saveModel}
      />
      <Modal
        width={400}
        open={dDelete !== undefined}
        onCancel={() => displayDelete()}
        className="no-buttons-modal black-modal"
      >
        <h5 className="create-btn-h">Delete Connection</h5>

        <div className="bx-form-brand">
          <h6>Are you sure you want to delete?</h6>
          <div className="delet-btns-h">
            <Button size="sm" onClick={() => displayDelete()} variant="light">
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                deleteAutomateData(dDelete);
                displayDelete();
              }}
              variant="danger"
              size="sm"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
