import { Spin } from "antd";
import React, { useContext, useState } from "react";
import { FaCheck, FaChevronDown, FaInfo } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import SearchContext from "../Context/SearchContext";
// import Loader from "../../../images/spinner.gif";
// import Tick from "../../../images/tick.png";
// import Cross from "../../../images/cross.png";
// import ErrorImg from "../../../images/error.svg";

const VideosUploading = () => {
  const { videosBeingUploaded, setVideosBeingUploaded } =
    useContext(SearchContext);
  const [isActive, setIsActive] = useState(true);

  const onVideoRemove = (vidId) => {
    setVideosBeingUploaded((prevState) => {
      const newState = [...prevState];

      const filtered = newState.filter((el) => el.id !== vidId);

      return filtered;
    });
  };
  return videosBeingUploaded?.length > 0 ? (
    <div
      className={`videos-uploading-modal ${
        videosBeingUploaded?.length > 0 ? "active" : ""
      } ${!isActive ? "lowered" : ""}`}
    >
      <div className="head" onClick={() => setIsActive(!isActive)}>
        Operation in progress... <FaChevronDown />{" "}
      </div>
      <div className="body">
        {videosBeingUploaded &&
          videosBeingUploaded.map((el, idx) => {
            if (el.name.length > 18) {
              el.name = el.name.substring(0, 18) + "...";
            }

            return (
              <div className="uploaded-item" key={"uploaded-item" + idx}>
                {el.name}
                {el.isLoading ? (
                  <Spin />
                ) : (
                  // <img src={Loader} title="loader" alt="loader" />
                  <div className="completed">
                    {el.success ? <FaCheck /> : <FaInfo />}
                    <MdClose
                      className="close-bx"
                      onClick={() => onVideoRemove(el.id)}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  ) : null;
};

export default VideosUploading;
