import {
  Alert,
  Col,
  Input,
  Row,
  Select,
  Collapse,
  theme,
  Spin,
  message,
  Button,
  Modal,
  TimePicker,
  Slider,
  notification,
  Tooltip,
  Popconfirm,
  Tag,
  Image,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BiTime } from "react-icons/bi";
import {
  FaCheck,
  FaFileAudio,
  FaImage,
  FaPlay,
  FaQuestion,
  FaQuestionCircle,
  FaStar,
  FaViadeo,
  FaVideo,
} from "react-icons/fa";
import ReactPlayer from "react-player";
import SearchContext from "../../../Context/SearchContext";
import { LoadingOutlined } from "@ant-design/icons";
import GreenTick from "../../../components/GreenTick";
import {
  AiFillThunderbolt,
  AiOutlineCloseCircle,
  AiOutlineQuestionCircle,
  AiTwotoneThunderbolt,
} from "react-icons/ai";
import noneImg from "../../../images/2222628-200.png";
import dayjs from "dayjs";
import ReImagineButton from "./ReImagineButton";
import Paragraph from "antd/es/typography/Paragraph";
import TagsGroup from "../../../components/TagsGroup";
import { BsQuestion, BsQuestionLg, BsThunderbolt } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { FcUpload } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import videoTab from "../../../images/viTab.png";
import imgTab from "../../../images/imgTab.png";
import soundTab from "../../../images/sTab.png";
import voiceTab from "../../../images/vTab.png";
import blocktab from "../../../images/btab.png";
import musicTab from "../../../images/mTab.png";
import TopBar from "../../editor/components/contents/TopBar";
import Blocks from "../../editor/components/left/Blocks";

const ff = {
  1: "Very Slow",
  2: "Slow",
  3: "Normal",
  4: "Fast",
  5: "Very Fast",
};
const ffd = {
  1: "very_slow",
  2: "slow",
  3: "normal",
  4: "fast",
  5: "very_fast",
};
const vc = {
  en_speaker_1: "English speaker 1",
  en_speaker_2: "English speaker 2",
  en_speaker_3: "English speaker 3",
  en_speaker_4: "English speaker 4",
  en_speaker_5: "English speaker 5",
  en_speaker_6: "English speaker 6",
  en_speaker_7: "English speaker 7",
  en_speaker_8: "English speaker 8",
  en_speaker_9: "English speaker 9",
  es_speaker_1: "Spanish speaker 1",
  es_speaker_2: "Spanish speaker 2",
  es_speaker_3: "Spanish speaker 3",
  es_speaker_4: "Spanish speaker 4",
  es_speaker_5: "Spanish speaker 5",
  es_speaker_6: "Spanish speaker 6",
  es_speaker_7: "Spanish speaker 7",
  es_speaker_8: "Spanish speaker 8",
  es_speaker_9: "Spanish speaker 9",
  fr_speaker_1: "France speaker 1",
  fr_speaker_2: "France speaker 2",
  fr_speaker_3: "France speaker 3",
  fr_speaker_4: "France speaker 4",
  fr_speaker_5: "France speaker 5",
  fr_speaker_6: "France speaker 6",
  fr_speaker_7: "France speaker 7",
  fr_speaker_8: "France speaker 8",
  fr_speaker_9: "France speaker 9",
  hi_speaker_1: "Hindi speaker 1",
  hi_speaker_2: "Hindi speaker 2",
  hi_speaker_3: "Hindi speaker 3",
  hi_speaker_4: "Hindi speaker 4",
  hi_speaker_5: "Hindi speaker 5",
  hi_speaker_6: "Hindi speaker 6",
  hi_speaker_7: "Hindi speaker 7",
  hi_speaker_8: "Hindi speaker 8",
  hi_speaker_9: "Hindi speaker 9",
  ja_speaker_1: "Japan speaker 1",
  ja_speaker_2: "Japan speaker 2",
  ja_speaker_3: "Japan speaker 3",
  ja_speaker_4: "Japan speaker 4",
  ja_speaker_5: "Japan speaker 5",
  ja_speaker_6: "Japan speaker 6",
  ja_speaker_7: "Japan speaker 7",
  ja_speaker_8: "Japan speaker 8",
  ja_speaker_9: "Japan speaker 9",
};
export default function Create() {
  let playerRef = useRef();
  const navigate = useNavigate();
  const {
    user,
    assist,
    setShowPrice,
    organization,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
  } = useContext(SearchContext);
  const { token } = theme.useToken();
  const [textField, setTextField] = useState(""); // State to store text input
  const [duration, setDuration] = useState(2); // State to store duration input
  const [generatedID, setGeneratedID] = useState();
  const [generatedVID, setGeneratedVID] = useState();
  const [activeVideo, setActiveVideo] = useState();
  const [clip, setClip] = useState();
  const [voice, setVoice] = useState(false);
  const [editVideo, setEditVideo] = useState();
  const [callApi, setCallApi] = useState(1);
  const [creativity, setCreativity] = useState(1);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const { Option } = Select; // Destructure Option from Select
  const [searched, setSearched] = useState({}); // Add style state
  const [motion, setMotion] = useState(3); // Add style state
  const [modalVisible, setModalVisible] = useState(false);
  const [styles, setStyles] = useState([]);
  const [video_style, setvideo_style] = useState("");
  const [selectedStyle, setSelectedStyle] = useState("");
  const [events, setevents] = useState("");
  const [search, setSearch] = useState("");
  const [searchST, setSearchST] = useState("");
  const [environment, setEnvironment] = useState("");
  const [tone, setTone] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [suggestoins, setSuggestoins] = useState();
  const [reimagine, setReimagine] = useState();
  const [isQuick, setIsQuick] = useState();
  const [mood, setMood] = useState(0.5);
  const [vfx, setvfx] = useState(true);
  const [isAdwance, setIsAdwance] = useState();
  const [cType, setCtype] = useState();
  const [voicer, setVoicer] = useState("es_speaker_1");

  const [stylesPerPage] = useState(20);
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  useEffect(() => {
    if (search.length) {
      getVideos();
    } else {
      getVideoGenerated();
    }
  }, [callApi, search, cType]);

  useEffect(() => {
    if (searchST.trim().length) {
      fetchStylesS();
    } else {
      fetchStyles();
    }
  }, [searchST]);

  useEffect(() => {
    if (organization && organization.subscription === "Pro") {
      setDuration(1);
    }
  }, [organization]);

  const fetchStylesS = () => {
    const obj = { query: searchST };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/vector/style/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setStyles([...[], ...data]);
        }
      });
  };

  const fetchStyles = async () => {
    try {
      // Fetch style data from the API
      const styleResponse = await axios.get(
        `https://embed.axv.ai/api/vector/style`
      );

      // Update the state with the retrieved style data from vectorList
      setStyles(styleResponse.data?.vectorList);
    } catch (error) {
      console.error("Error fetching style data:", error);
    }
  };

  const getVideoGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=&status=generated`
      );

      // Update the state with the retrieved jobs data
      setGeneratedVID(response.data?.imageList);
      setGeneratedID();
      setIsQuick();
      if (isThunder !== "thunder") {
        setTimeout(() => {
          setCallApi(callApi + 1);
        }, 10000);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  const getVideos = () => {
    const obj = { query: search, user_id: user && user.user_id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search/user`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setGeneratedVID([...[], ...data]);
        }
      });
  };

  const saveClip = () => {
    let obj = {
      start_time: TimeCorrection(startT),
      end_time: TimeCorrection(endT),
      video_id: activeVideo._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/trim_video`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.success) {
          setClip(response);
        } else {
          message.error("somehing went wrong");
        }
      });
  };

  const handleSendClick = async (srch) => {
    const selectedStyleObject = styles.find(
      (styleObj) => styleObj.name === selectedStyle
    );
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      stream_id: srch && srch._id,
      stream: srch ? "init" : undefined,
      duration: duration,
      voice,
      mood,
      username: user && user.username,
      profile_picture: user && user.profile_picture,
      source_image:
        activeVideo && activeVideo.source_image
          ? activeVideo.source_image
          : assist?.selAssetID?._id,
      style: selectedStyleObject?.metaname,
      video_style,
      events,
      creativity,
      environment,
      motion: ffd[motion],
      vfx,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/video",
        obj
      );
      window.localStorage.setItem("video-id", JSON.stringify(response.data));
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }

      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  const handleSendClick3 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      duration: 6,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/sound",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }

      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  const handleSendClick5 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      duration: 6,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/music",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }

      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick4 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      template: voicer,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/voice",
        obj
      );
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick2 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/image",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }

      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let all = generatedVID.map((m) =>
            m._id === id ? response.imageData : m
          );

          console.log(all, response.imageData);
          setGeneratedVID(all);
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  const TimeCorrection = (number) => {
    let hours = number / 3600;
    hours = Math.floor(hours);

    let remainder = number % 3600;
    let minuts = remainder / 60;
    minuts = Math.floor(minuts);

    remainder = remainder % 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minuts < 10) {
      minuts = "0" + minuts;
    }
    if (remainder < 10) {
      remainder = "0" + remainder;
    }

    return hours + ":" + minuts + ":" + remainder;
  };
  const formatter = (value) => TimeCorrection(value);

  const copyToClipboard = () => {
    const textToCopy = `${window.location.origin}/share/${editVideo._id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };

  const formatter1 = (value) => `${ff[value]}`;

  const itemsAdwance = () => {
    return (
      <table className="table-box-adwance">
        <tr>
          <td>
            Mood{" "}
            <Tooltip title="Mood">
              <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
            </Tooltip>
          </td>
          <td>
            <div>
              <Slider
                defaultValue={mood}
                onChange={(v) => setMood(v)}
                min={0}
                max={1}
                step={0.25}
              />
            </div>
          </td>
        </tr>
        {/*
<tr>
    <td style={{ width: "120px" }}>
        Creativity{" "}
        <Tooltip title="Creativity">
            <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
        </Tooltip>{" "}
    </td>
    <td>
        <div>
            <Slider
                defaultValue={creativity}
                onChange={(v) => setCreativity(v)}
                min={1}
                max={5}
                step={1}
            />
        </div>
    </td>
</tr>
*/}

        <tr>
          <td style={{ width: "100px" }}>
            Motion{" "}
            <Tooltip title="Motion">
              <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
            </Tooltip>{" "}
          </td>
          <td>
            <div>
              <Slider
                defaultValue={motion}
                onChange={(v) => setMotion(v)}
                min={1}
                max={5}
                step={1}
                tooltip={{
                  formatter: formatter1,
                }}
              />
            </div>
          </td>
        </tr>
        {cType === "voice" ? (
          <tr>
            <td>
              Voice Type{" "}
              <Tooltip title="Style">
                <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
              </Tooltip>{" "}
            </td>
            <td>
              <div className="InputContainer">
                <Select
                  value={voicer}
                  showSearch
                  placeholder="Add Style"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setVoicer(value);
                  }}
                  style={{ width: "100%", borderRadius: "20px" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={Object.keys(vc).map((m, mi) => {
                    return {
                      value: m,
                      label: vc[m],
                    };
                  })}
                />
              </div>
            </td>
          </tr>
        ) : null}
        <tr>
          {/*
<td>
    Style{" "}
    <Tooltip title="Style">
        <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
    </Tooltip>{" "}
</td>
<td>
    <div className="InputContainer">
        <Select
            value={video_style}
            showSearch
            placeholder="Add Style"
            optionFilterProp="children"
            onChange={(value) => {
                setvideo_style(value);
            }}
            style={{ width: "100%", borderRadius: "20px" }}
            onSearch={(v) => getSearch(v, "style")}
            filterOption={(input, option) =>
                (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            options={
                searched.style
                    ? searched.style.map((m) => {
                        return {
                            value: m.text,
                            label: m.text,
                        };
                    })
                    : [{ value: "", label: "None" }]
            }
        />
    </div>
</td>
*/}
        </tr>
        {/* <tr>
            <td>
              Environment{" "}
              <Tooltip title="Environment">
                <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
              </Tooltip>{" "}
            </td>
            <td>
              <div className="InputContainer">
                <Select
                  value={environment}
                  showSearch
                  placeholder="Add Environment"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setEnvironment(value);
                  }}
                  style={{ width: "100%" }}
                  onSearch={(v) => getSearch(v, "environment")}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={
                    searched.environment
                      ? searched.environment.map((m) => {
                          return {
                            value: m.text,
                            label: m.text,
                          };
                        })
                      : [{ value: "", label: "None" }]
                  }
                />
              </div>
            </td>
          </tr> */}
        <tr>
          <td>
            Tone{" "}
            <Tooltip title="Tone">
              <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
            </Tooltip>{" "}
          </td>
          <td>
            <div className="color-select-boxz">
              <div onClick={() => setTone("none")}>None</div>
              <div
                style={{ background: "blue" }}
                onClick={() => setTone("blue")}
              >
                {tone === "blue" ? <FaCheck /> : null}
              </div>
              <div style={{ background: "red" }} onClick={() => setTone("red")}>
                {tone === "red" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "linear-gradient(to right, blue , red)" }}
                onClick={() => setTone("duotone")}
              >
                {tone === "duotone" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "aqua" }}
                onClick={() => setTone("aqua")}
              >
                {tone === "aqua" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "lightblue" }}
                onClick={() => setTone("ice_blue")}
              >
                {tone === "ice_blue" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "burlywood" }}
                onClick={() => setTone("aqua")}
              >
                {tone === "aqua" ? <FaCheck /> : null}
              </div>
              <div
                style={{ background: "yellow" }}
                onClick={() => setTone("yellow")}
              >
                {tone === "yellow" ? <FaCheck /> : null}
              </div>
              <div
                style={{
                  background: "linear-gradient(to right, black , white)",
                }}
                onClick={() => setTone("black_white")}
              >
                {tone === "black_white" ? <FaCheck /> : null}
              </div>
            </div>
          </td>
        </tr>
        {/* <tr>
            <td>
              Events{" "}
              <Tooltip title="Events">
                <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
              </Tooltip>{" "}
            </td>
            <td>
              <div className="InputContainer">
                <Select
                  value={events}
                  showSearch
                  placeholder="Add Events"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setevents(value);
                  }}
                  style={{ width: "100%" }} // Set the desired width in pixels
                  onSearch={(v) => getSearch(v, "events")}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={
                    searched.events
                      ? searched.events.map((m) => {
                          return {
                            value: m.text,
                            label: m.text,
                          };
                        })
                      : [{ value: "", label: "None" }]
                  }
                />
              </div>
            </td>
          </tr> */}

        {/*
<tr>
    <td>
        VFX{" "}
        <Tooltip title="VFX">
            <AiOutlineQuestionCircle style={{ cursor: "pointer" }} />{" "}
        </Tooltip>
    </td>
    <td>
        <div className="voice-main-c">
            <div className="checkbox-wrapper">
                <input
                    onClick={(e) => {
                        setvfx(!vfx);
                    }}
                    checked={vfx}
                    id="_checkbox-26"
                    type="checkbox"
                    readOnly
                />
                <label htmlFor="_checkbox-26">
                    <div className="tick_mark"></div>
                </label>
            </div>
            <i>VFX</i>
        </div>
    </td>
</tr>
*/}

        <tr>
          <td></td>
          <td>
            <div className="flex-end">
              <Button onClick={() => setIsAdwance()} type="primary">
                Close
              </Button>
            </div>
          </td>
        </tr>
      </table>
    );
  };

  const PausedVideo = (obj, id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/views`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess && obj.category === "start") {
          setActiveVideo({ ...activeVideo, isPlayed: response.data._id });
        } else {
          // message.error("somehing went wrong");
        }
      });
  };
  const UpdatePausedVideo = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/views/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
        } else {
          // message.error("somehing went wrong");
        }
      });
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div className="top-create-ed">
        <TopBar />
      </div>
      <Row className="create-tab-main-row">
        <Col
          className="create-tab-main"
          sm={{ span: 24 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
        >
          <div className="create-tab">
            {cType ? null : <h3 className="select-type-csc">Models</h3>}
            <Row className="transition-row">
              <Col span={cType ? 4 : 8}>
                <img
                  onClick={() => {
                    setCtype("video");
                  }}
                  className={`gb-tag-brandv ${
                    cType === "video" ? " active-ctype-tag" : ""
                  }`}
                  src={videoTab}
                  alt=""
                />
              </Col>
              <Col span={cType ? 4 : 8}>
                <img
                  onClick={() => {
                    setCtype("image");
                  }}
                  className={`gb-tag-brandv ${
                    cType === "image" ? " active-ctype-tag" : ""
                  }`}
                  src={imgTab}
                  alt=""
                />
              </Col>
              <Col span={cType ? 4 : 8}>
                <img
                  onClick={() => {
                    setCtype("sound");
                  }}
                  className={`gb-tag-brandv ${
                    cType === "sound" ? " active-ctype-tag" : ""
                  }`}
                  src={soundTab}
                  alt=""
                />
              </Col>
              <Col span={cType ? 4 : 8}>
                <img
                  onClick={() => {
                    setCtype("music");
                  }}
                  className={`gb-tag-brandv ${
                    cType === "music" ? " active-ctype-tag" : ""
                  }`}
                  src={musicTab}
                  alt=""
                />
              </Col>
              <Col span={cType ? 4 : 8}>
                <img
                  onClick={() => {
                    setCtype("voice");
                  }}
                  className={`gb-tag-brandv ${
                    cType === "voice" ? " active-ctype-tag" : ""
                  }`}
                  src={voiceTab}
                  alt=""
                />
              </Col>
              <Col span={cType ? 4 : 8}>
                <img
                  onClick={() => {
                    setCtype("block");
                  }}
                  className={`gb-tag-brandv ${
                    cType === "block" ? " active-ctype-tag" : ""
                  }`}
                  src={blocktab}
                  alt=""
                />
              </Col>
            </Row>

            {cType === "block" ? <Blocks /> : null}
            {cType && cType !== "block" ? (
              <div>
                <Input
                  placeholder={
                    activeVideo?.source_image
                      ? `Using ${activeVideo?.query} as Source Image`
                      : "Start Your Idea Here"
                  }
                  value={
                    organization && organization.onboard === "tour"
                      ? "story about artificial intelligence the dawn of a new era"
                      : textField
                  }
                  className="form-control border-0  px-4 grey-input"
                  onChange={(e) => {
                    // if (e.target.value === "") {
                    //   setSuggestoins();
                    // } else {
                    //   getQueries(e.target.value);
                    // }
                    setTextField(e.target.value);
                  }}
                />

                <div className="duration-box-m">
                  <Select
                    placeholder="Time"
                    className="grey-input"
                    style={{ width: "150px", borderRadius: "5px" }} // Set the desired width in pixels
                    value={duration}
                    onChange={(value) => {
                      if (
                        organization &&
                        organization.subscription === "Pro" &&
                        value !== 1
                      ) {
                        setShowPrice(true);
                      } else {
                        setDuration(value);
                      }
                    }}
                  >
                    <Option value={1}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      3s
                    </Option>
                    <Option value={2}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      6s
                    </Option>
                    <Option value={4}>
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      12s
                    </Option>
                    <Option value={6}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      18s
                    </Option>
                    <Option value={8}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      24s
                    </Option>
                    <Option value={10}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      30s
                    </Option>
                    <Option value={12}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      36s
                    </Option>
                    <Option value={14}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      42s
                    </Option>
                    <Option value={16}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      48s
                    </Option>
                    <Option value={18}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      54s
                    </Option>
                    <Option value={20}>
                      {" "}
                      <BiTime
                        style={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      1m
                    </Option>
                  </Select>

                  <Modal
                    title="Select Style"
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    className="black-modal no-buttons-modal"
                  >
                    <div className="form-group mb-3">
                      <input
                        style={{ maxWidth: "250px", margin: "0 auto" }}
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearchST(e.target.value)}
                        value={searchST}
                        required=""
                        className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          border:
                            selectedStyle === ""
                              ? "2px solid white"
                              : "2px solid black",
                        }}
                        onClick={() => setSelectedStyle("")}
                        className="style-card-mn"
                      >
                        <img
                          onClick={() => console.log(selectedStyle)}
                          src={noneImg}
                          alt=""
                        />
                        <span> None</span>
                      </div>

                      {styles &&
                        styles.map((style, index) => (
                          <div
                            key={index}
                            className="style-card-mn"
                            style={{
                              border:
                                selectedStyle === style.name
                                  ? "2px solid white"
                                  : "2px solid black",
                              // Add a border for selected style
                            }}
                            onClick={() => setSelectedStyle(style.name)}
                          >
                            <img
                              src={style.file_url}
                              alt={style.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                marginBottom: "10px",
                                borderRadius: "90%",
                              }}
                            />
                            <span>{style.name}</span>
                          </div>
                        ))}
                    </div>
                  </Modal>

                  <div className="create-button-con">
                    {cType === "video" ? (
                      <button
                        ref={ref3}
                        onClick={() => {
                          handleSendClick();
                          setGeneratedID(textField);
                        }}
                        className="button-21"
                      >
                        Imagine
                        <div className="hoverEffect">
                          <div></div>
                        </div>
                      </button>
                    ) : cType === "image" ? (
                      <button
                        ref={ref3}
                        onClick={() => {
                          handleSendClick2();
                          setGeneratedID(textField);
                        }}
                        className="button-21"
                      >
                        Imagine
                        <div className="hoverEffect">
                          <div></div>
                        </div>
                      </button>
                    ) : cType === "sound" ? (
                      <button
                        ref={ref3}
                        onClick={() => {
                          handleSendClick3();
                          setGeneratedID(textField);
                        }}
                        className="button-21"
                      >
                        Imagine
                        <div className="hoverEffect">
                          <div></div>
                        </div>
                      </button>
                    ) : cType === "music" ? (
                      <button
                        ref={ref3}
                        onClick={() => {
                          handleSendClick5();
                          setGeneratedID(textField);
                        }}
                        className="button-21"
                      >
                        Imagine
                        <div className="hoverEffect">
                          <div></div>
                        </div>
                      </button>
                    ) : (
                      <button
                        ref={ref3}
                        onClick={() => {
                          handleSendClick4();
                          setGeneratedID(textField);
                        }}
                        className="button-21"
                      >
                        Imagine
                        <div className="hoverEffect">
                          <div></div>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  <Tag
                    onClick={() => {
                      setIsAdwance(true);
                    }}
                    className="custom-button"
                    style={{ marginTop: "15px", marginLeft: "8px" }}
                  >
                    Advanced Settings
                  </Tag>
                  <Modal
                    open={isAdwance !== undefined}
                    onCancel={() => {
                      setIsAdwance();
                    }}
                    width={500}
                    className="no-buttons-modal black-modal"
                  >
                    <h5>Advanced Settings</h5>
                    {itemsAdwance()}
                  </Modal>
                </div>

                <div className="cta-buttons-c">
                  {isQuick ? (
                    <div className="quick-generates-div">
                      <div className="clicked-thunder-query">
                        <span>
                          {isQuick} <AiFillThunderbolt />
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {suggestoins && suggestoins.length ? (
                    <div className="quick-generates-div">
                      <h6>
                        <span>
                          Quick Stream{" "}
                          <Tooltip title="Quickstream allows you to generate videos in seconds. The videos are still original and unique to you">
                            <span className="question-circle-c">?</span>
                          </Tooltip>
                        </span>{" "}
                        <AiTwotoneThunderbolt color="orange" />
                      </h6>
                      {suggestoins.map((s, si) => {
                        return (
                          <button
                            onClick={() => {
                              handleSendClick(s);
                              setIsQuick(s.query);
                              setSuggestoins();
                              setTimeout(() => {
                                setIsQuick();
                                getVideoGenerated("thunder");
                              }, 5000);
                            }}
                            key={si}
                            class="cta"
                          >
                            <span>
                              {s.query}{" "}
                              <b style={{ color: "#4158d0" }}>
                                {parseInt(Number(s.video_duration))}s
                              </b>
                            </span>
                            <svg viewBox="0 0 13 10" height="10px" width="15px">
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </button>
                        );
                      })}
                    </div>
                  ) : null}
                  {(suggestoins && suggestoins.length) ||
                  // (textField && textField.length) ||
                  generatedID ||
                  isQuick ? null : (
                    <div className="card21">
                      <div
                        onClick={() => navigate("/watch2")}
                        className="card21-img"
                      ></div>
                      <div className="card21-info">
                        <p className="text21-body">
                          Imagine The World Around You
                        </p>
                        <p className="txt-card-21">
                          <span>
                            Olm lets you generate original videos from scratch
                            up to 1 min long. That can be short scenes,
                            educational guides and much more.
                          </span>
                          <div class="checkbox-n">
                            <input
                              checked
                              id="checkbox1"
                              class="checkbox__input"
                              type="checkbox"
                            />
                            <label for="checkbox1" class="checkbox__label">
                              <span class="checkbox__custom"></span>
                              50 Different Languages.
                            </label>
                          </div>
                          <div class="checkbox-n">
                            <input
                              checked
                              id="checkbox3"
                              class="checkbox__input"
                              type="checkbox"
                            />
                            <label for="checkbox3" class="checkbox__label">
                              <span class="checkbox__custom"></span>
                              API Available.
                            </label>
                          </div>
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {generatedID ? (
                    <Alert
                      className="gradient-alert info-alert-bx"
                      message={
                        <div>
                          Generating video{" "}
                          <b>
                            <q>{generatedID}</q>
                          </b>
                        </div>
                      }
                      type="info"
                      showIcon
                      icon={
                        <div className="spiner-main-box">
                          <div className="spinner"></div>{" "}
                          <FaPlay className="thunder-icon" />
                        </div>
                      }
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </Col>
        {generatedVID ? (
          <Col
            className="create-tab-main"
            sm={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="geneated-main-create ">
              {organization &&
              organization.onboard === "tour" &&
              organization.onboardStep > 1 ? (
                <div
                  style={{ opacity: organization.onboardStep > 3 ? 1 : 0 }}
                  ref={ref5}
                  className="video-container radius-video"
                >
                  <ReactPlayer
                    controls
                    url={
                      "https://storage.googleapis.com/download/storage/v1/b/fussydata/o/ebd662d8e6fc0b91374757aad4a9cea8.mp4?generation=1695337029339177&alt=media"
                    }
                    playing
                  />
                </div>
              ) : null}
              {activeVideo && activeVideo.media_type === "original" ? (
                <div>
                  <div className="close-as-bn ">
                    <MdClose onClick={() => setActiveVideo()} />
                  </div>
                  <div className="video-container radius-video">
                    {organization && organization.subscription === "Pro" ? (
                      <ReactPlayer
                        ref={playerRef}
                        controls
                        url={activeVideo.file_url}
                        playing
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                        onPause={(e) => {
                          if (
                            activeVideo.isPlayed &&
                            e?.target?.currentTime < e?.target?.duration
                          ) {
                            UpdatePausedVideo(
                              {
                                category: "pause",
                                endTime: e?.target?.currentTime,
                              },
                              activeVideo.isPlayed
                            );
                          }
                        }}
                        onEnded={(e) => {
                          if (activeVideo.isPlayed) {
                            UpdatePausedVideo(
                              {
                                category: "complete",
                                endTime: e?.target?.currentTime,
                              },
                              activeVideo.isPlayed
                            );
                          }
                        }}
                        onPlay={(v) => {
                          PausedVideo({
                            category: "start",
                            video_id: activeVideo._id,
                            user_id: user && user.user_id,
                            startTime: playerRef.current?.getCurrentTime(),
                          });
                        }}
                      />
                    ) : (
                      <ReactPlayer
                        ref={playerRef}
                        controls
                        url={activeVideo.file_url}
                        playing
                        onPause={(e) => {
                          if (
                            activeVideo.isPlayed &&
                            e?.target?.currentTime < e?.target?.duration
                          ) {
                            UpdatePausedVideo(
                              {
                                category: "pause",
                                endTime: e?.target?.currentTime,
                              },
                              activeVideo.isPlayed
                            );
                          }
                        }}
                        onEnded={(e) => {
                          if (activeVideo.isPlayed) {
                            UpdatePausedVideo(
                              {
                                category: "complete",
                                endTime: e?.target?.currentTime,
                              },
                              activeVideo.isPlayed
                            );
                          }
                        }}
                        onPlay={(v) => {
                          PausedVideo({
                            category: "start",
                            video_id: activeVideo._id,
                            user_id: user && user.user_id,
                            startTime: playerRef.current?.getCurrentTime(),
                          });
                        }}
                      />
                    )}
                  </div>

                  <div className="video-main-edit">
                    <span />

                    <div
                      style={{ marginTop: "0px" }}
                      className="edit-video-btn"
                    >
                      <div className="stars-bx-fils">
                        <label class="containermn">
                          <input
                            onChange={() => {
                              setActiveVideo({ ...activeVideo, r_status: "1" });
                              updateValue({ r_status: 1 }, activeVideo._id);
                            }}
                            checked={activeVideo.r_status >= "1"}
                            type="checkbox"
                          />
                          <FaStar />
                        </label>
                        <label class="containermn">
                          <input
                            onChange={() => {
                              setActiveVideo({ ...activeVideo, r_status: "2" });
                              updateValue({ r_status: 2 }, activeVideo._id);
                            }}
                            checked={activeVideo.r_status >= "2"}
                            type="checkbox"
                          />
                          <FaStar />
                        </label>
                        <label class="containermn">
                          <input
                            onChange={() => {
                              setActiveVideo({ ...activeVideo, r_status: "3" });
                              updateValue({ r_status: 3 }, activeVideo._id);
                            }}
                            checked={activeVideo.r_status >= "3"}
                            type="checkbox"
                          />
                          <FaStar />
                        </label>
                        <label class="containermn">
                          <input
                            onChange={() => {
                              setActiveVideo({ ...activeVideo, r_status: "4" });
                              updateValue({ r_status: 4 }, activeVideo._id);
                            }}
                            checked={activeVideo.r_status >= "4"}
                            type="checkbox"
                          />
                          <FaStar />
                        </label>
                        <label class="containermn">
                          <input
                            onChange={() => {
                              setActiveVideo({ ...activeVideo, r_status: "5" });
                              updateValue({ r_status: 5 }, activeVideo._id);
                            }}
                            checked={activeVideo.r_status >= "5"}
                            type="checkbox"
                          />
                          <FaStar />
                        </label>
                      </div>
                      <span className="">
                        {activeVideo.enhance === "pending" ? (
                          <FcUpload style={{ marginRight: "10px" }} />
                        ) : activeVideo.enhance === "complete" ? (
                          <Tag
                            style={{ color: "black" }}
                            className="gb-tag-brand"
                          >
                            [2K] [60FPS]
                          </Tag>
                        ) : (
                          <Popconfirm
                            title="Are you sure Enhance This Video"
                            onConfirm={() => {
                              if (
                                organization &&
                                organization.subscription === "Pro"
                              ) {
                                setShowPrice(true);
                              } else {
                                setActiveVideo({
                                  ...activeVideo,
                                  enhance: "pending",
                                });
                                updateValue(
                                  { enhance: "pending" },
                                  activeVideo._id
                                );
                              }
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              style={{ marginRight: "10px" }}
                              type="primary"
                            >
                              Upscale
                            </Button>
                          </Popconfirm>
                        )}
                        <Button
                          onClick={() => setEditVideo(activeVideo)}
                          type="primary"
                        >
                          Edit
                        </Button>
                        {organization && organization.subscription === "Pro" ? (
                          <Button
                            onClick={() => setShowPrice(true)}
                            type="primary"
                            style={{ marginLeft: "10px" }}
                          >
                            Upgrade To Download
                          </Button>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <Modal
                    open={editVideo !== undefined}
                    onCancel={() => {
                      setEditVideo();
                      setClip();
                    }}
                    width={600}
                    className="no-buttons-modal "
                  >
                    {editVideo ? (
                      <div>
                        <div>
                          <b>
                            {" "}
                            <Paragraph
                              ellipsis={{
                                rows: 1,
                                expandable: false,
                                symbol: "",
                              }}
                            >
                              {editVideo.query}
                            </Paragraph>
                          </b>
                        </div>
                        {editVideo.isCliped ? null : (
                          <div className="video-main-edit-p">
                            <Select
                              bordered={false}
                              placeholder="Display"
                              style={{ width: 100 }} // Set the desired width in pixels
                              value={
                                activeVideo.display
                                  ? activeVideo.display
                                  : "private"
                              }
                              onChange={(value) => {
                                setActiveVideo({
                                  ...activeVideo,
                                  display: value,
                                });
                                updateValue(
                                  { display: value },
                                  activeVideo._id
                                );
                              }}
                            >
                              <Option value={"private"}>Private</Option>
                              <Option value={"public"}>Public</Option>
                            </Select>
                            <Button
                              className="custom-button"
                              style={{ marginRight: "10px" }}
                              onClick={copyToClipboard}
                            >
                              Share
                            </Button>
                          </div>
                        )}
                        <div className="video-container radius-video">
                          <ReactPlayer
                            controls
                            url={editVideo.file_url}
                            playing
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload",
                                },
                              },
                            }}
                          />
                        </div>
                        <Collapse defaultActiveKey={""} accordion>
                          <Collapse.Panel header="Clip" key="clip">
                            {clip ? (
                              <div>
                                <div className="video-container radius-video">
                                  <ReactPlayer
                                    controls
                                    url={clip.file_ur}
                                    playing
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: "nodownload",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                                <div>
                                  <Button
                                    onClick={() => {
                                      setClip();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      updateValue(
                                        { file_url: clip?.file_ur },
                                        activeVideo?._id
                                      );
                                      setClip();
                                      setEditVideo({
                                        ...clip,
                                        file_url: clip.file_ur,
                                        isCliped: true,
                                      });
                                    }}
                                    type="primary"
                                  >
                                    Save Clip
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <Slider
                                  tooltip={{
                                    placement: "bottom",
                                    formatter,
                                  }}
                                  range
                                  value={[startT, endT]}
                                  defaultValue={[startT, endT]}
                                  onChange={(v) => {
                                    setStartT(v[0]);
                                    setEndT(v[1]);
                                  }}
                                />
                                <Button
                                  type="primary"
                                  onClick={() => saveClip()}
                                >
                                  Clip
                                </Button>
                              </div>
                            )}
                          </Collapse.Panel>
                          <Collapse.Panel header="Tags" key="count">
                            <TagsGroup
                              tags={editVideo.count ? editVideo.count : []}
                              color="blue"
                              onTagChange={(v) => {
                                updateValue({ count: v }, activeVideo?._id);
                                setActiveVideo({
                                  ...activeVideo,
                                  count: v,
                                });
                                setEditVideo({
                                  ...editVideo,
                                  count: v,
                                });
                              }}
                            />
                          </Collapse.Panel>
                        </Collapse>
                      </div>
                    ) : null}
                  </Modal>
                </div>
              ) : activeVideo?.media_type === "image" ? (
                <div>
                  <div className="card-bx-con-fl2">
                    <Image className="img-gen" src={activeVideo.file_url} />
                  </div>
                  <div className="flex-end">
                    {activeVideo.source_image ? (
                      <span>Selected</span>
                    ) : (
                      <Button
                        onClick={() => {
                          setActiveVideo({
                            ...activeVideo,
                            source_image: activeVideo._id,
                          });
                          setTextField("");
                          setCtype("video");
                        }}
                        type="primary"
                      >
                        Animate
                      </Button>
                    )}
                  </div>
                </div>
              ) : activeVideo?.media_type === "sound" ||
                activeVideo?.media_type === "music" ||
                activeVideo?.media_type === "voice" ? (
                <div>
                  <div className="card-bx-con-fl">
                    <ReactAudioPlayer
                      src={activeVideo.file_url}
                      autoPlay
                      controls
                    />
                  </div>
                </div>
              ) : null}

              {generatedVID &&
              organization &&
              organization.onboard === "complete" ? (
                <div className="form-group mb-3">
                  <input
                    style={{ maxWidth: "250px", margin: "0 auto" }}
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    required=""
                    className="form-control rounded-pill border-0 shadow-sm px-4 text-primary grey-input"
                  />
                </div>
              ) : null}
              <div className="gen-video-c">
                {organization &&
                organization.onboard === "tour" &&
                organization.onboardStep > 2 ? (
                  <div
                    className={`InputContainer quer-genetated ${
                      activeVideo && activeVideo._id === ""
                        ? " act-selected-v"
                        : ""
                    }`}
                    ref={ref4}
                  >
                    <p>
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          // expandable: true,
                          symbol: "see more",
                        }}
                        className="desc-p-crdjjkl"
                      >
                        story about artificial intelligence the dawn of a new
                        era
                      </Paragraph>

                      <span className="icons-bx-sd">
                        <GreenTick width={"20px"} height={"20px"} />
                      </span>
                    </p>
                  </div>
                ) : null}
                {generatedVID &&
                  generatedVID.map((m, mi) => {
                    return (
                      <div
                        onClick={() => setActiveVideo(m)}
                        key={mi}
                        className={` quer-genetated ${
                          activeVideo && activeVideo._id === m._id
                            ? " act-selected-v"
                            : ""
                        }`}
                      >
                        <p>
                          <Paragraph
                            ellipsis={{
                              rows: 1,
                              // expandable: true,
                              symbol: "see more",
                            }}
                            className="desc-p-crdjjkl"
                          >
                            {m.query}{" "}
                            {m?.media_type === "original" ? (
                              <FaVideo />
                            ) : m?.media_type === "image" ? (
                              <FaImage />
                            ) : (
                              <FaFileAudio />
                            )}
                          </Paragraph>

                          <span className="icons-bx-sd">
                            {activeVideo &&
                            activeVideo._id === m._id &&
                            organization &&
                            organization.subscription !== "Pro" &&
                            activeVideo?.media_type === "video" ? (
                              <span>
                                <ReImagineButton
                                  video={activeVideo}
                                  reimagine={reimagine}
                                  setReimagine={setReimagine}
                                />

                                <Button
                                  onClick={() => {
                                    setReimagine(activeVideo);
                                  }}
                                >
                                  Reimagine
                                </Button>
                              </span>
                            ) : null}
                            {m.quene === "complete" ? (
                              <GreenTick width={"20px"} height={"20px"} />
                            ) : m.quene === "progress" ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <AiOutlineCloseCircle className="red-tick-t" />
                            )}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                {(generatedVID &&
                  generatedVID.length === 0 &&
                  organization &&
                  organization.onboard === "complete") ||
                (organization && organization.onboardStep < 3) ? (
                  <div className="card21">
                    <div className="card21-img"></div>
                    <div className="card21-info">
                      <p className="text21-body">Welcome To Studio</p>
                      <p className="txt-card-21">
                        Create original videos of anything your mind can
                        imagine. Generations typically take 2-6 minutes and will
                        appear here automatically when complete.
                      </p>
                      {/* {assist?.selAssetID?._id} */}
                      {/* </div> */}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
