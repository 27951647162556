import { Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { motion, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
export default function CardsSection() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const isInView = useInView(ref);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (isInView) {
      setShow(true);
    }
  }, [isInView]);

  const playBtn = () => {
    return (
      <button
        onClick={() => {
          navigate("/signup");
        }}
        type="button"
        class="btn"
      >
        <strong>View</strong>
        <div id="container-stars">
          <div id="stars"></div>
        </div>

        <div id="glow">
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
      </button>
    );
  };
  return (
    <div ref={ref} className="section-4-hm">
      {show ? (
        <motion.div
          transition={{
            ease: "easeOut",
            duration: 1,
            delay: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div
            style={{ background: "transparent", height: "auto" }}
            className="grey-clr-bx-text"
          >
            <div className="container">
              <h5>Introducing our model</h5>

              <div className="cards-s4-bx">
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="red-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="green-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="blue-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="yellow-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="brown-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="sky-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="purple-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="grey-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                  >
                    <div className="card-bx-h4">
                      <div className="orange-card-s4">
                        <div className="liner-2px"></div>
                        <h6>Google Sheets</h6>
                        <h4>
                          Create profit and loss columns for the last quarter
                        </h4>
                        <div className="icon-bx-section4">{playBtn()}</div>{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
    </div>
  );
}
