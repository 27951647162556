import { Col, Row } from "antd";
import React from "react";
import { Fa500Px } from "react-icons/fa";

export default function BigCardSection() {
  return (
    <div className="big-card-sec">
      <div className="container">
        <div className="what-w-sa-t">What we are saying.....</div>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="card-bx-h4">
              <div className="light-card-s5">
                <div className="liner-2px"></div>
                <h1>Create profit and loss columns for the last quarter</h1>
                <p>Create profit and loss columns for the last quarter</p>
                <div>
                  <Fa500Px />
                  <Fa500Px />
                  <Fa500Px />
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <div className="card-bx-h4">
              <div className="light-card-s5">
                <div className="liner-2px"></div>
                <h1>Create profit and loss columns for the last quarter</h1>
                <p>Create profit and loss columns for the last quarter</p>
                <div>
                  <Fa500Px />
                  <Fa500Px />
                  <Fa500Px />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>{" "}
    </div>
  );
}
