import {
  Button,
  Col,
  Collapse,
  Modal,
  Row,
  Spin,
  Switch,
  Table,
  Tag,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../Context/SearchContext";
import { FaArrowRight } from "react-icons/fa";
import FullLoader from "../pages/storyProject/components/FullLoader";

export default function Credit() {
  const { organization, setOrganization, user, showPrice, setShowPrice } =
    useContext(SearchContext);
  const [show, setShow] = useState();
  const [s, setS] = useState();
  const [price, setPrice] = useState({ type: "Monthly", price: 30 });

  useEffect(() => {
    if (organization.onboard === "credit") {
      setShow(organization);
    }
  }, [organization.onboard]);
  useEffect(() => {
    if (organization && showPrice) {
      setShow(organization);
    }
  }, [showPrice]);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user?.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
          setShow(org);
        }
      });
  };

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const columns = [
    {
      title: "Modality",
      dataIndex: "modality",
      key: "modality",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, row) => <b>{text}</b>,
    },
  ];
  const list = [
    { modality: "Text", unit: "1000 Tokens", price: 0.0008 },
    { modality: "Image", unit: "1 Image", price: 0.015 },
    { modality: "Video", unit: "1 Second", price: 0.02 },
    { modality: "Sound", unit: "1 Second", price: 0.005 },
    { modality: "Music", unit: "1 Second", price: 0.005 },
    { modality: "Voice", unit: "1000 Characters", price: 0.005 },
    { modality: "Edit Video", unit: "60 Seconds", price: 0.02 },
    { modality: "Signal", unit: "1000 Tokens", price: 0.0007 },
  ];
  return (
    <>
      <Tag
        onClick={() => {
          setS(true);
          getOrganization();
        }}
        className={`editor-tag-tab active-tg-wdt`}
      >
        Credits
      </Tag>
      <Modal
        open={
          show !== undefined ||
          s !== undefined ||
          organization.onboard === "credit" ||
          showPrice
        }
        onCancel={() => {
          setShow();
          setS();
          setShowPrice();
        }}
        width={1000}
        className="no-buttons-modal black-modal top-20-modal"
      >
        {show ? (
          <div>
            {/* <div style={{ display: "flex" }} className="card-bx-mnbc">
              <div style={{ alignItems: "center" }} className="space-between">
                <h6 style={{ marginBottom: "0px" }}>
                  Credit Balance: <b>${show.platform_credits}</b>
                </h6>
                <a href="https://buy.olm.ai/b/test_6oE5ne7qGedr9oYbII">
                  <Button className={`editor-tag-tab active-tg-wdt`}>
                    Add Credits
                  </Button>
                </a>
              </div>
            </div> */}
            <div className="m-y-switch">
              <b style={{ opacity: price.type === "Monthly" ? 1 : 0.7 }}>
                Monthly
              </b>
              <Switch
                style={{ margin: "10px" }}
                size="default"
                onChange={(v) => {
                  if (v) {
                    setPrice({ type: "Yearly", price: 18 });
                  } else {
                    setPrice({ type: "Monthly", price: 30 });
                  }
                }}
              ></Switch>
              <b style={{ opacity: price.type === "Yearly" ? 1 : 0.7 }}>
                Yearly <Tag color="lime">Save 40%</Tag>
              </b>
            </div>

            <Row style={{ marginTop: "20px" }}>
              <Col lg={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }}>
                <section class="pricing-section">
                  <div class="pricing pricing-theme">
                    <div class="pricing-item">
                      <h3 class="pricing-title">Free</h3>
                      <div class="pricing-price">
                        <span class="pricing-currency">$</span>0
                      </div>
                      <p class="pricing-sentence">Small business solution</p>
                      <ul class="pricing-feature-list">
                        <li class="pricing-feature">100 Action Credit</li>
                        <li class="pricing-feature">1 Huminoid</li>
                        <li class="pricing-feature">400MB Storage </li>
                        <li class="pricing-feature">Miltimodal </li>
                        <li class="pricing-feature">1 User </li>
                        <li class="pricing-feature">Auto</li>
                        <li class="pricing-feature">1 Element </li>
                      </ul>
                      <a
                        style={{ width: "100%" }}
                        href={
                          price.type === "Yearly"
                            ? "https://buy.olm.ai/b/yearly"
                            : "https://buy.olm.ai/b/monthly"
                        }
                        target="blank"
                      >
                        <button class="pricing-action editor-tag-tab  active-tg-wdt">
                          Choose plan
                        </button>
                      </a>
                    </div>
                  </div>
                </section>
              </Col>
              <Col lg={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }}>
                <section class="pricing-section">
                  <div class="pricing pricing-theme">
                    <div class="pricing-item">
                      <h3 class="pricing-title">Startup</h3>
                      <div class="pricing-price">
                        <span class="pricing-currency">$</span>
                        {price.price}
                      </div>
                      <p class="pricing-sentence">Small business solution</p>
                      <ul class="pricing-feature-list">
                        <li class="pricing-feature">15000 Action Credit</li>
                        <li class="pricing-feature">5 Huminoid</li>
                        <li class="pricing-feature">1TB Storage </li>
                        <li class="pricing-feature">Miltimodal </li>
                        <li class="pricing-feature">2 User </li>
                        <li class="pricing-feature">Third Party Skills</li>
                        <li class="pricing-feature">50 Element </li>
                      </ul>
                      <a
                        style={{ width: "100%" }}
                        href={
                          price.type === "Yearly"
                            ? "https://buy.olm.ai/b/yearly"
                            : "https://buy.olm.ai/b/monthly"
                        }
                        target="blank"
                      >
                        <button class="pricing-action editor-tag-tab  active-tg-wdt">
                          Choose plan
                        </button>
                      </a>
                    </div>
                  </div>
                </section>
              </Col>
              <Col lg={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }}>
                <section class="pricing-section">
                  <div class="pricing pricing-theme">
                    <div class="pricing-item">
                      <h3 class="pricing-title">Balance</h3>
                      <div class="pricing-price">
                        <span class="pricing-currency">$</span>
                        <b>{show.platform_credits}</b>
                      </div>
                      <p class="pricing-sentence">Small business solution</p>
                      <ul class="pricing-feature-list">
                        <li class="pricing-feature">Test</li>
                        <li class="pricing-feature">Test</li>
                        <li class="pricing-feature">Test</li>
                        <li class="pricing-feature">Test</li>
                        <li class="pricing-feature">Test</li>
                        <li class="pricing-feature">Test</li>
                      </ul>
                      <a
                        target="blank"
                        style={{ width: "100%" }}
                        href="https://buy.olm.ai/b/test_6oE5ne7qGedr9oYbII"
                      >
                        <button
                          className={` pricing-action editor-tag-tab  active-tg-wdt editor-tag-tab active-tg-wdt theme-btn-c2`}
                        >
                          Add Credits
                        </button>
                      </a>
                    </div>
                  </div>
                </section>
              </Col>
              {/* <Col lg={{ span: 13 }} sm={{ span: 13 }} md={{ span: 13 }}>
                <div className="card-box-center-c">
                  <h6>Usage Discounts</h6>

                  <Row>
                    <Col span={24}>
                      <div className="card-bx-mnbc">
                        <a href="https://www.fx.video/" target="blank">
                          <div>
                            $180 <FaArrowRight />
                            20%
                            <FaArrowRight />
                            $180
                            <Button
                              size="small"
                              className={`editor-tag-tab active-tg-wdt`}
                            >
                              Buy
                            </Button>
                          </div>
                        </a>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="card-bx-mnbc">
                        <a href="https://www.fx.video/" target="blank">
                          <div>
                            $360 <FaArrowRight />
                            35%
                            <FaArrowRight />
                            $720
                            <Button
                              size="small"
                              className={`editor-tag-tab active-tg-wdt`}
                            >
                              Buy
                            </Button>
                          </div>{" "}
                        </a>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="card-bx-mnbc">
                        <a href="https://www.fx.video/" target="blank">
                          <div>
                            $720 <FaArrowRight />
                            50%
                            <FaArrowRight />
                            $1080
                            <Button
                              size="small"
                              className={`editor-tag-tab active-tg-wdt`}
                            >
                              Buy
                            </Button>
                          </div>{" "}
                        </a>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="card-bx-mnbc">
                        <a href="https://www.fx.video/" target="blank">
                          <div>
                            $1080 <FaArrowRight />
                            65%
                            <FaArrowRight />
                            $1200
                            <Button
                              size="small"
                              className={`editor-tag-tab active-tg-wdt`}
                            >
                              Buy
                            </Button>
                          </div>{" "}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col> */}

              <Col span={24}>
                <Collapse className="black-collapse" bordered={false}>
                  <Collapse.Panel header="Modality Pricing Guide" key="Detail">
                    <Row>
                      <Col
                        lg={{ span: 16 }}
                        sm={{ span: 24 }}
                        md={{ span: 16 }}
                      >
                        <Table
                          className="brand-table black-table pagination-white"
                          columns={columns}
                          dataSource={list}
                          pagination={false}
                        />
                      </Col>

                      <Col lg={{ span: 8 }} sm={{ span: 24 }} md={{ span: 8 }}>
                        <div
                          style={{ maxHeight: "400px", marginTop: "35px" }}
                          className="no-heigh-div-c editor-container special-card-c"
                        >
                          <div
                            style={{ paddingTop: "10px" }}
                            className="card21"
                          >
                            <div className="card21-info">
                              <p className="text21-body">
                                {organization.onboard === "credit"
                                  ? "Free Trial"
                                  : "Consumption"}
                              </p>
                              <p className="txt-card-21">
                                {organization.onboard === "credit"
                                  ? "Enjoy this free trial which gives you up to 50 generations and access to our other tools"
                                  : `In order to consume ModelUI’s products and services,
                  credits are required. Your credit balance is reflected in US
                  dollars and allows you to consume any one of our
                  services.`}
                              </p>
                              {/* Add Learn More button */}
                              <button
                                onClick={() =>
                                  (window.location.href =
                                    "https://docs.modelui.com/pricing")
                                }
                                className="editor-tag-tab active-tg-wdt"
                              >
                                Learn More
                              </button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>
            {organization.onboard === "credit" ? (
              <div className="flex-end">
                <Button
                  onClick={() => {
                    updateOrganization("onboard", "tour");
                    setOrganization({ ...organization, onboard: "tour" });
                    setShow();
                    setS();
                    setShowPrice();
                  }}
                >
                  Skip
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="flex-center">
            <Spin />
          </div>
        )}
      </Modal>
    </>
  );
}
