import { Col, message, Row, Tabs, Tooltip, Upload, Button, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import EditPassword from "../components/EditPassword";
import "../account.css";
import SearchContext from "../../../Context/SearchContext";

export default function MyAccount() {
  const { user, setUser, organization } = useContext(SearchContext);
  const [userName, setuserName] = useState();

  const updateUser = () => {
    const obj = {
      name: userName,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.ACCESS_TOKEN,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/${user.id}/patch`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          user.name = response.patchedData.name;
          window.localStorage.setItem("user", JSON.stringify(user));
          setUser(user);
        } else {
          message.error("somehing went wrong");
        }
        setuserName();
      });
  };

  return (
    <div className="acc-topc">
      <div className="account-page-c">
        {user ? (
          <div>
            <div className="user-name-acc-c user-name-acc-c21">
              <div style={{maxWidth:"400px"}} className="row">
                <label className="account lbl-small">Username</label>
                <div className={userName ? "col-9" : "col-12"}>
                  <Input
                    type="text"
                    placeholder="User Name"
                    value={userName ? userName : user?.name}
                    onChange={(e) => {
                      setuserName(e.target.value);
                    }}
                    className="grey-input"
                  />
                </div>
                <div className={userName ? "col-2" : ""}>
                  {userName ? <Button onClick={updateUser}>Save</Button> : null}
                </div>

                <div className="col-12">
                  <label className="lbl-small">Email</label>
                  <Tooltip
                    className="account float-right mt-1"
                    color={
                      organization?.color_code &&
                      organization.color_code !== "null"
                        ? organization.color_code
                        : "black"
                    }
                    placement="right"
                    title="To change the email on your account, please contact us."
                  >
                    <FaQuestionCircle className="aut-q-lf " />
                  </Tooltip>
                  <Input className="grey-input" disabled type="text" value={user?.email} />
                </div>
                <div className="col-12">
                  <label className="lbl-small">Password</label>
                  <Input
                    type="text"
                    disabled
                    placeholder="User Name"
                    className="grey-input"
                    value="**********"
                  />
                </div>
                <div className="col-12 mt-1">
                  <div className="account float-right">
                    <EditPassword />
                  </div>
                </div>
              </div>
              {/* <table>
                <tbody>
                  <tr>
                    <td>
                      <h6>Name:</h6>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="User Name"
                        value={userName ? userName : user?.name}
                        onChange={(e) => {
                          setuserName(e.target.value);
                        }}
                      />
                      {userName ? (
                        <Button onClick={updateUser}>Save</Button>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Email:</h6>
                    </td>
                    <td>
                      <Form.Control disabled type="text" value={user?.email} />{" "}
                      <Tooltip
                        color={
                          organization?.color_code &&
                          organization.color_code !== "null"
                            ? organization.color_code
                            : "black"
                        }
                        placement="right"
                        title="To change the email on your account, please contact us."
                      >
                        <FaQuestionCircle className="aut-q-lf " />
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Password:</h6>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        disabled
                        placeholder="User Name"
                        value="**********"
                      />
                      <EditPassword />

                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Permission:</h6>
                    </td>
                    <td>{user?.permission}</td>
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
