import { Button, Col, Row } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../Context/SearchContext";

export default function Pricing({ hide }) {
  const { organization, user, setUser, setOrganization } =
    useContext(SearchContext);
  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };
  return (
    <div style={{ width: "100%" }}>
      {hide ? null : <h4 style={{ fontFamily: "inter" }}>Pricing</h4>}
      <Row>
        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }}>
          <div className="price-card-box">
            <div class="card61">
              <div class="card-content61">
                <p class="card-title61" style={{ fontFamily: "inter" }}>
                  Community
                </p>

                <p class="card-para61" style={{ fontFamily: "inter" }}>
                  Free
                </p>
                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Create Up To 3s
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Browse Unlimited
                    </b>
                  </span>
                </div>
                <div className="prifing-list">
                  <span className="PriceX">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Reimagine
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="PriceX">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Image To Video
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="PriceX">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Monetize
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="PriceX">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      AI Chat
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="PriceX">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Support
                    </b>
                  </span>
                </div>

                <button
                  onClick={() => {
                    updateOrganization("onboard", "tour");
                    setOrganization({ ...organization, onboard: "tour" });
                  }}
                  class="btnpr prbutton"
                  style={{ fontFamily: "inter", fontSize: "12px" }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }}>
          <div className="price-card-box">
            <div class="card61">
              <div class="card-content61">
                <p class="card-title61" style={{ fontFamily: "inter" }}>
                  Creator
                </p>

                <p class="card-para61" style={{ fontFamily: "inter" }}>
                  $15/Month
                </p>
                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Create Up To 1m
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Browse Unlimited
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Reimagine
                    </b>
                  </span>
                </div>
                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Image To Video
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Monetize
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="PriceX">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      AI Chat
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="PriceX">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Support
                    </b>
                  </span>
                </div>

                <button
                  onClick={() => {
                    window.open(
                      "https://buy.stripe.com/3cs15dcuC4G35XO00j",
                      "_blank"
                    ); // Open in a new tab or window
                  }}
                  class="btnpr prbutton"
                  style={{ fontFamily: "inter", fontSize: "12px" }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }}>
          <div className="price-card-box">
            <div class="card61">
              <div class="card-content61">
                <p class="card-title61" style={{ fontFamily: "inter" }}>
                  Business
                </p>

                <p class="card-para61" style={{ fontFamily: "inter" }}>
                  $25/Per User
                </p>
                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Create Up To 1m
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Browse Unlimited
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Reimagine
                    </b>
                  </span>
                </div>
                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Image To Video
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Monetize
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      AI Chat
                    </b>
                  </span>
                </div>

                <div className="prifing-list">
                  <span className="checkmarkPrice">
                    <span className="checkmarkSymbol"></span>{" "}
                    <b
                      className="checkmarkText"
                      style={{ fontFamily: "inter" }}
                    >
                      Support
                    </b>
                  </span>
                </div>

                <button
                  onClick={() => {
                    window.open(
                      "https://buy.stripe.com/7sI6px7ai8Wj71SdR5",
                      "_blank"
                    ); // Open in a new tab or window
                  }}
                  class="btnpr prbutton"
                  style={{ fontFamily: "inter", fontSize: "12px" }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {hide ? null : (
        <div style={{ marginTop: "20px" }} className="up-bx-mnbcs">
          <span />
          <Button
            onClick={() => {
              updateOrganization("onboard", "tour");
              setOrganization({ ...organization, onboard: "tour" });
            }}
            style={{ fontFamily: "inter" }}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
}
