import { Alert, Input, Modal, Select } from "antd";
import React, { useContext, useState } from "react";
import { BiTime } from "react-icons/bi";
import { BsMagic } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import SearchContext from "../Context/SearchContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CreateImagine({ header }) {
  const { user, assist } = useContext(SearchContext);
  const navigate = useNavigate();
  const { Option } = Select; // Destructure Option from Select
  const [textField, setTextField] = useState(""); // State to store text input
  const [imagine, setImagine] = useState("");
  const [duration, setDuration] = useState(4); // State to store duration input
  const [voice, setVoice] = useState(false);
  const [generatedID, setGeneratedID] = useState();
  const [generatedVID, setGeneratedVID] = useState();

  const handleSendClick = async () => {
    // const selectedStyleObject = styles.find(
    //   (styleObj) => styleObj.name === selectedStyle
    // );
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/video",
        {
          query: textField,
          user_id: user && user.user_id,
          duration: duration,
          voice,
          username: user && user.username,
          profile_picture: user && user.profile_picture,
          source_image: assist?.selAssetID?._id,
          //   style: selectedStyleObject?.metaname,
        }
      );

      window.localStorage.setItem("video-id", JSON.stringify(response.data));
      // Handle the response here if needed
      setGeneratedID(response.data);
      // Display the alert
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {header ? (
        <BsMagic className="magic-icon-h" onClick={() => setImagine(true)} />
      ) : (
        <div>
          <div className="imagive-modal-cadrd">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">Imagine The World Around You</p>
                <p className="txt-card-21">
                  Olm lets you generate original videos from scratch up to 1 min
                  long. That can be short scenes, educational guides and much
                  more.{" "}
                </p>
                <div style={{ marginTop: "-30px", marginBottom: "20px" }}>
                  <button
                    onClick={() => {
                      setImagine();
                      navigate("/studio");
                    }}
                    className="custom-button"
                    style={{marginRight:"20px"}}
                  >
                    Visit Studio
                  </button>
                  <button
                    onClick={() => setImagine(true)}
                    className="custom-button"
                  >
                    Create It Yourself
                  </button>
                </div>
                {assist?.selAssetID?._id}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={imagine}
        onCancel={() => {
          setImagine();
        }}
        width={600}
        className="no-buttons-modal black-modal"
      >
        <h4 style={{ marginBottom: "-10px" }}>Quick Create</h4>
        <div className="create-tab">
          {/* Create Tab Content Goes Here */}
          <div
            className="InputContainer"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Input.TextArea
              placeholder="Start Your Idea Here"
              value={textField}
              className="form-control border-0  px-4"
              onChange={(e) => setTextField(e.target.value)}
              autoSize={{ minRows: 1, maxRows: 4 }}
            />
          </div>
          <div className="duration-box-m">
            <div className="InputContainer">
              <Select
                placeholder="Time"
                style={{ width: 120 }} // Set the desired width in pixels
                value={duration}
                onChange={(value) => setDuration(value)}
              >
                {/* <Option disabeled value={""}>
              Duration
            </Option> */}

                <Option value={1}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  3s
                </Option>
                <Option value={2}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  6s
                </Option>
                <Option value={4}>
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  12s
                </Option>
                <Option value={6}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  18s
                </Option>
                <Option value={8}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  24s
                </Option>
                <Option value={10}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  30s
                </Option>
                <Option value={12}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  36s
                </Option>
                <Option value={14}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  42s
                </Option>
                <Option value={16}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  48s
                </Option>
                <Option value={18}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  54s
                </Option>
                <Option value={20}>
                  {" "}
                  <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
                  1m
                </Option>
              </Select>
            </div>
            <div>
              {/* <button
              onClick={() => setModalVisible(true)}
              className="custom-button"
              style={{ marginLeft: "10px" }}
            >
              Style Transfer
            </button> */}
            </div>
            <div className="voice-main-c">
              <div className="checkbox-wrapper">
                <input
                  onClick={(e) => {
                    setVoice(!voice);
                  }}
                  checked={voice}
                  id="_checkbox-26"
                  type="checkbox"
                  readOnly
                />
                <label htmlFor="_checkbox-26">
                  <div className="tick_mark"></div>
                </label>
              </div>
              <i>Voice</i>
            </div>
            {/* <Modal
            title="Select Style"
            open={modalVisible}
            onCancel={() => setModalVisible(false)}
            onOk={() => {
              handleStyleSelect(selectedStyle);
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              {styles &&
                styles.map((style, index) => (
                  <div
                    key={index}
                    style={{
                      width: "20%",
                      margin: "10px",
                      textAlign: "center",
                      cursor: "pointer",
                      border:
                        selectedStyle === style.name
                          ? "2px solid #1890ff"
                          : "none", // Add a border for selected style
                      padding: "5px",
                    }}
                    onClick={() => setSelectedStyle(style.name)}
                  >
                    <img
                      src={style.file_url}
                      alt={style.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        marginBottom: "10px",
                        borderRadius: "90%",
                      }}
                    />
                    <p>{style.name}</p>
                  </div>
                ))}
            </div>
          </Modal> */}

            <div className="create-button-con">
              <button onClick={handleSendClick} className="button-21">
                Imagine
                <div className="hoverEffect">
                  <div></div>
                </div>
              </button>
            </div>
          </div>
          {/* <div className="InputContainer"> */}
          {/* <div className="card21">
            <div className="card21-img"></div>
            <div className="card21-info">
              <p className="text21-body">Imagine The World Around You</p>
              <p className="txt-card-21">
                Olm lets you generate original videos from scratch up to 1 min
                long. That can be short scenes, educational guides and much
                more.
              </p>
              {assist?.selAssetID?._id}
              
            </div>
          </div> */}
          <div>
            {generatedID ? (
              <Alert
                className="bg-light info-alert-bx"
                message={
                  <div>
                    Check Studio for the results
                    {/* <b>
                      <q>{generatedID && generatedID.data?.query}</q>
                    </b> */}
                  </div>
                }
                type="info"
                showIcon
                icon={
                  <div className="spiner-main-box">
                    <div className="spinner"></div> <FaPlay />
                  </div>
                }
              />
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}
