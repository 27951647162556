import React, { useContext, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import { Button, Modal, message } from "antd";
import { Link } from "react-router-dom";
import profileIcon from "../../../images/profile-icon.png";
export default function Profile() {
  const { user, setUser, organization, setShowPrice , setOrganization } =
    useContext(SearchContext);
  const [show, setShow] = useState(false);

  const updateUser = (obj) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.ACCESS_TOKEN,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/${user.id}/patch`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          user.name = response.patchedData.name;
          window.localStorage.setItem("user", JSON.stringify(user));
          setUser(user);
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setOrganization(response.organizationData)
        }
      });
  };
  return (
    <div className="profile-edit-page">
      {organization ? (
        <div className="form-group mb-3">
          <table>
            <tr>
              <td>
                <h5 className="profile-name pro-tb">
                  <img
                    alt=""
                    src={
                      user.profile_picture ? user.profile_picture : profileIcon
                    }
                  />{" "}
                </h5>
              </td>
              <td></td>

              <td>
                {organization.earn === "progress" ? (
                  <Button className="custom-button" type="primary">
                    In Progress
                  </Button>
                ) : (
                  <Button
                    onClick={() => setShow(true)}
                    className="custom-button"
                    type="primary"
                  >
                    Monetize
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td>Name:</td>
              <td colSpan={2}>
                {" "}
                <input
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  value={user.name}
                  onBlur={() => updateUser({ name: user.name })}
                  required=""
                  className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                />
              </td>
            </tr>
            <tr>
              <td>Username:</td>
              <td colSpan={2}>
                {" "}
                <input
                  type="text"
                  placeholder="Username"
                  onChange={(e) =>
                    setUser({ ...user, username: e.target.value })
                  }
                  value={user.username}
                  onBlur={() => {
                    if (user.username.length < 3) {
                      const u = JSON.parse(window.localStorage.getItem("user"));
                      setUser(u);
                      message.warning(
                        "Usernamae should be longer then 3 letters"
                      );
                    } else {
                      updateUser({ username: user.username });
                    }
                  }}
                  required=""
                  className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                />
              </td>
            </tr>
            <tr>
              <td>Email:</td>
              <td colSpan={2}> {user.email}</td>
            </tr>
            <tr>
              <td>Earnings:</td>
              <td> {organization.balance}</td>
            </tr>
            <tr>
              <td>Subscription:</td>
              <td> {organization.subscription}</td>
            </tr>
            <tr>
              <td>Balance:</td>
              <td>${organization.reserved_compute_credits} </td>
              <td>
                {" "}
                <a
                  href="https://buy.stripe.com/dR68xFbqyfkH4TKdR4"
                  target="blank"
                >
                  <Button type="primary">Add Credit</Button>{" "}
                </a>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                {" "}
                <Button
                  className="custom-button"
                  onClick={() => {
                    setShowPrice(true);
                  }}
                >
                  Plans
                </Button>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <Link to="https://billing.stripe.com/p/login/00gfZz7uLeaH50Y288">
                  Manage Billing
                </Link>
              </td>
            </tr>
          </table>
        </div>
      ) : null}
      <Modal
        width={500}
        className="no-buttons-modal no-close-model black-modal "
        open={show}
        onCancel={() => setShow(false)}
      >
        <h4>Monetize your Ideas</h4>
        <p>
          Start earning money today by leveraging your visual assets across the
          Olm Network.
        </p>
        <div className="monti-m-button">
          <Button
            onClick={() => {
              updateOrganization("earn", "progress");
              setShow(false);
            }}
          >
            Continue
          </Button>
          <Button onClick={() => setShow(false)}>Cancel</Button>
        </div>
      </Modal>
    </div>
  );
}
