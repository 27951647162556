import Feed from "./feed/Feed";
import Home from "./home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Profile from "./profile/Profile";
import Console from "./console/Console";
import Login from "./login/Login";
import Signup from "./signup/Signup";
import { useContext, useEffect, useState } from "react";
import SearchContext from "../Context/SearchContext";
import { v4 as uuidv4 } from "uuid";
import VideoPage from "./videoPage/VideoPage";
import ShareVideo from "./share/ShareVideo";
import { FaPlay } from "react-icons/fa";
import WatchTry from "./watchTry/WatchTry";
import Story from "./story/Story";
import StoryProject from "./storyProject/StoryProject";
import Test from "./test/Test";
import Test2 from "./test copy 2/Test";
import TourOnboard from "../components/TourOnboard";
import WatchCreate from "./watchCreate/WatchCreate";
import Editor from "./editor/Editor";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import SIgnupTest from "./SIgnupTest/SIgnupTest";
import SignInTest from "./SignInTest/SignInTest";
import SingleAsset from "./editor/SingleAsset";
import ImagesCategory from "./images/ImagesCategory";
import Images from "./images/Images";
import ImagesQuery from "./images/ImagesQuery";
import VideosCategory from "./videos/VideosCategory";
import VideosQuery from "./videos/VideosQuery";
import Videos from "./videos/Videos";
import SoundsCategory from "./sounds/SoundsCategory";
import Sounds from "./sounds/Sounds";
import SoundsQuery from "./sounds/SoundsQuery";
import Pricing from "./pricing/Pricing";
import FullLoader from "./storyProject/components/FullLoader";

function Pages() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setUser,
    loginChange,
    left,
    setLeft,
    theme,
    setFormType,
    setTheme,
    setOrganization,
    setLanguage,
  } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      setUser(u);
      getOrganization(u); 
    } else {
      if (
        // window.location.pathname !== "/" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/signup" &&
        window.location.pathname !== "/watch/try" &&
        !window.location.pathname.includes("/share/") &&
        !window.location.pathname.includes("/image") &&
        !window.location.pathname.includes("/video") &&
        !window.location.pathname.includes("/sound") &&
        !window.location.pathname.includes("/profile/")
      ) {
        navigate("/signup");
        
      }
      setLoading(false);
      //   let isID = JSON.parse(window.localStorage.getItem("isID"));
      //   let user_id;
      //   if (isID) {
      //     user_id = isID.user_id;
      //   } else {
      //     user_id = uuidv4();
      //   }
      //   obj = { user_id };
      //   setUser(obj);
    }
  }, [loginChange]);

  useEffect(() => {
    const t = window.localStorage.getItem("theme");
    const lang = window.localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    }
    if (t) {
      setTheme(t);
    }
  }, []);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
          setLoading(false);

        }
      });
  };
  return loading ? (
 <FullLoader />
  ) : (
    <>
      {/* <SignedIn> */}
      <Routes>
        {/* <Route path="/" element={<Editor />} /> */}

        <Route path="/" element={<Home />} />

        <Route path="/watch" element={<Feed />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/sound" element={<SoundsCategory />} />
        <Route path="/sound/:id" element={<Sounds />} />
        <Route path="/sound/:id/:id" element={<SoundsQuery />} />
        <Route path="/image" element={<ImagesCategory />} />
        <Route path="/image/:id" element={<Images />} />
        <Route path="/image/:id/:id" element={<ImagesQuery />} />
        <Route path="/video" element={<VideosCategory />} />
        <Route path="/video/:id" element={<Videos />} />
        <Route path="/video/:id/:id" element={<VideosQuery />} />
        <Route path="/watch2" element={<WatchCreate />} />
        {/* <Route path="/story/:id" element={<Editor />} /> */}
        <Route path="/canvas/:id" element={<Editor />} />
        <Route path="/asset/:id" element={<SingleAsset />} />
        <Route path="/task/:id" element={<Editor />} />
        <Route path="/task/:id/media/:id" element={<Editor />} />

        {/* <Route path="/story/:id" element={<Test />} /> */}
        <Route path="/story" element={<Story />} />
        {/* <Route path="/story/:id" element={<StoryProject />} /> */}
        <Route path="/share/:id" element={<ShareVideo />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/studio" element={<Console />} />
        <Route path="/watch/try" element={<WatchTry />} />
        <Route path="/video/:id" element={<VideoPage />} />
        <Route path="/login/test" element={<SignInTest />} />
        <Route path="/signup/test" element={<SIgnupTest />} />
        <Route path="/space/:id" element={<Editor />} />
      </Routes>
      {/* </SignedIn> */}
      {/* <SignedOut>
      <Routes>
          <Route path="/login" element={<SignInTest />} />
          <Route path="/signup" element={<SIgnupTest />} />
      </Routes>
        </SignedOut> */}
    </>
  );
}

export default Pages;
