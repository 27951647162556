import {
  Alert,
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Input,
  Progress,
  Row,
  Spin,
  Select,
  Collapse,
  Slider,
  Pagination,
  Tag,
  Popover,
  Drawer,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaArrowDown, FaArrowRight, FaInfo } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { LoadingOutlined } from "@ant-design/icons";

//   import UploadAssets from "./UploadAssets";
//   import ReImagineButton from "./ReImagineButton";
import SearchContext from "../../../../../Context/SearchContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdClose, MdOutlineInfo } from "react-icons/md";
import { BsInfo } from "react-icons/bs";
import FullLoader from "../../../../storyProject/components/FullLoader";
import ConnectAlerts from "./ConnectAlerts";
import cardImg from "../../../../../images/16.png";
import GuideLoadingVideo from "./GuideLoadingVideo";
import OpenCsv from "./OpenCsv";
import OpenCsvBlocks from "./OpenCsvBlocks";
import OpenPdfBlocks from "./OpenPdfBlocks";
export default function Blocks({}) {
  const { images, setImages, user, behavior, ref10, organization } =
    useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [callApi, setCallApi] = useState(1);
  const [edit, setEdit] = useState();

  useEffect(() => {
    if (callApi > 1 || behavior.callBlocks !== undefined) {
      getBlocksGenerated();
    }
  }, [callApi, behavior.callBlocks]);
  useEffect(() => {
    if (images.blocks === undefined) {
      getBlocksGenerated();
    } else {
      setLoading2(false);
    }
  }, []);

  const getBlocksGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/block/?page=${
          images.pageNoB
        }&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&block_type=&search=${
          images.bloackSearch ? images.bloackSearch : ""
        }&router_id=${behavior.selected ? behavior.selected._id : "auto"}`
      );
      setLoading2(false);
      if (response.data?.isSuccess) {
        let all = [];
        if (images.pageNoB && images.pageNoB > 1) {
          all = images.blocks.concat(response.data?.blockList);
        } else {
          all = [...response.data?.blockList];
        }
        setImages({
          ...images,
          // data: data.imageList,
          blocks: all,
          totalPBlock: response.data?.totalPages,
        });
      }

      // setTimeout(() => {
      //   setCallApi(callApi + 1);
      // }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const updateValue = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/block/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let all = images.blocks.map((m) =>
            m._id === response.blockData._id ? response.blockData : m
          );
          setImages({ ...images, blocks: all });
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/block/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = images.blocks.filter((p) => p._id !== row._id);
          setImages({ ...images, blocks: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  return (
    <div style={{ width: "100%" }} className="active-goals-tc21">
      <div style={{overflowY:"visible"}} className="assets-bx-h1">
        <div
          style={{ borderBlockColor: "transparent" }}
          className="active-conn-c"
        >
          <div className="help-text-con">
            <a
              style={{ textDecoration: "none" }}
              href="https://guide.dendric.com/engrams/elements"
              target="blank"
            >
              <Tooltip title="Help">
                <button className="btn-yel-c">
                  <span>Elements Guide</span>

                  <FaArrowRight />
                </button>
              </Tooltip>
            </a>
          </div>
        </div>
        {/* <ConnectAlerts
          t1={"What are Blocks?"}
          p1={
            "Blocks are assets that you want to store in-memory for the AI model. You can store assets such as how characters look, products from a store, a voice you want to clone."
          }
          t2={"How To Use It"}
          p2={
            "You can make use it of one of your blocks by entering the name of it in your query in the context you want."
          }
          cardimg={cardImg}
        /> */}

        {loading2 || images.blocks === undefined ? <FullLoader /> : null}
        <Row>
          {images.blocks &&
            images.blocks.map((m, mi) => {
              return (
                <Col
                  lg={{ span: 6 }}
                  md={{ span: 8 }}
                  key={mi}
                  //   className="display-goal-st-s"
                  ref={mi === 0 ? ref10 : null}
                >
                  <div
                    className="display-goal-st-s1"
                    style={{
                      color: "white",
                      width: "100%",
                      opacity:
                        organization?.onboardStep &&
                        organization?.onboardStep < 10
                          ? 0
                          : 1,
                    }}
                  >
                    <div className="video-thumbnail-imgg ">
                      <img
                        style={{ maxHeight: "200px", minHeight: "200px" }}
                        src={m.file_url}
                        alt=""
                      />
                      <div className="bottom-popup">
                        <span>{m.block_name}</span>
                      </div>
                      <div className="top-right-popup">
                        {m.block_type === "CSV" ? (
                          <OpenCsvBlocks row={m} />
                        ) : null}
                        {m.block_type === "PDF" ? (
                          <OpenPdfBlocks row={m} />
                        ) : null}
                        {m.block_type ? (
                          <span className="hide-hover">
                            {m.block_type.replace("_", " ")}
                          </span>
                        ) : null}
                        <b className="show-hover">
                          <BsInfo
                            onClick={() => setEdit(m)}
                            className="hover"
                          />
                        </b>
                        <b className="show-hover">
                          <Popconfirm
                            title="Delete this block"
                            onConfirm={() => deleteTask(m)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                            placement="right"
                          >
                            <MdClose
                              style={{ marginLeft: "5px" }}
                              className="hover"
                            />
                          </Popconfirm>
                        </b>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
        {images.totalPBlock && images.totalPBlock > images.pageNoB ? (
          <div className="load-more-btn">
            {loading2 ? (
              <Spin indicator={antIcon} />
            ) : (
              <Tag
                onClick={() => {
                  setImages({
                    ...images,
                    pageNoB: images.pageNoB + 1,
                  });
                  setLoading2(true);
                  setCallApi(callApi + 1);
                }}
                className={`editor-tag-tab  active-tg-wdt`}
              >
                Load More <FaArrowDown />
              </Tag>
            )}
          </div>
        ) : null}
        {images.blocks && images.blocks.length === 0 ? (
          <div
            className="no-heigh-div-c"
            style={{ maxWidth: "550px", margin: "auto" }}
          >
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">Create a Block</p>
                <p className="txt-card-21">
                  Leverage blocks to add context about your assets to your
                  creations. Use the upload button to create your first block.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <Drawer
          open={edit !== undefined}
          onClose={() => {
            setEdit();
          }}
          // width={600}
          className="black-drawer"
        >
          <h4>Edit Block</h4>
          {edit ? (
            <div className="edit-block-modal">
              <div>
                <b>Name: </b>{" "}
                <Input
                  className="grey-input"
                  placeholder="Name"
                  value={edit.block_name}
                  style={{ marginTop: "5px" }}
                  onChange={(e) =>
                    setEdit({ ...edit, block_name: e.target.value, edit: true })
                  }
                />
              </div>
              <div>
                <b>Description: </b>{" "}
                <Input.TextArea
                  className="grey-input"
                  placeholder="Description"
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  value={edit.description}
                  style={{ marginTop: "5px" }}
                  onChange={(e) =>
                    setEdit({
                      ...edit,
                      description: e.target.value,
                      edit: true,
                    })
                  }
                />
              </div>
              <div>
                <b>Guide: </b>{" "}
                <Input.TextArea
                  className="grey-input"
                  placeholder="Description"
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  value={edit.guide}
                  style={{ marginTop: "5px" }}
                  onChange={(e) =>
                    setEdit({
                      ...edit,
                      guide: e.target.value,
                      edit: true,
                    })
                  }
                />
              </div>
              <div className="flex-center">
                <GuideLoadingVideo
                  text={edit.guide ? edit.guide : ""}
                  id={edit._id}
                />
              </div>
              {edit.edit ? (
                <div className="flex-end">
                  <Button
                    onClick={() => setEdit()}
                    style={{ marginRight: "10px" }}
                    className={`editor-tag-tab  active-tg-wdt`}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={`editor-tag-tab  active-tg-wdt`}
                    onClick={() => {
                      updateValue(
                        {
                          block_name: edit.block_name,
                          description: edit.description,
                          guide: edit.guide,
                        },
                        edit._id
                      );
                      setEdit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              ) : null}
            </div>
          ) : null}
        </Drawer>
      </div>
    </div>
  );
}
